import React from 'react';
import {Box, Typography} from '@mui/material';

const primary = '#1976d2'; // #f44336

export default function Error() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundColor: primary,
      }}>
      <Typography variant="h1" style={{color: 'white'}}>
        404
        <Typography variant="h4" style={{color: 'white'}}>
          Page non trouvée
        </Typography>
      </Typography>
    </Box>
  );
}
