import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import {useNavigate} from 'react-router-dom';
import DefautColonne from 'src/components/Defect_manager/defautcolonne/DefautColonne';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useSelector} from 'react-redux';
import {useAppThunkDispatch, RootState} from 'src/store';
import {rollbackDefect, toogleGrease, toogleGap, skinUpdate, updateDefect} from 'src/store/lotSlice';
import CustomSwitch from 'src/components/Defect_manager/customswitch/CustomSwitch';
import GapSwitchButton from 'src/components/Defect_manager/gapwitchbutton/GapSwitchButton';
import update from 'immutability-helper';
import {Skin} from 'src/models/Skin';
import {Defect} from 'src/models/Defect';
import skinImage from 'src/assets/images/Skin.png';

type ModalPlateauInfoProps = {
  handleClose: () => void;
  skin: any;
};

export default function UpdateSkinModal(props: ModalPlateauInfoProps): JSX.Element {
  const {handleClose, skin} = props;
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const skins = useSelector<RootState, Skin[] | undefined>((state) => state.lot.skinLot);
  const [working, setWorking] = useState(false);

  if (skins == undefined) {
    return <div></div>;
  }

  const currentSkinIndex = skins.findIndex((s) => s.number === parseInt(skin.row.number, 10));
  const currentSkin = currentSkinIndex !== -1 ? skins[currentSkinIndex] : undefined;

  if (currentSkin === undefined) {
    return <div></div>;
  }

  const [defectHistory, setDefautHistory] = useState<Defect[]>([currentSkin.defect]);
  const [defectBackHistory, setDefautBackHistory] = useState<Defect[]>([]);

  const updateDefaut = (key: keyof Defect, defect: [number, number, number, number]) => {
    const newDefaut = update(currentSkin.defect, {[key]: {$set: defect}});
    setDefautHistory([...defectHistory, newDefaut]);
    setDefautBackHistory([]);
    dispatch(updateDefect({potId: currentSkin.id, key, singleDefect: defect}));
  };

  const onCancel = () => {
    if (defectHistory.length > 1) {
      const last = defectHistory.pop();
      if (last !== undefined) {
        setDefautBackHistory([...defectBackHistory, Object.assign({}, last)]);
        setDefautHistory(defectHistory);
        dispatch(
          rollbackDefect({potId: currentSkin.id, defect: Object.assign({}, defectHistory[defectHistory.length - 1])}),
        );
      }
    }
  };
  const onRepete = () => {
    const prev = defectBackHistory.pop();
    if (prev !== undefined) {
      setDefautHistory([...defectHistory, Object.assign({}, prev)]);
      setDefautBackHistory(defectBackHistory);
      dispatch(rollbackDefect({potId: currentSkin.id, defect: Object.assign({}, prev)}));
    }
  };

  const closeModale = async () => {
    handleClose();
  };

  const saveSkin = async () => {
    setWorking(true);
    await dispatch(skinUpdate(currentSkin));
    setWorking(false);
    handleClose();
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Modifier la peau</DialogTitle>
      <DialogContent className={classes.modal}>
        <div className={classes.skin} style={{backgroundImage: `url(${skinImage})`}}>
          <DefautColonne
            title="ÉPAULE GAUCHE"
            skinDefect={currentSkin.defect.shoulder_left}
            onUpdate={(defect) => updateDefaut('shoulder_left', defect)}
          />
          <DefautColonne
            title="COLLET"
            skinDefect={currentSkin.defect.neck}
            onUpdate={(defect) => updateDefaut('neck', defect)}
          />
          <DefautColonne
            title="ÉPAULE DROITE"
            skinDefect={currentSkin.defect.shoulder_right}
            onUpdate={(defect) => updateDefaut('shoulder_right', defect)}
          />
          <div className={classes.break} />
          <DefautColonne
            title="CUISSE GAUCHE"
            skinDefect={currentSkin.defect.leg_left}
            onUpdate={(defect) => updateDefaut('leg_left', defect)}
          />
          <DefautColonne
            title="CROUPON"
            skinDefect={currentSkin.defect.back}
            onUpdate={(defect) => updateDefaut('back', defect)}
            noEchancrure
          />
          <DefautColonne
            title="CUISSE DROITE"
            skinDefect={currentSkin.defect.leg_right}
            onUpdate={(defect) => updateDefaut('leg_right', defect)}
          />
        </div>
        <div className={classes.footer}>
          <div>
            <Button variant="contained" onClick={onCancel} disabled={defectHistory.length === 1}>
              ANNULER
            </Button>
            <Button
              variant="contained"
              onClick={onRepete}
              className={classes.button}
              disabled={defectBackHistory.length === 0}>
              RETABLIR
            </Button>
          </div>
          <div>
            <CustomSwitch
              checked={currentSkin.degrease === false}
              disabled={working}
              onChange={async () => {
                setWorking(true);
                await dispatch(toogleGrease(currentSkin.id));
                setWorking(false);
              }}
            />
          </div>
          <div>
            <GapSwitchButton
              checked={currentSkin.gap === true}
              disabled={working}
              onChange={async () => {
                setWorking(true);
                await dispatch(toogleGap(currentSkin.id));
                setWorking(false);
              }}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className={classes.button} onClick={closeModale}>
          Annuler
        </Button>
        <Button variant="contained" className={classes.button} onClick={saveSkin}>
          Sauvgarder
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  page: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: -24,
    marginRight: -24,
    paddingTop: 24,
    position: 'relative',
  },
  header: {
    minHeight: 156,
    display: 'flex',
    flexDirection: 'row',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    '& > *': {
      flex: 1,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  },

  skin: {
    height: 'calc(100% - 316px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRight: 'solid #707070 1px',
    borderTop: 'solid #707070 1px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: '50%',
  },
  break: {
    height: 0,
    flexBasis: '100%',
  },
  modal: {
    padding: 0,
  },
  footer: {
    padding: 10,
    minHeight: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: 10,
  },
});
