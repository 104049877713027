import React, {useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import {Button} from '@mui/material';
import {RootState, useAppThunkDispatch} from 'src/store';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {fetchSalageSkin} from 'src/store/lotSlice';
import SkinList from 'src/components/Defect_manager/skinlist/SkinList';
import {Container} from '@mui/material';
import ModalSkinUpdate from 'src/components/Defect_manager/modalskinupdate/modalskinupdate';
import {Skin} from 'src/models/Skin';

export default function SkinListView(): JSX.Element {
  const {lot, skin} = useParams<{lot: string; skin: string | undefined}>();
  const classes = useStyles();
  const navigate = useNavigate();
  const [showModalSkinUpdate, setShowModalSkinUpdate] = React.useState(false);
  const dispatch = useAppThunkDispatch();
  const currentLot = useSelector((state: RootState) => state.lot.currentLot);
  const skins = useSelector((state: RootState) => state.lot.skinLot);
  const [skinItem, setSkinItem] = React.useState<Skin>();

  function addSkin(item: Skin) {
    setSkinItem(item);
    setShowModalSkinUpdate(true);
  }

  useEffect(() => {
    if (lot !== undefined) {
      dispatch(fetchSalageSkin(lot));
    }
  }, [lot]);

  return (
    <Container className={classes.root}>
      <div className={classes.page}>
        <div className={classes.header}>
          {skin ? (
            <div onClick={() => navigate(`/salage/${lot}/${skin}`)}>&lt; RETOUR</div>
          ) : (
            <div onClick={() => navigate('/salage/')}>&lt; RETOUR</div>
          )}
        </div>
        <div className={classes.navigation}>
          <div>LOT {currentLot ? currentLot.name : ''}</div>
          <Button variant="contained" onClick={() => navigate(`/salage/${lot}/resume`)}>
            VOIR LOT TOTAL
          </Button>
        </div>
        <div className={classes.skin}>{skins && <SkinList skins={skins} handleClose={(item) => addSkin(item)} />}</div>
        {showModalSkinUpdate && skinItem && currentLot && (
          <ModalSkinUpdate handleClose={() => setShowModalSkinUpdate(false)} skin={skinItem} lot={currentLot} />
        )}
      </div>
    </Container>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
  },
  page: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: -24,
    marginRight: -24,
    paddingTop: 24,
    position: 'relative',
  },
  header: {
    minHeight: 68,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 'solid 1px',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    '& > *': {
      flex: 1,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  blockInfo: {
    width: 150,
  },
  navigation: {
    padding: '0 20px',
    minHeight: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 101,
    '&.isC3': {
      color: 'white',
    },
  },
  skin: {
    height: 'calc(100% - 316px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRight: 'solid #707070 1px',
    backgroundSize: '70%',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 120,
    backgroundPositionY: 0,
  },
  break: {
    height: 0,
    flexBasis: '100%',
  },
  footer: {
    padding: 10,
    minHeight: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: 10,
  },
});
