import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import SupplierTab from 'src/components/Lot_manager/tabs/SupplierTab';
import Typography from '@mui/material/Typography';
import LotTab from 'src/components/Lot_manager/tabs/LotTab';
import Paper from '@mui/material/Paper';
import CreateContactModal from 'src/components/Lot_manager/modals/CreateContactModal';
import UpdateSupplierModal from 'src/components/Lot_manager/modals/UpdateSupplierModal';
import UpdateContactModal from 'src/components/Lot_manager/modals/UpdateContactModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useSelector} from 'react-redux';
import {TabContext, TabPanel} from '@mui/lab';
import {setLot} from 'src/store/lotSlice';
import {deleteSupplier} from 'src/store/supplierSlice';
import {createContact, updateContact} from 'src/store/supplierSlice';
import {RootState, useAppThunkDispatch} from 'src/store';
import {Contact} from 'src/models/Contact';
import {Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useNavigate} from 'react-router-dom';
import {ContactType} from 'src/models/ContactType';

export default function SupplierResume() {
  const [open, setOpen] = React.useState(false);
  const [modalUpdateContactOpen, setModalUpdateContactOpen] = React.useState(false);
  const [contact, setContact] = React.useState<Contact>();
  const [modalAddSupplierOpen, setModalAddSupplierOpen] = useState(false);
  const [modalUpdateSupplierOpen, setModalUpdateSupplierOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'1' | '2'>('1');
  const supplier = useSelector((state: RootState) => state.supplier?.currentSupplier);
  const lots = useSelector((state: RootState) => state.lot.lots);
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const updateContactModal = (contact: Contact) => {
    setContact(contact);
    setModalUpdateContactOpen(true);
  };

  const lotsEnCours = lots.filter((lot) => {
    if (supplier) {
      return lot.supplier.id == supplier.id;
    }
  });

  const onContactCreate = (
    supplieID: string,
    name: string,
    contactType: ContactType,
    email: string,
    phoneNumber: string,
  ) => {
    dispatch(
      createContact({
        supplieID,
        name,
        contactType,
        email,
        phoneNumber,
      }),
    )
      .unwrap()
      .then(() => setModalAddSupplierOpen(false));
  };

  const onContactUpdate = (contact: Contact) => {
    if (contact != undefined) {
      dispatch(updateContact(contact))
        .unwrap()
        .then(() => setModalUpdateContactOpen(false));
    }
  };

  const handleDelete = (id: string | undefined) => {
    if (id) {
      dispatch(deleteSupplier(id));
    }
    handleClose();
    navigate('/gestion-des-lots/fournisseurs');
  };

  return (
    <Grid item xs={12} md={12} lg={12} spacing={3}>
      <Grid item={true} container p={3}>
        <Grid item={true} xs={8}>
          <Button variant="text" className={classes.btn} onClick={() => navigate('/gestion-des-lots/fournisseurs')}>
            &lt; Retour à la liste
          </Button>
        </Grid>
        <Grid item={true} xs={4} container justifyContent="flex-end">
          <Button variant="contained" className={classes.btn} color="error" onClick={() => setOpen(true)}>
            Supprimer
          </Button>
          <Button variant="contained" className={classes.btn} onClick={() => setModalUpdateSupplierOpen(true)}>
            Modifier
          </Button>
        </Grid>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Container className={classes.root}>
          <div className={classes.page}>
            <Grid container spacing={0}>
              <Grid item={true} xs={8} p={2}>
                <Typography variant="h4">{supplier ? supplier.name : null}</Typography>
              </Grid>
              <Grid item={true} xs={4}>
                {supplier && (
                  <Button
                    variant="contained"
                    className={classes.btn}
                    onClick={() => navigate(`/gestion-des-lots/fournisseurs/${supplier.id}/plans`)}>
                    Cometta configuration
                  </Button>
                )}
                <Button variant="contained" className={classes.btn} onClick={() => setModalAddSupplierOpen(true)}>
                  Ajouter un contact
                </Button>
              </Grid>
            </Grid>
            <Grid item={true} container p={0}>
              {supplier && supplier.contacts?.length != 0 ? (
                supplier.contacts?.map((contact) => (
                  <Grid
                    key={contact.id}
                    className={classes.desk}
                    item={true}
                    xs={6}
                    md={5}
                    lg={3}
                    p={3}
                    onClick={() => updateContactModal(contact)}>
                    <Typography variant="body1">{contact.contactType}</Typography>
                    {contact.name ? (
                      <Typography variant="body2" className={classes.desktype}>
                        Contact : <Typography variant="subtitle2">{contact.name}</Typography>
                      </Typography>
                    ) : null}
                    <Typography variant="body1" className={classes.desktype}>
                      Coordonnées : <Typography variant="subtitle2">{contact.email}</Typography>
                      <Typography variant="subtitle2">{contact.phoneNumber}</Typography>
                    </Typography>
                  </Grid>
                ))
              ) : (
                <Grid className={classes.desk} item={true} xs={3} p={4}>
                  <Typography variant="subtitle2">Pas de contact</Typography>
                </Grid>
              )}
            </Grid>
            {modalAddSupplierOpen && supplier && (
              <CreateContactModal
                handleSubmit={onContactCreate}
                supplier={supplier}
                handleClose={() => setModalAddSupplierOpen(false)}
              />
            )}
            {modalUpdateSupplierOpen && supplier && (
              <UpdateSupplierModal supplierStat={supplier} handleClose={() => setModalUpdateSupplierOpen(false)} />
            )}
            {modalUpdateContactOpen && contact && (
              <UpdateContactModal
                contactStat={contact}
                handleClose={() => setModalUpdateContactOpen(false)}
                handleSubmit={onContactUpdate}
              />
            )}
            {supplier && (
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{'Confirmation de la suppression'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Êtes-vous sûr de vouloir supprimer le fournisseur &quot;{supplier.name}&quot; ?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>NON</Button>
                  <Button onClick={() => handleDelete(supplier.id)} autoFocus>
                    OUI
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            <TabContext value={selectedTab}>
              <TabPanel value="1" className={classes.tabPanel}>
                <div className={classes.tabContainer}>
                  <LotTab
                    lots={lotsEnCours}
                    onClick={async (lot) => {
                      dispatch(setLot(lot));
                      navigate(`/gestion-des-lots/${lot.id}/detail`);
                    }}
                  />
                </div>
              </TabPanel>
            </TabContext>
          </div>
        </Container>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  desk: {
    backgroundColor: '#F5F6FA',
    borderRadius: 10,
    margin: 10,
    cursor: 'pointer',
  },
  btn: {
    margin: 5,
  },
  desktype: {
    marginTop: 25,
  },
  icon: {
    marginLeft: 5,
    marginRight: 5,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  page: {
    paddingTop: theme.spacing(3),
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tabPanel: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
  },
  tabContainer: {
    height: '100vh',
  },
  root: {
    maxWidth: '100%',
    display: 'grid',
    ' height': '-webkit-fill-available',
  },
}));
