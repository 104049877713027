import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import {IpAddress} from 'src/models/IpAddress';
import {axiosInstance} from 'src/security/useAxios';

export interface IpAddressState {
  IpAddresses: IpAddress[];
}

const initialState: IpAddressState = {
  IpAddresses: [],
};

export const fetchIpAddresses = createAsyncThunk<IpAddress[]>('ipAddress/fetch', async () => {
  const IpAddresses = await axiosInstance.get('/ip-address', {});

  return IpAddresses.data;
});

export const createIpAddress = createAsyncThunk<IpAddress, Omit<IpAddress, 'id'>>(
  'ipAddress/create',
  async (ipAddressData) => {
    const newIpAddress = await axiosInstance.post('/ip-address/create', {
      name: ipAddressData.name,
      address: ipAddressData.address,
    });

    return newIpAddress.data;
  },
);

export const updateIpAddress = createAsyncThunk<IpAddress[], IpAddress>(
  'ipAddress/update',
  async ({id, name, address}) => {
    const ipAddressID = await axiosInstance.post(`/ip-address/${id}/update`, {
      name: name,
      address: address,
    });

    return ipAddressID.data;
  },
);

export const deleteIpAddress = createAsyncThunk<IpAddress[], string>('ipAddress/delete', async (id: string) => {
  const ipAddressID = await axiosInstance.post(`/ip-address/${id}/delete`, {});

  return ipAddressID.data;
});

export const ipAddressSlice = createSlice({
  name: 'IpAddress',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchIpAddresses.fulfilled, (state, action) => {
      state.IpAddresses = action.payload;
    });
    builder.addCase(createIpAddress.fulfilled, (state, action) => {
      state.IpAddresses = [...state.IpAddresses, action.payload];
    });
    builder.addCase(updateIpAddress.fulfilled, (state, action) => {
      state.IpAddresses = action.payload;
    });
    builder.addCase(deleteIpAddress.fulfilled, (state, action) => {
      state.IpAddresses = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = ipAddressSlice.actions;

export default ipAddressSlice.reducer;
