import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from '@mui/material';
import {Lot} from 'src/models/Lot';
import {useSelector} from 'react-redux';
import {RootState, useAppThunkDispatch} from 'src/store';
import {fetchSalageSkin, createSalageTray, setPlateau, setBac} from 'src/store/lotSlice';
import NumberTextField from '../numbertextfield/NumberTextField';

type ModalSaisieInfoProps = {
  lot: Lot;
  handleClose: () => void;
  handleNext: (first: number) => void;
};

export default function ModalSaisieInfo(props: ModalSaisieInfoProps): JSX.Element {
  const {lot, handleClose, handleNext} = props;
  const dispatch = useAppThunkDispatch();

  const currentPlateau = useSelector((state: RootState) => state.lot.plateau);
  const currentBac = useSelector((state: RootState) => state.lot.bac);

  const [first, setFirst] = useState<number | undefined>();
  const [firstError, setFirstError] = useState<string | undefined>();
  const [temperature, setTemperature] = useState<number | undefined>();
  const [temperatureError, setTemperatureError] = useState<string | undefined>();
  const [plateauInput, setPlateauInput] = useState<string>(currentPlateau ?? '');
  const [bacInput, setBacInput] = useState<string>(currentBac ?? '');
  const [plateauError, setPlateauError] = useState<string | undefined>();
  const [bacError, setBacError] = useState<string | undefined>();

  const onSubmit = () => {
    let hasError = false;
    if (first == undefined || first < lot.first_skin || first > lot.last_skin) {
      setFirstError(`Numéro de peau invalid. Doit être entre ${lot.first_skin} et ${lot.last_skin}`);
      hasError = true;
    }
    if (temperature == undefined) {
      setTemperatureError('Obligatoire');
      hasError = true;
    }
    if (bacInput == undefined || bacInput == '') {
      setBacError('Obligatoire');
      hasError = true;
    }
    if (plateauInput == undefined || plateauInput == '') {
      setPlateauError('Obligatoire');
      hasError = true;
    }
    if (hasError) {
      return;
    }
    if (temperature && first) {
      dispatch(setPlateau(plateauInput));
      dispatch(setBac(bacInput));
      dispatch(
        createSalageTray({lot: lot.id, temperature: temperature, first_skin: first, name: plateauInput, bac: bacInput}),
      )
        .unwrap()
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .then(() => handleNext(first!));
    }
  };

  useEffect(() => {
    dispatch(fetchSalageSkin(lot.id));
  }, [lot]);

  useEffect(() => {
    if (currentPlateau && currentBac) {
      setPlateauInput(currentPlateau);
      setPlateauError(undefined);
      setBacInput(currentBac);
      setBacError(undefined);
    }
  }, [currentPlateau, currentBac]);

  return (
    <Dialog open>
      <DialogTitle>LOT {lot.name}</DialogTitle>
      <DialogContent>
        <NumberTextField
          autoFocus
          margin="dense"
          label="Permier numéro du bac"
          type="number"
          fullWidth
          variant="standard"
          value={first}
          error={firstError !== undefined}
          helperText={firstError}
          onNumberChange={(e) => setFirst(e)}
        />
        <NumberTextField
          margin="dense"
          label="Température du bac"
          type="number"
          fullWidth
          variant="standard"
          value={temperature}
          error={temperatureError !== undefined}
          helperText={temperatureError}
          onNumberChange={(e) => setTemperature(e)}
        />
        <TextField
          margin="dense"
          label="Identifiant plateau"
          type="text"
          fullWidth
          variant="standard"
          error={plateauError !== undefined}
          helperText={plateauError}
          value={plateauInput}
          onChange={(e) => setPlateauInput(e.target.value.toUpperCase())}
        />
        <TextField
          margin="dense"
          label="Identifiant bac"
          type="text"
          fullWidth
          variant="standard"
          error={bacError !== undefined}
          helperText={bacError}
          value={bacInput}
          onChange={(e) => setBacInput(e.target.value.toUpperCase())}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Démarrer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
