import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useAppThunkDispatch} from 'src/store';
import {trayDelete} from 'src/store/traySlice';
import {Tray} from 'src/models/Tray';

type ModalDeleteTrayProps = {
  tray: Tray;
  handleClose: () => void;
};

export default function ModalDeleteTray(props: ModalDeleteTrayProps) {
  const {tray, handleClose} = props;
  const dispatch = useAppThunkDispatch();

  const deleteTray = () => {
    if (tray && tray.id) {
      dispatch(trayDelete(tray.id));
    }
    handleClose();
  };

  return (
    <Dialog open fullWidth maxWidth={'lg'}>
      <DialogTitle>{'Confirmation de la suppression'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Ce plateau est vide !
          <br /> Voulez-vous supprimer le plateau N° {tray.name} ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>NON</Button>
        <Button onClick={deleteTray} autoFocus>
          OUI
        </Button>
      </DialogActions>
    </Dialog>
  );
}
