import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {useNavigate, useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {fetchDessalageCurrentPalletsSkins} from 'src/store/palletSlice';
import DetailSkinModal from 'src/components/Desalting_manager/modals/DetailSkinModal';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import SkinTab from 'src/components/Desalting_manager/tabs/SkinTab';
import {RootState, useAppThunkDispatch} from 'src/store';
import {useSelector} from 'react-redux';
import {Skin} from 'src/models/Skin';

export default function Detail() {
  const classes = useStyles();
  const skins = useSelector((state: RootState) => state.pallet?.skinsList);
  const currentSkin = useSelector((state: RootState) => state.pallet?.currentSkin);
  const tray = useSelector((state: RootState) => state.tray.currentTray);
  const [detailSkin, setDetailSkin] = useState<Skin>();
  const [detailSkinModalOpen, setDetailSkinModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();

  const fetchData = async () => {
    if (tray && tray.supplier_id) {
      await dispatch(fetchDessalageCurrentPalletsSkins(tray.supplier_id));
    }
  };

  const openSkinsDetail = (skin: Skin) => {
    if (skin) {
      setDetailSkin(skin);
      setDetailSkinModalOpen(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid item={true} container spacing={0}>
        <Grid item={true} xs={6}>
          {currentSkin && tray ? (
            <Button
              variant="text"
              className={classes.btn}
              onClick={() => navigate(`/dessalage/plateau/${tray.id}/peaux/${currentSkin.number}`)}>
              &lt; Retour
            </Button>
          ) : (
            <Button variant="text" className={classes.btn} onClick={() => navigate(`/dessalage`)}>
              &lt; Retour
            </Button>
          )}
        </Grid>
        <Grid item={true} xs={6} container justifyContent="flex-end">
          {/* <Button variant="contained" className={classes.btn} onClick={() => navigate(`/dessalage/editer`)}>
            Modifier le plan
          </Button> */}
        </Grid>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Container className={classes.root}>
          <div className={classes.page}>
            {detailSkin && detailSkinModalOpen && (
              <DetailSkinModal currentSkin={detailSkin} handleClose={() => setDetailSkinModalOpen(false)} />
            )}
            <Grid item={true} xs={12} container justifyContent="center">
              {skins && (
                <SkinTab
                  skins={skins}
                  onClick={async (skin) => {
                    openSkinsDetail(skin);
                  }}
                />
              )}
            </Grid>
          </div>
        </Container>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100%',
  },
  page: {
    height: `100%`,
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    margin: 10,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
