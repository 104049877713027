import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CreateContactTypeModal from 'src/components/Lot_manager/modals/CreateContactTypeModal';
import UpdateContactTypeModal from 'src/components/Lot_manager/modals/UpdateContactTypeModal';
import {useSelector} from 'react-redux';
import {RootState, useAppThunkDispatch} from 'src/store';
import {fetchContactTypes, deleteContactType} from 'src/store/contactTypeSlice';
import {ContactType} from 'src/models/ContactType';

export default function ContactTypeTab(): JSX.Element {
  const [open, setOpen] = React.useState(false);
  const contactTypes = useSelector((state: RootState) => state.contactType.contactTypes);
  const [contactType, setContactType] = React.useState<ContactType>();
  const [modalUpdateCategoryOpen, setModalUpdateCategoryOpen] = useState(false);
  const [modalAddCategoryOpen, setModalAddCategoryOpen] = useState(false);
  const dispatch = useAppThunkDispatch();

  useEffect(() => {
    dispatch(fetchContactTypes());
  }, []);

  const handleClickOpenUpdateModal = (contactType: ContactType) => {
    setContactType(contactType);
    setModalUpdateCategoryOpen(true);
  };

  const handleClickOpenDeleteModal = (contactType: ContactType) => {
    setContactType(contactType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (id: string | undefined) => {
    if (id) {
      dispatch(deleteContactType(id));
    }
    handleClose();
  };

  return (
    <TableContainer>
      <Grid container spacing={0} mt={2} mb={2}>
        <Grid container justifyContent="flex-end">
          <Button variant="contained" onClick={() => setModalAddCategoryOpen(true)}>
            ajouter un type
          </Button>
          {modalAddCategoryOpen && <CreateContactTypeModal handleClose={() => setModalAddCategoryOpen(false)} />}
          {modalUpdateCategoryOpen && contactType && (
            <UpdateContactTypeModal
              contactTypeStat={contactType}
              handleClose={() => setModalUpdateCategoryOpen(false)}
            />
          )}
        </Grid>
      </Grid>
      <Table sx={{minWidth: 650}} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contactTypes &&
            contactTypes.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  <IconButton color="primary" aria-label="update" onClick={() => handleClickOpenUpdateModal(row)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" aria-label="delete" onClick={() => handleClickOpenDeleteModal(row)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {contactType && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Confirmation de la suppression'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Êtes-vous sûr de vouloir supprimer le type de contact &quot;{contactType.name}&quot; ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>NON</Button>
            <Button onClick={() => handleDelete(contactType.id)} autoFocus>
              OUI
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </TableContainer>
  );
}
