import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import NumberTextField from 'src/components/Defect_manager/numbertextfield/NumberTextField';
import {makeStyles} from '@mui/styles';
import {RootState, useAppThunkDispatch} from 'src/store';
import {fetchDessalageCategories} from 'src/store/categorySlice';
import {generatePalletName} from 'src/store/palletSlice';
import {useSelector} from 'react-redux';
import {Pallet} from 'src/models/Pallet';

type CreatePalletModalProps = {
  addNewPallet: (category: string, name: string, capacity: number, position_x: number, position_y: number) => void;
  handleClose: () => void;
};

export default function CreatePalletModal(props: CreatePalletModalProps): JSX.Element {
  const {addNewPallet, handleClose} = props;
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();
  const categoriesState = useSelector((state: RootState) => state.category.categories);
  const [pallet, setPallet] = useState('');
  const currentSkin = useSelector((state: RootState) => state.pallet.currentSkin);
  const palletName = useSelector((state: RootState) => state.pallet.palletName);
  const [capacity, setCapacity] = useState<number | undefined>(0);
  const [category, setCategory] = useState<string>('');

  useEffect(() => {
    dispatch(fetchDessalageCategories());
    if (currentSkin && currentSkin.supplier_id) {
      dispatch(generatePalletName({supplierID: currentSkin.supplier_id}))
        .unwrap()
        .then((response) => setPallet(response));
    }
  }, []);

  const formValid = pallet !== '' && category !== '' && capacity != undefined;

  const create = () => {
    if (formValid) {
      addNewPallet(category, pallet, capacity, 50, 100);
      handleClose();
    }
  };

  return (
    <Dialog open fullWidth maxWidth={'lg'}>
      <DialogTitle>Ajouter une nouvelle palette</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item={true} xs={12} p={2}>
            <TextField
              margin="dense"
              label="N° Palette :"
              type="text"
              fullWidth
              variant="standard"
              value={pallet}
              onChange={(e) => setPallet(e.target.value.toUpperCase())}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <NumberTextField
              margin="dense"
              label="Capacité :"
              placeholder="Capacité maximal de peaux"
              type="number"
              fullWidth
              variant="standard"
              value={capacity}
              onNumberChange={(e) => setCapacity(e)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item={true} xs={12} p={2}>
            <TextField
              id="outlined-select-currency"
              className={classes.input}
              fullWidth
              select
              value={category}
              label="Categories :"
              onChange={(e) => setCategory(e.target.value)}>
              {categoriesState.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={create}>
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 10,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
