import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {RootState, useAppThunkDispatch} from 'src/store';
import {createCategory} from 'src/store/categorySlice';

type ModalCreateCategoryProps = {
  handleClose: () => void;
};

export default function CreateCategoryModal(props: ModalCreateCategoryProps): JSX.Element {
  const {handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');

  const onCategoryCreate = (name: string) => {
    dispatch(
      createCategory({
        name,
      }),
    )
      .unwrap()
      .then(() => handleClose);
  };

  const onSubmit = () => {
    let hasError = false;

    if (name.length == 0) {
      setNameError('Le nom est obligatoire !');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    onCategoryCreate(name);
    handleClose();
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Ajouter une nouvelle categorie</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nom :"
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={nameError.length != 0}
          helperText={nameError}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
