import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import {persistor, store} from './store';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {CssBaseline, Theme, StyledEngineProvider} from '@mui/material';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {PersistGate} from 'redux-persist/integration/react';
import AppRoutes from './routes/AppRoutes';
import './style/index.css';

const theme = createTheme();
declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

if (process.env.REACT_APP_ENV_ENVIRONEMENT == 'production') {
  Sentry.init({
    dsn: 'https://725dbd85ddf84e0f8b0884478ad5618a@o1130690.ingest.sentry.io/4504723264176128',
    integrations: [new Integrations.BrowserTracing()],
    autoSessionTracking: true,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<div>Loading...</div>}>
              <BrowserRouter>
                <CssBaseline />
                <AppRoutes />
              </BrowserRouter>
            </Suspense>
          </ThemeProvider>
        </StyledEngineProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
