import React, {useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import DefautColonne from 'src/components/Defect_manager/defautcolonne/DefautColonne';
import {Button} from '@mui/material';
import {RootState, useAppThunkDispatch} from 'src/store';
import {useNavigate, useParams} from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {format, parseISO} from 'date-fns';
import skinImage from 'src/assets/images/Skin.png';
import {useSelector} from 'react-redux';
import {fetchSalageSkin} from 'src/store/lotSlice';
import {Container} from '@mui/material';

export default function SkinResume(): JSX.Element {
  const {lot} = useParams<{lot: string}>();
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useAppThunkDispatch();

  const currentLot = useSelector((state: RootState) => state.lot.currentLot);
  const skins = useSelector((state: RootState) => state.lot.skinLot);

  if (skins === undefined || currentLot === undefined) {
    return <div></div>;
  }

  useEffect(() => {
    if (lot !== undefined) {
      dispatch(fetchSalageSkin(lot));
    }
  }, [lot]);

  const c3list = skins.filter((p) => p.c3);
  const bacs = [
    ...new Map(
      skins
        .map((p) => p.tray)
        .filter((i) => i !== undefined)
        .map((b) => [b.first_skin, b]),
    ).values(),
  ];

  const reducer = (array: [number, number, number, number][]): [number, number, number, number] => {
    return array.reduce(
      (sum, cur) => {
        sum[0] += cur[0];
        sum[1] += cur[1];
        sum[2] += cur[2];
        sum[3] += cur[3];
        return sum;
      },
      [0, 0, 0, 0],
    );
  };

  const shoulder_left = reducer(skins.map((p) => p.defect.shoulder_left));
  const neck: [number, number, number, number] = reducer(skins.map((p) => p.defect.neck));
  const shoulder_right: [number, number, number, number] = reducer(skins.map((p) => p.defect.shoulder_right));
  const leg_left: [number, number, number, number] = reducer(skins.map((p) => p.defect.leg_left));
  const back: [number, number, number, number] = reducer(skins.map((p) => p.defect.back));
  const leg_right: [number, number, number, number] = reducer(skins.map((p) => p.defect.leg_right));

  return (
    <Container className={classes.root}>
      <div className={classes.page}>
        <div className={classes.header}>
          <Button variant="text" onClick={() => navigate('/salage/')}>
            &lt; RETOUR
          </Button>
          <div className={classes.details}>
            <div>
              <div className={classes.blockInfo}>
                <div>Date d’abattage :</div>
                <div>{format(parseISO(currentLot.slaughter_date), 'dd-MM-yy')}</div>
              </div>
            </div>
            <div>
              <div className={classes.blockInfo}>
                <div>Températures :</div>
                <div>{bacs.map((b) => b.temperature).join('°C, ')}°C</div>
              </div>
              <div className={classes.blockInfo}>
                <div>Dégraissage :</div>
                <div>{Math.round((skins.filter((p) => p.degrease).length / skins.length) * 100)}%</div>
              </div>
            </div>
          </div>
          <div>
            <div>C3</div>
            <div>
              {c3list.map((c3, index) => (
                <React.Fragment key={c3.id}>
                  <FormControlLabel control={<Checkbox checked={c3.isolate} />} label={c3.number} />
                  {index !== 0 && index % 2 === 0 && <div className={classes.break} />}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.navigation}>
          <div>LOT TOTAL {currentLot.name}</div>
        </div>
        <div className={classes.skin} style={{backgroundImage: `url(${skinImage})`}}>
          <DefautColonne title="ÉPAULE GAUCHE" skinDefect={shoulder_left} />
          <DefautColonne title="COLLET" skinDefect={neck} />
          <DefautColonne title="ÉPAULE DROITE" skinDefect={shoulder_right} />
          <div className={classes.break} />
          <DefautColonne title="CUISSE GAUCHE" skinDefect={leg_left} />
          <DefautColonne title="CROUPON" skinDefect={back} />
          <DefautColonne title="CUISSE DROITE" skinDefect={leg_right} />
        </div>
        <div className={classes.footer}>
          <Button variant="contained" onClick={() => navigate(`/salage/${lot}/list`)}>
            DÉTAIL DES PEAUX
          </Button>
          <Button
            variant="contained"
            className={classes.button}
            onClick={() => navigate(`/salage/${lot}/resume/print`)}>
            IMPRIMER
          </Button>
          <Button variant="contained" className={classes.button} onClick={() => navigate('/salage/')}>
            TERMINER
          </Button>
        </div>
      </div>
    </Container>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
  },
  page: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: -24,
    marginRight: -24,
    paddingTop: 24,
    position: 'relative',
  },
  header: {
    minHeight: 156,
    display: 'flex',
    flexDirection: 'row',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    '& > *': {
      flex: 1,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  blockInfo: {
    width: 150,
  },
  c3Overlay: {
    backgroundColor: 'rgba(240, 20, 46, 0.81)',
    position: 'absolute',
    top: 180,
    height: 'calc(100% - 180px)',
    width: '100%',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  navigation: {
    padding: '0 20px',
    minHeight: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'solid 1px #707070',
    marginBottom: 30,
    zIndex: 101,
    '&.isC3': {
      color: 'white',
    },
  },
  skin: {
    height: 'calc(100% - 316px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRight: 'solid #707070 1px',
    borderTop: 'solid #707070 1px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: '50%',
  },
  break: {
    height: 0,
    flexBasis: '100%',
  },
  footer: {
    padding: 10,
    minHeight: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: 10,
  },
});
