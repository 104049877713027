import React, {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {Pallet} from 'src/models/Pallet';

type PalletProps = {
  pallets: Pallet[];
};

export default function PalletDesk(props: PalletProps) {
  const {pallets} = props;

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <TableContainer>
        <Table sx={{minWidth: 650}} aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell>PALETTE</TableCell>
              <TableCell align="right">Nombre de peaux</TableCell>
              <TableCell align="right">Categorie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pallets &&
              pallets.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.skinsAmount}</TableCell>
                  <TableCell align="right">{row.category}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
