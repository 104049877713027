import React from 'react';
import {Routes, Route} from 'react-router-dom';
import DefectManagerRouter from './DefectManagerRouter';
import DesaltingManagerRouter from './DesaltingManagerRouter';
import LotManagerRouter from './LotManagerRouter';
import AuthenticateMiddleware from '../middlewares/AuthenticateMiddleware';
import LoginRequiredRoute from '../middlewares/LoginRequiredRoute';
import Login from '../views/Authenticate/Login';
import Signin from '../views/Defect_manager/Signin';
import Index from '../views/Index';
import Error from '../views/Error';
import {Container} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ErrorModal from 'src/components/ErrorModal';

export default function AppRoutes(): JSX.Element {
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <ErrorModal></ErrorModal>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="login" element={<Login />} />
        <Route path="signin" element={<Signin />} />
        <Route element={<AuthenticateMiddleware />}>
          <Route path="/gestion-des-lots/*" element={<LotManagerRouter />} />
        </Route>
        <Route element={<LoginRequiredRoute />}>
          <Route path="/salage/*" element={<DefectManagerRouter />} />
        </Route>
        <Route element={<LoginRequiredRoute />}>
          <Route path="/dessalage/*" element={<DesaltingManagerRouter />} />
        </Route>
        <Route path="/*" element={<Error />} />
        <Route path="/404" element={<Error />} />
      </Routes>
    </Container>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
    width: '100%',
    padding: 0,
    maxWidth: '100%',
  },
});
