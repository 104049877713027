import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {RootState, useAppThunkDispatch} from 'src/store';
import {useNavigate, useParams} from 'react-router-dom';
import {format, parseISO} from 'date-fns';
import logoTouraineCuirs from 'src/assets/images/Logo-Tourain-Cuirs.png';
import cuirsCoutelures from 'src/assets/images/Cuirs_Coutelures.jpeg';
import cuirsdechirures from 'src/assets/images/Cuirs_Dechirures.jpeg';
import cuirsechancrures from 'src/assets/images/Cuirs_Echancrures.jpeg';
import cuirsTrous from 'src/assets/images/Cuirs_Trous.jpeg';
import {useSelector} from 'react-redux';
import {fetchSalageSkin} from 'src/store/lotSlice';
import {Container} from '@mui/material';
import {Skin} from 'src/models/Skin';

const getC2Total = (skins: Skin[] | undefined): number => {
  let count = 0;
  if (skins != undefined) {
    skins.forEach((skin) => {
      if (skin.c2 === true) {
        count++;
      }
    });
  }

  return count;
};

const getSkinsWitchoutC2 = (skins: Skin[] | undefined): Skin[] | undefined => {
  return skins != undefined ? skins.filter((skin) => skin.c2 === false) : undefined;
};

export default function Print(): JSX.Element {
  const {lot} = useParams<{lot: string}>();
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useAppThunkDispatch();
  const currentLot = useSelector((state: RootState) => state.lot.currentLot);
  const allSkins = useSelector((state: RootState) => state.lot.skinLot);
  const skins = getSkinsWitchoutC2(allSkins);
  const countC2 = getC2Total(allSkins);
  if (skins === undefined || currentLot === undefined) {
    navigate('/salage/');
    return <div></div>;
  }

  useEffect(() => {
    if (lot != undefined) {
      dispatch(fetchSalageSkin(lot)).then(() => {
        document.title = `Touraine Cuirs N° ${lot}`;
        window.print();
        setTimeout(() => {
          history.back();
        }, 2000);
      });
    }
  }, [lot]);

  const sum = (sum: number, current: number) => sum + current;
  const dechirureCrouponPeau = skins.filter((p) => p.defect.back[0] !== 0).length;
  const dechirureshoulder_left = skins.map((p) => p.defect.shoulder_left[0]).reduce(sum);
  const dechirureneck = skins.map((p) => p.defect.neck[0]).reduce(sum);
  const dechirureshoulder_right = skins.map((p) => p.defect.shoulder_right[0]).reduce(sum);
  const dechirureleg_left = skins.map((p) => p.defect.leg_left[0]).reduce(sum);
  const dechirureback = skins.map((p) => p.defect.back[0]).reduce(sum);
  const dechirureleg_right = skins.map((p) => p.defect.leg_right[0]).reduce(sum);
  const dechirureTotal =
    dechirureshoulder_left +
    dechirureneck +
    dechirureshoulder_right +
    dechirureleg_left +
    dechirureback +
    dechirureleg_right;
  const dechirurePeau = skins.filter(
    (p) =>
      p.defect.shoulder_left[0] !== 0 ||
      p.defect.neck[0] !== 0 ||
      p.defect.shoulder_right[0] !== 0 ||
      p.defect.leg_left[0] !== 0 ||
      p.defect.back[0] !== 0 ||
      p.defect.leg_right[0] !== 0,
  ).length;

  const echancrureCrouponPeau = skins.filter((p) => p.defect.back[1] !== 0).length;
  const echancrureshoulder_left = skins.map((p) => p.defect.shoulder_left[1]).reduce(sum);
  const echancrureneck = skins.map((p) => p.defect.neck[1]).reduce(sum);
  const echancrureshoulder_right = skins.map((p) => p.defect.shoulder_right[1]).reduce(sum);
  const echancrureleg_left = skins.map((p) => p.defect.leg_left[1]).reduce(sum);
  const echancrureback = skins.map((p) => p.defect.back[1]).reduce(sum);
  const echancrureleg_right = skins.map((p) => p.defect.leg_right[1]).reduce(sum);
  const echancrureTotal =
    echancrureshoulder_left +
    echancrureneck +
    echancrureshoulder_right +
    echancrureleg_left +
    echancrureback +
    echancrureleg_right;
  const echancrurePeau = skins.filter(
    (p) =>
      p.defect.shoulder_left[1] !== 0 ||
      p.defect.neck[1] !== 0 ||
      p.defect.shoulder_right[1] !== 0 ||
      p.defect.leg_left[1] !== 0 ||
      p.defect.back[1] !== 0 ||
      p.defect.leg_right[1] !== 0,
  ).length;

  const coutelureCrouponPeau = skins.filter((p) => p.defect.back[2] !== 0).length;
  const coutelureshoulder_left = skins.map((p) => p.defect.shoulder_left[2]).reduce(sum);
  const coutelureneck = skins.map((p) => p.defect.neck[2]).reduce(sum);
  const coutelureshoulder_right = skins.map((p) => p.defect.shoulder_right[2]).reduce(sum);
  const coutelureleg_left = skins.map((p) => p.defect.leg_left[2]).reduce(sum);
  const coutelureback = skins.map((p) => p.defect.back[2]).reduce(sum);
  const coutelureleg_right = skins.map((p) => p.defect.leg_right[2]).reduce(sum);
  const coutelureTotal =
    coutelureshoulder_left +
    coutelureneck +
    coutelureshoulder_right +
    coutelureleg_left +
    coutelureback +
    coutelureleg_right;
  const coutelurePeau = skins.filter(
    (p) =>
      p.defect.shoulder_left[2] !== 0 ||
      p.defect.neck[2] !== 0 ||
      p.defect.shoulder_right[2] !== 0 ||
      p.defect.leg_left[2] !== 0 ||
      p.defect.back[2] !== 0 ||
      p.defect.leg_right[2] !== 0,
  ).length;

  const trousCrouponPeau = skins.filter((p) => p.defect.back[3] !== 0).length;
  const trousshoulder_left = skins.map((p) => p.defect.shoulder_left[3]).reduce(sum);
  const trousneck = skins.map((p) => p.defect.neck[3]).reduce(sum);
  const trousshoulder_right = skins.map((p) => p.defect.shoulder_right[3]).reduce(sum);
  const trousleg_left = skins.map((p) => p.defect.leg_left[3]).reduce(sum);
  const trousback = skins.map((p) => p.defect.back[3]).reduce(sum);
  const trousleg_right = skins.map((p) => p.defect.leg_right[3]).reduce(sum);
  const trousTotal = trousshoulder_left + trousneck + trousshoulder_right + trousleg_left + trousback + trousleg_right;
  const trousPeau = skins.filter(
    (p) =>
      p.defect.shoulder_left[3] !== 0 ||
      p.defect.neck[3] !== 0 ||
      p.defect.shoulder_right[3] !== 0 ||
      p.defect.leg_left[3] !== 0 ||
      p.defect.back[3] !== 0 ||
      p.defect.leg_right[3] !== 0,
  ).length;

  return (
    <Container className={classes.root}>
      <div className={classes.page}>
        <div className={classes.heading}>
          <div className={classes.recap}>
            <p>
              <img src={logoTouraineCuirs} />
            </p>
            <p className={classes.title}>
              <strong>
                BILAN DE DEPOUILLE DES
                <br />
                CUIRS
              </strong>
            </p>
            <div className={classes.address}>
              <p>3 rue de Suède </p>
              <p>37100 TOURS</p>
              <p>02 47 54 64 14</p>
              <p>contact@touraine-cuirs.fr</p>
            </div>
          </div>
          <div className={classes.recap}>
            <p>
              Fournisseur : <br />
              <strong>{currentLot.supplier.name}</strong>
            </p>
            <p>
              Lot : <br />
              <strong>{currentLot.name}</strong>
            </p>
            <p>
              Date : <br />
              <strong>{format(parseISO(currentLot.slaughter_date), 'dd-MM-yyyy')}</strong>
            </p>
          </div>
          <div className={classes.recap}>
            <p>
              Nombre de peaux : <br />
              <strong>{skins.length}</strong>
              <br />
              Nombre de peaux C2: <br />
              <strong>{countC2}</strong>
            </p>
            <p>
              Température moyenne (en °C) : <br />
              <strong>
                {(
                  skins
                    .map((p) => p.tray?.temperature)
                    .filter((t): t is number => t !== undefined || t !== null)
                    .reduce((acc, t) => acc + t, 0) / skins.length
                ).toFixed(2)}
              </strong>
            </p>
            <p>
              % peaux dégraissées : <br />
              <strong>
                {((skins.map((p) => p.degrease).filter((b) => b).length / skins.length) * 100).toFixed(2)}%
              </strong>
              <br />
              % peaux écartées : <br />
              <strong>{((skins.map((p) => p.gap).filter((b) => b).length / skins.length) * 100).toFixed(2)}%</strong>
            </p>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.column}>
            <p>Trous : {trousTotal}</p>
            <p>Peaux trouées : {((trousPeau / skins.length) * 100).toFixed(2)}%</p>
            <p>Peaux avec croupon trouées: {((trousCrouponPeau / skins.length) * 100).toFixed(2)}%</p>
            <div className={`${classes.skin} ${classes.trous}`}>
              <div>{trousshoulder_left}</div>
              <div>{trousneck}</div>
              <div>{trousshoulder_right}</div>
              <div>{trousleg_left}</div>
              <div>{trousback}</div>
              <div>{trousleg_right}</div>
            </div>
          </div>
          <div className={classes.column}>
            <p>Déchirures : {dechirureTotal}</p>
            <p>Peaux déchirées : {((dechirurePeau / skins.length) * 100).toFixed(2)}%</p>
            <p>Peaux avec croupon déchirées: {((dechirureCrouponPeau / skins.length) * 100).toFixed(2)}%</p>
            <div className={`${classes.skin} ${classes.dechirures}`}>
              <div>{dechirureshoulder_left}</div>
              <div>{dechirureneck}</div>
              <div>{dechirureshoulder_right}</div>
              <div>{dechirureleg_left}</div>
              <div>{dechirureback}</div>
              <div>{dechirureleg_right}</div>
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.column}>
            <p>Coutelures : {coutelureTotal}</p>
            <p>Peaux coutelées : {((coutelurePeau / skins.length) * 100).toFixed(2)}%</p>
            <p>Peaux avec croupon coutelées: {((coutelureCrouponPeau / skins.length) * 100).toFixed(2)}%</p>
            <div className={`${classes.skin} ${classes.coutelures}`}>
              <div>{coutelureshoulder_left}</div>
              <div>{coutelureneck}</div>
              <div>{coutelureshoulder_right}</div>
              <div>{coutelureleg_left}</div>
              <div>{coutelureback}</div>
              <div>{coutelureleg_right}</div>
            </div>
          </div>
          <div className={classes.column}>
            <p>Échancrures : {echancrureTotal}</p>
            <p>Peaux echancrées : {((echancrurePeau / skins.length) * 100).toFixed(2)}%</p>
            <p>Peaux avec croupon echancrées: {((echancrureCrouponPeau / skins.length) * 100).toFixed(2)}%</p>
            <div className={`${classes.skin} ${classes.echancrures}`}>
              <div>{echancrureshoulder_left}</div>
              <div>{echancrureneck}</div>
              <div>{echancrureshoulder_right}</div>
              <div>{echancrureleg_left}</div>
              <div>{echancrureback}</div>
              <div>{echancrureleg_right}</div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

const useStyles = makeStyles({
  page: {
    padding: 24,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    pageBreakAfter: 'always',
  },
  heading: {
    display: 'flex',
    flexDirection: 'column',
    border: '0.5px solid #000',
    padding: 20,
    marginBottom: '70px',
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
  },
  address: {
    '& > p': {
      margin: 0,
    },
  },
  recap: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexFlow: 'wrap',
    '& p, & div': {
      flex: 1,
      margin: 0,
      height: 'fit-content',
      fontSize: 9,
    },
    '& strong': {
      fontSize: 16,
    },
    '& img': {
      maxWidth: 120,
    },
    '& div': {
      textAlign: 'right',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    height: 325,
  },
  column: {
    width: '50%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 'bold',
    '& p': {
      margin: 0,
    },
  },
  skin: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    justifyItems: 'center',
    alignItems: 'center',
    height: '100%',
    '& > div': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      fontSize: 30,
    },
  },
  dechirures: {
    backgroundImage: `url(${cuirsdechirures})`,
  },
  echancrures: {
    backgroundImage: `url(${cuirsechancrures})`,
  },
  coutelures: {
    backgroundImage: `url(${cuirsCoutelures})`,
  },
  trous: {
    backgroundImage: `url(${cuirsTrous})`,
  },
  root: {
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
  },
});
