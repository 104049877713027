import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import {RootState, useAppThunkDispatch} from 'src/store';
import {onUserPasswordUpdate} from 'src/store/userSlice';
import {User} from 'src/models/User';

type UpdateUserPasswordModalProps = {
  user: User;
  handleClose: () => void;
};

export default function UpdateUserPasswordModal(props: UpdateUserPasswordModalProps): JSX.Element {
  const {user, handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const onSubmit = () => {
    let hasError = false;

    if (password.length == 0 || passwordConfirm.length == 0) {
      setPasswordError('Le mot de passe est obligatoire !');
      hasError = true;
    }

    if (password != passwordConfirm) {
      setPasswordError('Ces mots de passe ne correspondent pas. Veuillez réessayer !');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (user.id != undefined) {
      const userID = user.id;
      dispatch(onUserPasswordUpdate({userID, password}))
        .unwrap()
        .then(() => handleClose());
    }
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Modifier le mot de passe de l&apos;utilisateur</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item={true} xs={12} p={0}>
            <TextField
              margin="dense"
              fullWidth
              value={password}
              label="Mot de passe"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError.length != 0}
              helperText={passwordError}
            />
          </Grid>
          <Grid item={true} xs={12} p={0}>
            <TextField
              margin="dense"
              fullWidth
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              label="Confirmer le mot de passe"
              type="password"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Modifier
        </Button>
      </DialogActions>
    </Dialog>
  );
}
