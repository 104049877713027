import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Logo from 'src/assets/images/Logo-Tourain-Cuirs.png';
import Container from '@mui/material/Container';
import {useAppThunkDispatch} from 'src/store';
import {fetchLogin} from 'src/store/userSlice';
import {useNavigate} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {createTheme, ThemeProvider} from '@mui/material/styles';

const theme = createTheme();

export default function Login() {
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    if (email != '' && password != '') {
      const identifications = {
        email: email,
        password: password,
      };

      dispatch(fetchLogin(identifications)).then(() => navigate('/gestion-des-lots/'));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 25,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <img className={classes.img} src={Logo}></img>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 3}}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Identifiant"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              VALIDER
            </Button>
          </Box>
          <Button className={classes.btn} fullWidth variant="contained" onClick={() => navigate('/')}>
            Sortir
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

const useStyles = makeStyles({
  img: {
    width: '50%',
  },
  btn: {
    marginTop: '70%',
    position: 'inherit',
  },
});
