import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {useSelector} from 'react-redux';
import {fetchTrays} from 'src/store/traySlice';
import {Box, Tab, Button} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {RootState, useAppThunkDispatch} from 'src/store';
import TrayTab from 'src/components/Desalting_manager/tabs/TrayTab';
import PalletTab from 'src/components/Lot_manager/tabs/PalletTab';
import PalletInProgressTab from 'src/components/Lot_manager/tabs/PalletInProgressTab';
import Paper from '@mui/material/Paper';
import {CircularProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';
import ModalDetail from 'src/components/Desalting_manager/modals/ModalDetail';

export default function Pallet() {
  const [selectedTab, setSelectedTab] = useState<'1' | '2'>('1');
  const [formSubmit, setFormSubmit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const classes = useStyles();
  const trayTreaty = useSelector((state: RootState) => state?.tray?.trays);
  const dispatch = useAppThunkDispatch();

  const fetchData = async () => {
    await dispatch(fetchTrays()).then(() => setFormSubmit(true));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const cancel = () => {
    setModalOpen(false);
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Container className={classes.root}>
          <div className={classes.page}>
            <TabContext value={selectedTab}>
              <Box sx={{borderBottom: 1, borderColor: 'divider', display: 'flex'}}>
                <TabList
                  sx={{flexGrow: 1}}
                  onChange={(_, value) => setSelectedTab(value)}
                  aria-label="lab API tabs example">
                  <Tab label="PLATEAUX EN COURS" value="1" />
                  <Tab label="PALETTES EN COURS" value="2" />
                  <Tab label="PALETTES TERMINÉES" value="3" />
                </TabList>
              </Box>
              {!formSubmit ? (
                <div className={classes.tabCircular}>
                  <CircularProgress />
                </div>
              ) : (
                <div className={classes.tabContainer}>
                  <TabPanel value="1" className={classes.tabPanel}>
                    <TrayTab trays={trayTreaty} />
                  </TabPanel>
                  <TabPanel value="2" className={classes.tabPanel}>
                    <PalletInProgressTab setFormSubmit={setFormSubmit} />
                  </TabPanel>
                  <TabPanel value="3" className={classes.tabPanel}>
                    <PalletTab setFormSubmit={setFormSubmit} />
                  </TabPanel>
                </div>
              )}
            </TabContext>
          </div>
          {modalOpen && <ModalDetail handleClose={cancel} />}
        </Container>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  page: {
    height: `calc(100% - 64px)`,
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tabCircular: {
    height: '50vh',
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabPanel: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    height: '100%',
  },
  tabContainer: {
    height: '100vh',
  },
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
  },
  btn: {
    margin: 5,
  },
}));
