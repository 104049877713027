import React from 'react';
import clsx from 'clsx';
import {styled} from '@mui/system';
import {useSwitch, UseSwitchProps} from '@mui/base/SwitchUnstyled';

const SwitchRoot = styled('span')`
  display: inline-block;
  position: relative;
  width: 140px;
  height: 36px;
`;

const SwitchInput = styled('input')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;

const SwitchThumb = styled('span')`
  position: absolute;
  display: block;
  background-color: #21d59b;
  width: 94px;
  height: 36px;
  border-radius: 4px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);

  &::before {
    display: block;
    content: 'DÉGRAISSÉ';
    color: #fff;
    width: 100%;
    margin-top: 5px;
    text-align: center;
  }

  &.focusVisible {
    background-color: #79b;
  }

  &.checked {
    transform: translateX(45px);
    background-color: #f0142f;

    &::before {
      content: 'GRAS';
    }
  }
`;

const SwitchTrack = styled('span')(
  () => `
  background-color: #D7DBEC;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: block;
`,
);

export default function CustomSwitch(props: UseSwitchProps): JSX.Element {
  const {getInputProps, checked, disabled, focusVisible} = useSwitch(props);

  const stateClasses = {
    checked,
    disabled,
    focusVisible,
  };

  return (
    <SwitchRoot className={clsx(stateClasses)}>
      <SwitchTrack>
        <SwitchThumb className={clsx(stateClasses)} />
      </SwitchTrack>
      <SwitchInput {...getInputProps()} aria-label="Demo switch" />
    </SwitchRoot>
  );
}
