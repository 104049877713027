import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import NumberTextField from 'src/components/Lot_manager/numbertextfield/NumberTextField';
import FinishedPalletModal from 'src/components/Desalting_manager/modals/FinishedPalletModal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import {RootState, useAppThunkDispatch} from 'src/store';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import CreatePalletModal from 'src/components/Desalting_manager/modals/CreatePalletModal';
import {
  deletePallet,
  storePallet,
  updatePallet,
  storePalletPosition,
  saveAllPalletPositions,
} from 'src/store/palletSlice';
import {Pallet} from 'src/models/Pallet';

const CANVAS_WIDTH = window.innerWidth > 1000 ? window.innerWidth - 100 : window.innerWidth - 100;
const CANVAS_HEIGHT = window.innerHeight - 200;
const CIRCLE_RADIUS = window.innerWidth > 1200 ? 50 : 30;
const CIRCLE_NAME = window.innerWidth > 1200 ? 120 : 80;
const CIRCLE_NAME_X = 0;
const NAME_SIZE = window.innerWidth > 1200 ? '16px' : '12px';
const NAME_CATEGORY = window.innerWidth > 1200 ? '18px' : '12px';
const SKIN_SIZE = window.innerWidth > 1200 ? '32px' : '24px';
const SKIN_MARGIN_Y = window.innerWidth > 1200 ? 60 : 40;
const SKIN_MARGIN_X = window.innerWidth > 1200 ? 32 : 24;
const SKIN_MARGIN_X_SMALL = window.innerWidth > 1200 ? 40 : 22;
const windowSize = window.innerWidth > 822 ? 1 : 2;

interface PalletModel {
  id?: string;
  index: number;
  category: string;
  name: string;
  capacity: number;
  position_x: number;
  position_y: number;
  update: any;
  deleteThisPallet: any;
  savePalletPosition: any;
}

const DraggableCircle: React.FC<PalletModel> = ({
  id,
  index,
  name,
  category,
  capacity,
  position_x,
  position_y,
  update,
  deleteThisPallet,
  savePalletPosition,
}) => {
  const categoriesState = useSelector((state: RootState) => state.category.categories);
  const [isDown, setIsDown] = useState(false);
  const [posX, setPosX] = useState(position_x / windowSize);
  const [posY, setPosY] = useState(position_y / windowSize);
  const [screenX, setScreenX] = useState(0);
  const [screenY, setScreenY] = useState(0);
  const [categoryName, setCategoryName] = useState(category);
  const [pallet, setPallet] = useState(name);
  const [palletCapacity, setPalletCapacity] = useState<number>(capacity);
  const [capacityTextMargin, setCapacityTextMargin] = useState(
    palletCapacity < 10 ? SKIN_MARGIN_X_SMALL : SKIN_MARGIN_X,
  );
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedPalletName, setEditedPalletName] = useState('');

  const addCapacity = (e: number | undefined) => {
    setPalletCapacity(e ?? 0);
    if (e) {
      setCapacityTextMargin(e < 10 ? SKIN_MARGIN_X_SMALL : SKIN_MARGIN_X);
    }
  };

  const onMouseDown = (e: React.MouseEvent<SVGRectElement> | React.TouchEvent<SVGRectElement>) => {
    e.preventDefault();
    if ('touches' in e) {
      setIsDown(true);
      setScreenX(e.touches[0].screenX);
      setScreenY(e.touches[0].screenY);
    } else {
      setIsDown(true);
      setScreenX(e.screenX);
      setScreenY(e.screenY);
    }
  };

  const onMouseMove = (e: React.MouseEvent<SVGRectElement> | React.TouchEvent<SVGRectElement>) => {
    e.preventDefault();
    if (!isDown) {
      return;
    }
    const shiftX = 'touches' in e ? e.touches[0].screenX - screenX : e.screenX - screenX;
    const shiftY = 'touches' in e ? e.touches[0].screenY - screenY : e.screenY - screenY;
    const updatedPosX = posX + shiftX;
    const updatedPosY = posY + shiftY;

    const maxX = CANVAS_WIDTH - CIRCLE_RADIUS;
    const maxY = CANVAS_HEIGHT - CIRCLE_RADIUS;
    const minX = CIRCLE_RADIUS;
    const minY = CIRCLE_RADIUS;

    const adjustedPosX = Math.max(Math.min(updatedPosX, maxX), minX);
    const adjustedPosY = Math.max(Math.min(updatedPosY, maxY), minY);

    setPosX(adjustedPosX);
    setPosY(adjustedPosY);
    setScreenX('touches' in e ? e.touches[0].screenX : e.screenX);
    setScreenY('touches' in e ? e.touches[0].screenY : e.screenY);
  };

  const onMouseUp = () => {
    savePalletPosition(index, posX, posY);
    setIsDown(false);
    setScreenX(0);
    setScreenY(0);
  };

  const openEditModal = (name: string) => {
    setEditedPalletName(name);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    update(id, categoryName, editedPalletName, palletCapacity, posX, posY);
    setPallet(editedPalletName);
    setEditedPalletName('');
    setEditModalOpen(false);
  };

  const handleDelete = () => {
    deleteThisPallet(id);
    setEditModalOpen(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedPalletName(e.target.value);
  };

  const close = () => {
    setEditModalOpen(false);
  };

  return (
    <>
      <text
        x={posX - CIRCLE_RADIUS + capacityTextMargin}
        y={posY - CIRCLE_RADIUS + SKIN_MARGIN_Y}
        fill="#0058FF"
        style={{fontSize: SKIN_SIZE, cursor: 'pointer', fontWeight: '700'}}>
        {palletCapacity}
      </text>
      <rect
        x={posX - CIRCLE_RADIUS}
        y={posY - CIRCLE_RADIUS}
        width={CIRCLE_RADIUS * 2}
        height={CIRCLE_RADIUS * 2}
        stroke="#0058FF"
        fill="transparent"
        strokeWidth="3"
        className="block"
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onTouchStart={onMouseDown}
        onTouchMove={onMouseMove}
        onTouchEnd={onMouseUp}
        style={{fontSize: '28px', cursor: 'move'}}
      />
      <text
        x={posX - CIRCLE_RADIUS + 0}
        y={posY - CIRCLE_RADIUS - 10}
        fill="#0058FF"
        style={{fontSize: NAME_CATEGORY, cursor: 'pointer'}}
        onClick={() => openEditModal(pallet)}>
        {categoryName}
      </text>
      <text
        x={posX - CIRCLE_RADIUS + CIRCLE_NAME_X}
        y={posY - CIRCLE_RADIUS + CIRCLE_NAME}
        fill="#0058FF"
        style={{fontSize: NAME_SIZE, cursor: 'pointer'}}
        onClick={() => openEditModal(pallet)}>
        {pallet}
      </text>
      <Dialog open={editModalOpen} fullWidth maxWidth={'lg'}>
        <DialogTitle>Modifier la palette</DialogTitle>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item={true} xs={12} p={2}>
              <TextField
                margin="dense"
                label="N° Palette :"
                type="text"
                style={{marginTop: 10}}
                fullWidth
                variant="standard"
                value={editedPalletName}
                onChange={onChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <NumberTextField
                margin="dense"
                label="Capacité :"
                placeholder="Capacité maximal de peaux"
                type="number"
                fullWidth
                variant="standard"
                value={palletCapacity}
                onNumberChange={(e) => addCapacity(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item={true} xs={12} p={2}>
              <TextField
                id="outlined-select-currency"
                fullWidth
                select
                value={categoryName}
                label="Categories :"
                onChange={(e) => setCategoryName(e.target.value)}>
                {categoriesState.map((option) => (
                  <MenuItem key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid item={true} container spacing={0}>
            <Grid item={true} xs={4} spacing={2}>
              <Button variant="contained" onClick={close}>
                Annuler
              </Button>
            </Grid>
            <Grid item={true} xs={8} container justifyContent="flex-end">
              <Button variant="contained" color="error" onClick={handleDelete} style={{marginRight: 10}}>
                Supprimer
              </Button>
              <Button variant="contained" onClick={handleEditModalClose}>
                Sauvegarder
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default function EditPlan() {
  const classes = useStyles();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [error, setError] = useState('');
  const [finishedPalletModalOpen, setFinishedPalletModalOpen] = useState(false);
  const tray = useSelector((state: RootState) => state.tray.currentTray);
  const palletsList = useSelector((state: RootState) => state.pallet.pallets);
  const currentSkin = useSelector((state: RootState) => state.pallet.currentSkin);
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();

  const addPallet = () => {
    setCreateModalOpen(true);
  };

  const savePalletPosition = (index: number, position_x: number, position_y: number) => {
    dispatch(storePalletPosition({index, position_x, position_y}));
  };

  const backToTray = (trayID: string | undefined, number: number | string) => {
    if (trayID) {
      dispatch(saveAllPalletPositions(palletsList));
      navigate(`/dessalage/plateau/${trayID}/peaux/${number}`);
    }
  };

  const backToHome = () => {
    dispatch(saveAllPalletPositions(palletsList));
    navigate(`/dessalage`);
  };

  const deletePalletHandle = (palletID: string) => {
    dispatch(deletePallet(palletID));
  };

  const addNewPallet = (category: string, name: string, capacity: number, position_x: number, position_y: number) => {
    if (tray) {
      const newPallet: Pallet = {
        name: name,
        capacity: capacity,
        position_x: position_x,
        position_y: position_y,
        category: category,
        supplier_id: tray.supplier_id != undefined ? tray.supplier_id : '',
        skinsAmount: 0,
        skins: [],
      };
      dispatch(storePallet(newPallet));
    }
  };

  const updatePalletHandle = (
    id: string,
    category: string,
    name: string,
    capacity: number,
    position_x: number,
    position_y: number,
  ) => {
    if (tray) {
      const pallet: Pallet = {
        id: id,
        name: name,
        capacity: capacity,
        position_x: position_x,
        position_y: position_y,
        category: category,
        supplier_id: tray.supplier_id != undefined ? tray.supplier_id : '',
        skinsAmount: 0,
        skins: [],
      };
      dispatch(updatePallet(pallet));
    }
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid item={true} container spacing={0}>
        <Grid item={true} xs={8}>
          {currentSkin && tray ? (
            <Button variant="text" className={classes.btn} onClick={() => backToTray(tray.id, currentSkin.number)}>
              &lt; Retour
            </Button>
          ) : (
            <Button variant="text" className={classes.btn} onClick={() => backToHome()}>
              &lt; Retour
            </Button>
          )}
        </Grid>
        {/* {finishedPalletModalOpen && (
          <FinishedPalletModal pallets={palletsList} handleClose={() => setFinishedPalletModalOpen(false)} />
        )} */}
        <Grid item={true} xs={4} container justifyContent="flex-end">
          {/* <Button variant="contained" className={classes.btn} onClick={() => setFinishedPalletModalOpen(true)}>
            FIN DE PALETTE
          </Button> */}
          <Button variant="contained" className={classes.btn} onClick={addPallet}>
            Ajouter une palette
          </Button>
        </Grid>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '94%',
        }}>
        <Container className={classes.root}>
          {createModalOpen && (
            <CreatePalletModal addNewPallet={addNewPallet} handleClose={() => setCreateModalOpen(false)} />
          )}
          <div className={classes.page}>
            <Grid item={true} xs={12} container justifyContent="center">
              <div>
                <svg
                  style={{
                    width: CANVAS_WIDTH + 'px',
                    height: CANVAS_HEIGHT + 'px',
                    border: '1px solid silver',
                    overflow: 'hidden',
                  }}>
                  {palletsList.map((pallet, index) =>
                    pallet.supplier_id === tray?.supplier_id ? (
                      <DraggableCircle
                        key={pallet.id}
                        id={pallet.id}
                        index={index}
                        name={pallet.name}
                        capacity={pallet.capacity}
                        category={pallet.category}
                        position_x={
                          typeof pallet.position_x == 'number' ? pallet.position_x : parseInt(pallet.position_x)
                        }
                        position_y={
                          typeof pallet.position_y == 'number' ? pallet.position_y : parseInt(pallet.position_y)
                        }
                        update={updatePalletHandle}
                        deleteThisPallet={deletePalletHandle}
                        savePalletPosition={savePalletPosition}
                      />
                    ) : null,
                  )}
                </svg>
              </div>
            </Grid>
          </div>
        </Container>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100%',
  },
  page: {
    height: `100%`,
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    margin: 10,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
