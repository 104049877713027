import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Skin} from 'src/models/Skin';

type ModalC2Props = {
  c2: Skin[];
  handleClose: () => void;
  handleConfirm: () => void;
};
export default function ModalC2(props: ModalC2Props): JSX.Element {
  const {c2, handleClose, handleConfirm} = props;
  return (
    <Dialog open>
      <DialogTitle>ATTENTION C2 MANQUANTE !</DialogTitle>
      <DialogContent>
        {c2.length === 1
          ? `LA PEAU N°${c2[0].number} N’A PAS ÉTÉ MARQUÉ COMME RENVOYER
          CONFIRMER TOUT DE MÊME ?`
          : `LES PEAUX N° ${c2
              .filter((c) => c.returned !== true)
              .map((c) => c.number)
              .join(', ')} N’ONT PAS ÉTÉ MARQUÉS COMME ISOLÉ
          CONFIRMER TOUT DE MÊME ?`}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={handleConfirm}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
