import React, {useState} from 'react';
import {Tray} from 'src/models/Tray';

import {DataGrid, GridColDef, GridValueGetterParams, GridCellParams, GridSortModel} from '@mui/x-data-grid';
import {parseISO, format} from 'date-fns';

const columns: GridColDef[] = [
  {
    field: 'name',
    cellClassName: 'pointer',
    headerName: 'PLATEAU',
    flex: 1,
    renderCell: (params) => {
      return <a>{params.row.name}</a>;
    },
  },
  {
    field: 'last_skin',
    cellClassName: 'pointer',
    headerName: 'N°PEAU',
    flex: 1,
    renderCell: (params) => {
      return <a>{params.row.last_skin}</a>;
    },
  },
  {
    field: 'bac',
    cellClassName: 'pointer',
    headerName: 'N°BAC',
    flex: 1,
    renderCell: (params) => {
      return <a>{params.row.bac ?? 'non défini'}</a>;
    },
  },
  {
    field: 'supplier',
    headerName: 'FOURNISSEUR',
    flex: 1,
    renderCell: (params) => {
      return params.row.supplierName ?? '';
    },
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.supplierName ?? '';
    },
  },
  {
    field: 'skinsAmount',
    headerName: 'NOMBRE DE PEAUX',
    flex: 1,
    renderCell: (params) => {
      return params.row.skinsFinished + '/' + params.row.skinsAmount ?? '';
    },
  },
  {
    field: 'reception_date',
    headerName: 'DATE D’ENTREE',
    type: 'date',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.reception_date ? new Date(params.row.reception_date) : '';
    },
  },
  {
    field: 'slaughter_date',
    headerName: 'DATE ABATTAGE',
    type: 'date',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.slaughter_date ? new Date(params.row.slaughter_date) : '';
    },
  },
];

type LotTabProps = {
  trays: Tray[];
  onClick?: (tray: Tray) => void;
  deleteModal?: (tray: Tray) => void;
  trayReturned?: (tray: Tray) => void;
};

export default function TrayTab(props: LotTabProps): JSX.Element {
  const {trays, onClick, deleteModal, trayReturned} = props;

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'slaughter_date',
      sort: 'desc',
    },
  ]);

  const filteredTrays = trays.filter((tray) => tray.show);

  const onCellClick = (params: GridCellParams) => {
    const tray = trays.find((l) => l.id === params.id);
    if (tray != undefined && trayReturned && tray.c2 == true) {
      trayReturned(tray);
    }
    if (tray !== undefined && onClick && tray.skinsAmount != 0 && tray.c2 == false) {
      onClick(tray);
    }
    if (tray != undefined && deleteModal && tray.skinsAmount == 0) {
      deleteModal(tray);
    }
  };

  return (
    <DataGrid
      rows={filteredTrays}
      columns={columns}
      getRowId={(row) => row.id}
      pageSize={20}
      rowsPerPageOptions={[20]}
      onCellClick={onCellClick}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
    />
  );
}
