import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import SupplierTab from 'src/components/Lot_manager/tabs/SupplierTab';
import Paper from '@mui/material/Paper';
import {useSelector} from 'react-redux';
import CreateSupplierModal from 'src/components/Lot_manager/modals/CreateSupplierModal';
import {createSupplier, fetchSuppliers} from 'src/store/supplierSlice';
import {RootState, useAppThunkDispatch} from 'src/store';
import {setSupplier} from 'src/store/supplierSlice';
import {Contact} from 'src/models/Contact';
import {Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {CircularProgress} from '@mui/material';
import {useNavigate} from 'react-router-dom';

export default function Supplier() {
  const [modalAddClientOpen, setModalAddClientOpen] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const suppliers = useSelector((state: RootState) => state.supplier?.suppliers);
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();

  const fetchData = async () => {
    await dispatch(fetchSuppliers()).then(() => setFormSubmit(true));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSupplierCreate = (name: string, prefix: string, contact: Contact) => {
    dispatch(
      createSupplier({
        name,
        prefix,
        contact,
      }),
    )
      .unwrap()
      .then(() => setModalAddClientOpen(false));
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Grid container spacing={0} mt={2} mb={2}>
          <Grid item container justifyContent="flex-end">
            <Button variant="contained" className={classes.btn} onClick={() => setModalAddClientOpen(true)}>
              Ajouter un fournisseur
            </Button>
            {modalAddClientOpen && (
              <CreateSupplierModal handleSubmit={onSupplierCreate} handleClose={() => setModalAddClientOpen(false)} />
            )}
          </Grid>
        </Grid>
        {!formSubmit ? (
          <div className={classes.tabCircular}>
            <CircularProgress />
          </div>
        ) : (
          <SupplierTab
            suppliers={suppliers}
            onClick={async (supplier) => {
              dispatch(setSupplier(supplier));
              navigate(`/gestion-des-lots/fournisseurs/${supplier.id}/detail`);
            }}
          />
        )}
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 5,
  },
  icon: {
    marginLeft: 5,
    marginRight: 5,
  },
  tabCircular: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
}));
