import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import {makeStyles} from '@mui/styles';
import {Chip, CircularProgress} from '@mui/material';
import {parse, isPast} from 'date-fns';
import NumberTextField from '../numbertextfield/NumberTextField';
import {RootState, useAppThunkDispatch} from 'src/store';
import {fetchSalageSuppliers} from 'src/store/supplierSlice';
import {useSelector} from 'react-redux';

type ModalCreateLotProps = {
  handleClose: () => void;
  handleSubmit: (
    lot: string,
    dateAbattage: string,
    firstPeau: number,
    lastPeau: number,
    c3List: number[],
    c2List: number[],
    supplierName?: string,
    dateReceipt?: string,
  ) => void;
};

export default function ModalCreateLot(props: ModalCreateLotProps): JSX.Element {
  const {handleClose, handleSubmit} = props;
  const classes = useStyles();
  const c3inputRef = useRef<HTMLInputElement>();
  const c2inputRef = useRef<HTMLInputElement>();
  const [lot, setLot] = useState('');
  const dispatch = useAppThunkDispatch();
  const [supplierName, setSupplierName] = useState<string>();
  const [dateAbattage, setDateAbattage] = useState('');
  const [firstPeau, setFirstPeau] = useState<number | undefined>();
  const [lastPeau, setLastPeau] = useState<number | undefined>();
  const [c3input, setC3input] = useState<number | undefined>();
  const [c3list, setC3list] = useState<number[]>([]);
  const [c2input, setC2input] = useState<number | undefined>();
  const [c2list, setC2list] = useState<number[]>([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [dateslaughter_dateValid, setDateslaughter_dateValid] = useState(true);
  const [dateReceipt_dateValid, setDateReceipt_dateValid] = useState(true);
  const [dateReceipt, setDateReceipt] = useState('');
  const dateslaughter_date = parse(dateAbattage, 'yyyy-MM-dd', new Date());
  const suppliersState = useSelector((state: RootState) => state.supplier.suppliers);
  const datesReceipt_date = parse(dateReceipt, 'yyyy-MM-dd', new Date());

  useEffect(() => {
    dispatch(fetchSalageSuppliers());
    if (dateAbattage === '') {
      setDateslaughter_dateValid(true);
      return;
    }
    setDateslaughter_dateValid(isPast(dateslaughter_date));
    setDateReceipt_dateValid(isPast(datesReceipt_date));
  }, [dateAbattage]);

  const formValid =
    formSubmit !== true &&
    lot !== '' &&
    supplierName !== undefined &&
    dateAbattage !== '' &&
    isPast(dateslaughter_date) &&
    firstPeau != undefined &&
    lastPeau != undefined &&
    firstPeau <= lastPeau;

  const addC3 = () => {
    if (c3input !== undefined) {
      setC3list([...c3list, c3input]);
      setC3input(undefined);
      c3inputRef.current?.focus();
    }
  };
  const addC2 = () => {
    if (c2input !== undefined) {
      setC2list([...c2list, c2input]);
      setC2input(undefined);
      c2inputRef.current?.focus();
    }
  };

  const creer = () => {
    if (formValid) {
      setFormSubmit(true);
      handleSubmit(lot, dateAbattage, firstPeau, lastPeau, c3list, c2list, supplierName, dateReceipt);
    }
  };

  return (
    <Dialog open>
      <DialogTitle>Ajouter un nouveau lot</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Lot"
          type="text"
          className={classes.input}
          fullWidth
          variant="standard"
          value={lot}
          onChange={(e) => setLot(e.target.value.toUpperCase())}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id="outlined-select-currency-supplier"
          select
          fullWidth
          className={classes.input}
          value={supplierName}
          onChange={(e) => setSupplierName(e.target.value)}
          label="Fournisseur :"
          defaultValue="1">
          {suppliersState.map((option) => (
            <MenuItem key={option.id} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          margin="dense"
          label="Date de reception :"
          type="date"
          fullWidth
          variant="standard"
          value={dateReceipt}
          onChange={(e) => setDateReceipt(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={dateReceipt_dateValid === false}
          helperText={dateReceipt_dateValid === false ? 'La date est dans le future' : undefined}
        />
        <TextField
          margin="dense"
          label="Date d'abattage"
          className={classes.input}
          type="date"
          fullWidth
          variant="standard"
          value={dateAbattage}
          onChange={(e) => setDateAbattage(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={dateslaughter_dateValid === false}
          helperText={dateslaughter_dateValid === false ? 'La date est dans le future' : undefined}
        />
        <NumberTextField
          margin="dense"
          label="Première peau"
          placeholder="Numéro de la première peau"
          className={classes.input}
          type="number"
          variant="standard"
          sx={{width: '49%'}}
          value={firstPeau}
          onNumberChange={(e) => setFirstPeau(e)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <NumberTextField
          margin="dense"
          label="Dernière peau"
          placeholder="Numéro de la dernière peau"
          className={classes.input}
          type="number"
          variant="standard"
          sx={{width: '49%', marginLeft: '2%'}}
          value={lastPeau}
          onNumberChange={(e) => setLastPeau(e)}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {c3list.map((c3) => (
          <Chip key={c3} label={c3} onDelete={() => setC3list(c3list.filter((c) => c !== c3))} />
        ))}
        <NumberTextField
          inputRef={c3inputRef}
          margin="dense"
          label="C3"
          placeholder="Numéro de la peau"
          type="number"
          fullWidth
          variant="standard"
          value={c3input}
          onNumberChange={(e) => setC3input(e)}
          InputProps={{
            endAdornment: (
              <Button variant="contained" className={classes.btn} onClick={addC3}>
                AJOUTER
              </Button>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <NumberTextField
          inputRef={c2inputRef}
          margin="dense"
          label="C2"
          placeholder="Numéro de la peau"
          type="number"
          fullWidth
          variant="standard"
          value={c2input}
          onNumberChange={(e) => setC2input(e)}
          InputProps={{
            endAdornment: (
              <Button variant="contained" className={classes.btn} onClick={addC2}>
                AJOUTER
              </Button>
            ),
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {c2list.map((c2) => (
          <Chip key={c2} label={c2} onDelete={() => setC2list(c2list.filter((c) => c !== c2))} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={creer} disabled={formValid === false}>
          {formSubmit ? <CircularProgress /> : 'Créer'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 10,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
