import {Action, configureStore, ThunkDispatch} from '@reduxjs/toolkit';
import {combineReducers} from 'redux';
import {useDispatch} from 'react-redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import userReducer from './userSlice';
import lotReducer from './lotSlice';
import categoryReducer from './categorySlice';
import contactTypeReducer from './contactTypeSlice';
import supplierReducer from './supplierSlice';
import trayReducer from './traySlice';
import palletReducer from './palletSlice';
import IpAddressReducer from './ipAddressSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const reducer = combineReducers({
  user: userReducer,
  lot: lotReducer,
  IpAddress: IpAddressReducer,
  category: categoryReducer,
  contactType: contactTypeReducer,
  supplier: supplierReducer,
  tray: trayReducer,
  pallet: palletReducer,
});

const persisted = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persisted,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof persisted>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
export const useAppThunkDispatch = () => useDispatch<ThunkAppDispatch>();
