import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import CategorieTab from 'src/components/Lot_manager/tabs/CategoryTab';
import ContactTypeTab from 'src/components/Lot_manager/tabs/ContactTypeTab';
import UserTab from 'src/components/Lot_manager/tabs/UserTab';
import IpAddressTab from 'src/components/Lot_manager/tabs/IpAddressTab';
import Paper from '@mui/material/Paper';
import {Box, Tab} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {makeStyles} from '@mui/styles';

export default function Parameters() {
  const [selectedTab, setSelectedTab] = useState<'1' | '2' | '3' | '4'>('1');
  const classes = useStyles();

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Container className={classes.root}>
          <div className={classes.page}>
            <TabContext value={selectedTab}>
              <Box sx={{borderBottom: 1, borderColor: 'divider', display: 'flex'}}>
                <TabList
                  sx={{flexGrow: 1}}
                  onChange={(_, value) => setSelectedTab(value)}
                  aria-label="lab API tabs example">
                  <Tab label="Categories" value="1" />
                  <Tab label="Types de contact" value="2" />
                  <Tab label="Utilisateurs" value="3" />
                  <Tab label="IP Adresses" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1" className={classes.tabPanel}>
                <div className={classes.tabContainer}>
                  <CategorieTab />
                </div>
              </TabPanel>
              <TabPanel value="2" className={classes.tabPanel}>
                <div className={classes.tabContainer}>
                  <ContactTypeTab />
                </div>
              </TabPanel>
              <TabPanel value="3" className={classes.tabPanel}>
                <div className={classes.tabContainer}>
                  <UserTab />
                </div>
              </TabPanel>
              <TabPanel value="4" className={classes.tabPanel}>
                <div className={classes.tabContainer}>
                  <IpAddressTab />
                </div>
              </TabPanel>
            </TabContext>
          </div>
        </Container>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  page: {
    height: `calc(100% - 64px)`,
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tabPanel: {
    flexGrow: 1,
    padding: 0,
  },
  tabContainer: {
    height: '100%',
  },
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
  },
  btn: {
    margin: 5,
  },
}));
