import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {useNavigate, useParams} from 'react-router-dom';
import FinishedPalletModal from 'src/components/Desalting_manager/modals/FinishedPalletModal';
import DetailSkinModal from 'src/components/Desalting_manager/modals/DetailSkinModal';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import {RootState, useAppThunkDispatch} from 'src/store';
import {useSelector} from 'react-redux';
import {Skin} from 'src/models/Skin';
import {
  addSkinToPallet,
  deleteSkinInPallet,
  setCurrentSkin,
  setSkinToPallet,
  removeSkinToPallet,
} from 'src/store/palletSlice';

const CANVAS_WIDTH = window.innerWidth > 1000 ? window.innerWidth - 100 : window.innerWidth - 100;
const CANVAS_HEIGHT = window.innerHeight - 200;
const CIRCLE_RADIUS = window.innerWidth > 1200 ? 50 : 30;
const CIRCLE_NAME = window.innerWidth > 1200 ? 120 : 80;
const CIRCLE_NAME_X = 0;
const NAME_SIZE = window.innerWidth > 1200 ? '16px' : '12px';
const NAME_CATEGORY = window.innerWidth > 1200 ? '18px' : '12px';
const SKIN_SIZE = window.innerWidth > 1200 ? '32px' : '24px';
const SKIN_MARGIN_Y = window.innerWidth > 1200 ? 60 : 40;
const SKIN_MARGIN_X = window.innerWidth > 1200 ? 32 : 16;
const SKIN_MARGIN_X_SMALL = window.innerWidth > 1200 ? 40 : 20;
const windowSize = window.innerWidth > 822 ? 1 : 2;

interface Pallet {
  ifSkinAdded: boolean;
  addSkin: any;
  pallet: any;
  currentSkin: any;
}

const countFinishedSkins = (skins: Skin[] | undefined): number => {
  let count = 0;
  if (skins != undefined) {
    skins.forEach((skin) => {
      if (skin.finished === true) {
        count++;
      }
    });
  }

  return count;
};

const DraggableCircle: React.FC<Pallet> = ({ifSkinAdded, addSkin, pallet, currentSkin}) => {
  const [posX, setPosX] = useState(pallet.position_x / windowSize);
  const [posY, setPosY] = useState(pallet.position_y / windowSize);
  const [colorFill, setColorFill] = useState(
    pallet.capacity > pallet.skinsAmount || pallet.capacity == pallet.skinsAmount ? 'white' : 'red',
  );
  const [colorStroke, setColorStroke] = useState(
    pallet.skinsAmount > pallet.capacity || pallet.capacity == pallet.skinsAmount ? 'red' : '#0058FF',
  );
  const [colorFillText, setColorFillText] = useState(pallet.capacity >= pallet.skinsAmount ? '#0058FF' : 'white');
  const [capacityTextMargin, setCapacityTextMargin] = useState(
    pallet.skinsAmount > 9 ? SKIN_MARGIN_X : SKIN_MARGIN_X_SMALL,
  );
  const [skinsAmount, setSkinsAmount] = useState(pallet.skinsAmount);

  const add = () => {
    if (
      currentSkin.finished == false &&
      (pallet.capacity > pallet.skinsAmount || pallet.capacity != pallet.skinsAmount)
    ) {
      setSkinsAmount(pallet.skinsAmount + 1);
      addSkin();
      setColorStroke(skinsAmount + 1 > pallet.capacity || pallet.capacity == skinsAmount + 1 ? 'red' : '#0058FF');
      setCapacityTextMargin(skinsAmount > 8 ? SKIN_MARGIN_X : SKIN_MARGIN_X_SMALL);
      setColorFill(ifSkinAdded ? '#0058FF' : colorFill);
      setColorFillText(ifSkinAdded ? '#0058FF' : colorFillText);
    }
  };

  return (
    <>
      <rect
        x={posX - CIRCLE_RADIUS}
        y={posY - CIRCLE_RADIUS}
        width={CIRCLE_RADIUS * 2}
        height={CIRCLE_RADIUS * 2}
        stroke={colorStroke}
        fill={ifSkinAdded ? '#0058FF' : colorFill}
        strokeWidth="3"
        rx="5"
        onClick={add}
        className="block"
        style={{fontSize: '28px', cursor: 'pointer'}}
      />
      <text x={posX - CIRCLE_RADIUS + 0} y={posY - CIRCLE_RADIUS - 10} fill="#0058FF" style={{fontSize: NAME_CATEGORY}}>
        {pallet.category}
      </text>
      <text
        x={posX - CIRCLE_RADIUS + capacityTextMargin}
        y={posY - CIRCLE_RADIUS + SKIN_MARGIN_Y}
        fill={ifSkinAdded ? 'white' : colorFillText}
        onClick={add}
        style={{fontSize: SKIN_SIZE, cursor: 'pointer', fontWeight: '700'}}>
        {pallet.skinsAmount}
      </text>
      <text
        x={posX - CIRCLE_RADIUS + CIRCLE_NAME_X}
        y={posY - CIRCLE_RADIUS + CIRCLE_NAME}
        fill="#0058FF"
        style={{fontSize: NAME_SIZE}}>
        {pallet.name}
      </text>
    </>
  );
};

export default function SkinsList() {
  const {skin} = useParams<{skin: string}>();
  const classes = useStyles();
  const [finishedPalletModalOpen, setFinishedPalletModalOpen] = useState(false);
  const [detailSkinModalOpen, setDetailSkinModalOpen] = useState(false);
  const tray = useSelector((state: RootState) => state.tray.currentTray);
  const pallets = useSelector((state: RootState) => state.pallet.pallets);
  const skins = useSelector((state: RootState) => state.pallet?.skinPallet);
  const finishedSkinCount = countFinishedSkins(skins);
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();

  if (skin == undefined || !skins || !Array.isArray(skins) || skins.length === 0) {
    navigate('/dessalage/');
    return <></>;
  }

  const currentSkinIndex = skins.findIndex((s) => s.number === parseInt(skin, 10));
  const currentSkin = currentSkinIndex !== -1 ? skins[currentSkinIndex] : undefined;
  const nextSkin = currentSkinIndex > 0 ? skins[currentSkinIndex - 1] : undefined;
  const prevSkin = currentSkinIndex < skins.length - 1 ? skins[currentSkinIndex + 1] : undefined;

  if (currentSkin == undefined) {
    navigate(`/404`);
  }

  const onPrev = () => {
    if (prevSkin && tray) {
      navigate(`/dessalage/plateau/${tray.id}/peaux/${prevSkin.number}`);
    }
  };

  const onNext = async () => {
    if (nextSkin && tray) {
      navigate(`/dessalage/plateau/${tray.id}/peaux/${nextSkin.number}`);
    }
  };

  const editPlan = () => {
    if (tray && currentSkin) {
      dispatch(setCurrentSkin(currentSkin));
      navigate(`/dessalage/editer`);
    }
  };

  const palletDetails = () => {
    if (currentSkin) {
      dispatch(setCurrentSkin(currentSkin));
      navigate(`/dessalage/detail`);
    }
  };

  const addPallet = () => {
    setFinishedPalletModalOpen(true);
  };

  const finishedPallet = () => {
    setFinishedPalletModalOpen(false);
    window.location.reload();
  };

  const removeSkinInPallet = () => {
    const index = pallets.findIndex(function (pallet) {
      return pallet.id == currentSkin?.pallet_id;
    });

    if (
      index != undefined &&
      currentSkin != undefined &&
      currentSkinIndex != undefined &&
      tray != undefined &&
      tray.id != undefined
    ) {
      const currentSkinID = currentSkin.id;
      const trayID = tray.id;
      dispatch(removeSkinToPallet({index, currentSkin, currentSkinIndex}));
      dispatch(deleteSkinInPallet({trayID, currentSkinID}));
    }
  };

  const handleAddSkin = (index: number, currentSkin: Skin | undefined, currentSkinIndex: number | undefined) => {
    if (currentSkin != undefined && currentSkinIndex != undefined && tray != undefined && tray.id != undefined) {
      if (!currentSkin.finished) {
        const palletID = pallets[index].id ?? '';
        const currentSkinID = currentSkin.id;
        const trayID = tray.id;
        dispatch(setSkinToPallet({index, currentSkin, currentSkinIndex}));
        dispatch(addSkinToPallet({palletID, trayID, currentSkinID}));
      }
    }
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid item={true} container spacing={0}>
        <Grid item={true} xs={2}>
          <Button variant="text" className={classes.btn} onClick={() => navigate(`/dessalage`)}>
            &lt; Retour
          </Button>
        </Grid>
        <Grid item={true} xs={4} container>
          {currentSkin && currentSkin.skinAlert && (
            <Typography variant="h6" gutterBottom className={classes.alert}>
              ⚠ ATTENTION PEAU ⚠
            </Typography>
          )}
          {currentSkin && detailSkinModalOpen && (
            <DetailSkinModal currentSkin={currentSkin} handleClose={() => setDetailSkinModalOpen(false)} />
          )}
          <Typography variant="h6" gutterBottom className={classes.btn}>
            Fait : {finishedSkinCount} / {skins?.length}
          </Typography>
        </Grid>
        <Grid item={true} xs={3} container justifyContent="center">
          <Button variant="contained" className={classes.btn} onClick={addPallet}>
            FIN DE PALETTE
          </Button>
          {currentSkin && (
            <Button
              variant="contained"
              color="error"
              className={classes.btn}
              onClick={removeSkinInPallet}
              disabled={currentSkin.finished == false}>
              Enlever la peau
            </Button>
          )}
        </Grid>
        {finishedPalletModalOpen && tray && (
          <FinishedPalletModal pallets={pallets} tray={tray} handleClose={() => finishedPallet()} />
        )}
        <Grid item={true} xs={3} container justifyContent="flex-end">
          <Button variant="contained" className={classes.btn} onClick={editPlan}>
            Ajouter une catégorie
          </Button>
          <Button variant="contained" className={classes.btn} onClick={palletDetails}>
            Détail des palettes
          </Button>
        </Grid>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '90%',
        }}>
        <Container className={classes.root}>
          <Grid item={true} container spacing={0}>
            <Grid item={true} xs={4} container>
              <Button variant="text" className={classes.btnArrow} onClick={onPrev}>
                {prevSkin && `< ${prevSkin.number}`}
              </Button>
            </Grid>
            <Grid item={true} xs={4} container justifyContent="center">
              <Typography
                variant="h6"
                gutterBottom
                className={classes.pointed}
                onClick={() => setDetailSkinModalOpen(true)}>
                N° {currentSkin?.number ?? ''}{' '}
                {currentSkin?.ref_number_error ? ' (' + currentSkin?.ref_number_error + ') ' : ''}
              </Typography>
            </Grid>
            <Grid item={true} xs={4} container justifyContent="flex-end">
              <Button variant="text" className={classes.btnArrow} onClick={onNext}>
                {nextSkin && `${nextSkin?.number} >`}
              </Button>
            </Grid>
          </Grid>
          <div className={classes.page}>
            <Grid item={true} xs={12} container justifyContent="center">
              <div>
                <svg style={{width: CANVAS_WIDTH + 'px', height: CANVAS_HEIGHT + 'px', border: '1px solid silver'}}>
                  {currentSkin &&
                    pallets.map((pallet, index) =>
                      pallet.supplier_id === tray?.supplier_id ? (
                        <DraggableCircle
                          key={index}
                          ifSkinAdded={currentSkin.pallet_id === pallet.id}
                          pallet={pallet}
                          currentSkin={currentSkin}
                          addSkin={() => handleAddSkin(index, currentSkin, currentSkinIndex)}
                        />
                      ) : null,
                    )}
                </svg>
              </div>
            </Grid>
          </div>
        </Container>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100%',
  },
  page: {
    height: `100%`,
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    margin: 10,
  },
  btnArrow: {
    margin: 10,
    fontSize: 18,
  },
  alert: {
    margin: 10,
    color: 'red',
  },
  pointed: {
    cursor: 'pointer',
    fontSize: 24,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
