import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import {User} from 'src/models/User';
import {axiosInstance} from 'src/security/useAxios';
import {login} from 'src/client/Auth';

export interface UserState {
  currentUser?: User;
  users: User[];
}
const initialState: UserState = {
  users: [],
};

export const fetchUsers = createAsyncThunk<User[]>('users/fetch', async () => {
  const users = await axiosInstance.get('/users', {});

  return users.data;
});

export const fetchSalageUsers = createAsyncThunk<User[]>('salage/users/fetch', async () => {
  const users = await axiosInstance.get('/salage/users', {});

  return users.data;
});

export const onUserUpdate = createAsyncThunk<User[], {userID: string; name: string; email: string}>(
  'user/update',
  async ({userID, name, email}) => {
    const users = await axiosInstance.post(`/user/${userID}/update`, {
      name: name,
      email: email,
    });

    return users.data;
  },
);

export const deleteUser = createAsyncThunk<User[], string>('user/delete', async (userID) => {
  const users = await axiosInstance.post(`/user/${userID}/delete`, {});

  return users.data;
});

export const onUserCreate = createAsyncThunk<User[], {name: string; email: string; password: string}>(
  'user/create',
  async ({name, email, password}) => {
    const users = await axiosInstance.post(`/user/create`, {
      name: name,
      email: email,
      password: password,
    });

    return users.data;
  },
);

export const onUserPasswordUpdate = createAsyncThunk<User[], {userID: string; password: string}>(
  'user/update-password',
  async ({userID, password}) => {
    const users = await axiosInstance.post(`/user/${userID}/update-password`, {
      password: password,
    });
    return users.data;
  },
);

export const fetchLogin = createAsyncThunk<
  User,
  {email: FormDataEntryValue | null; password: FormDataEntryValue | null}
>('login/fetch', async ({email, password}) => {
  let user = await axiosInstance.post(`/login`, {
    email: email,
    password: password,
  });

  login(user.data.token);

  const userSansToken = {
    ...user,
    data: {
      ...user.data,
      token: undefined,
    },
  };
  user = userSansToken;

  return user.data;
});

export const userSlice = createSlice({
  name: 'User',
  initialState,
  reducers: {
    connect: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    disconnect: (state) => {
      state.currentUser = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(fetchSalageUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(onUserCreate.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(onUserPasswordUpdate.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(onUserUpdate.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(deleteUser.fulfilled, (state, action) => {
      state.users = action.payload;
    });
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
  },
});

export const {connect, disconnect} = userSlice.actions;

export default userSlice.reducer;
