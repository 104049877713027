import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import {Skeleton, Typography} from '@mui/material';
import {Lot} from 'src/models/Lot';
import {useSelector} from 'react-redux';
import {RootState, useAppThunkDispatch} from 'src/store';
import {fetchSalageSkin} from 'src/store/lotSlice';
import {Defect} from 'src/models/Defect';
import {useNavigate} from 'react-router-dom';
import AlertDialog from 'src/components/Defect_manager/deletemodal/deletemodal';

type ModalLotDetailProps = {
  lot: Lot;
  handleClose: () => void;
  handleNext: () => void;
};

export default function ModalLotDetail(props: ModalLotDetailProps): JSX.Element {
  const {lot, handleClose, handleNext} = props;
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const currentLot = useSelector((state: RootState) => state.lot?.currentLot);
  const skins = useSelector((state: RootState) => state.lot?.skinLot);

  useEffect(() => {
    dispatch(fetchSalageSkin(lot.id));
  }, [lot]);

  // Déchirures, Échancrures, Coutelures, Trous
  const processTrous = (p: Defect) =>
    p.neck[3] + p.back[3] + p.leg_right[3] + p.leg_left[3] + p.shoulder_right[3] + p.shoulder_left[3];
  const processCoutelures = (p: Defect) =>
    p.neck[2] + p.back[2] + p.leg_right[2] + p.leg_left[2] + p.shoulder_right[2] + p.shoulder_left[2];
  const processEchancrures = (p: Defect) =>
    p.neck[1] + p.back[1] + p.leg_right[1] + p.leg_left[1] + p.shoulder_right[1] + p.shoulder_left[1];
  const processDechirures = (p: Defect) =>
    p.neck[0] + p.back[0] + p.leg_right[0] + p.leg_left[0] + p.shoulder_right[0] + p.shoulder_left[0];

  const troues = skins?.map((p) => processTrous(p.defect)).reduce((sum, n) => sum + n, 0) ?? 0;
  const coutelures = skins?.map((p) => processCoutelures(p.defect)).reduce((sum, n) => sum + n, 0) ?? 0;
  const echancrures = skins?.map((p) => processEchancrures(p.defect)).reduce((sum, n) => sum + n, 0) ?? 0;
  const dechirures = skins?.map((p) => processDechirures(p.defect)).reduce((sum, n) => sum + n, 0) ?? 0;

  return (
    <Dialog open>
      <Stack direction="row" alignItems="center" spacing={1}>
        <DialogTitle>LOT {lot.name}</DialogTitle>
        <AlertDialog lot={lot} close={handleClose} />
      </Stack>
      <DialogContent>
        <Typography>
          Peux restantes : {lot.last_skin - lot.first_skin + 1 - lot.count_sort}/{lot.last_skin - lot.first_skin + 1}
        </Typography>
        {currentLot?.id !== lot.id ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        ) : (
          <>
            <Typography>Nombre de peaux troués : {troues}</Typography>
            <Typography>Nombre de peaux coutelées : {coutelures}</Typography>
            <Typography>Nombre de peaux déchirées : {dechirures}</Typography>
            <Typography>Nombre de peaux échancrées : {echancrures}</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => navigate(`/salage/${lot.id}/list`)}>
          DÉTAIL DES PEAUX
        </Button>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={handleNext}>
          Suivant
        </Button>
      </DialogActions>
    </Dialog>
  );
}
