import React, {KeyboardEvent} from 'react';
import {TextField, TextFieldProps} from '@mui/material';
import {ChangeEvent} from 'react';

type NumberTextFieldProps = Omit<TextFieldProps, 'onChange' | 'value' | 'type'> & {
  value: number | undefined;
  type: 'number';
  onNumberChange?: (value: number | undefined) => void;
};

export default function NumberTextField(props: NumberTextFieldProps): JSX.Element {
  const {value, onNumberChange, onKeyPress, ...rest} = props;

  const onlyNumber = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
      return;
    }
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (onNumberChange) {
      if (value === '') {
        return onNumberChange(undefined);
      }
      const intValue = parseInt(value, 10);
      if (!isNaN(intValue)) {
        return onNumberChange(intValue);
      }
    }
  };

  return <TextField {...rest} value={value || ''} onKeyPress={onlyNumber} onChange={onChange} />;
}
