export const login = (newToken: string) => {
  const [partie1, partie2] = diviserToken(newToken);
  stockerToken(partie1, partie2);
};

export const logout = () => {
  removeToken();
};

const diviserToken = (token: string): string[] => {
  const longueur = Math.ceil(token.length / 2);
  const partie1 = token.slice(0, longueur);
  const partie2 = token.slice(longueur);
  return [partie1, partie2];
};

const stockerToken = (partie1: string, partie2: string) => {
  localStorage.setItem('token', JSON.stringify(partie1));
  const anneeEnMillisecondes = 365 * 24 * 60 * 60 * 1000;
  const expirationDate = new Date(Date.now() + anneeEnMillisecondes);
  const expirationString = `expires=${expirationDate.toUTCString()}`;
  const domain = process.env.REACT_APP_ENV_DOMAIN;
  document.cookie = `touraine-cuirs-token=${partie2}; ${expirationString}; path=/; domain=${domain}`;
};

export const removeToken = () => {
  const domain = process.env.REACT_APP_ENV_DOMAIN;
  localStorage.removeItem('token');
  document.cookie = `touraine-cuirs-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
};

export const getToken = (): string => {
  let userToken = '';
  const tokenString = localStorage.getItem('token') || '';
  const partie1 = tokenString ? JSON.parse(tokenString) : '';
  const partie2 =
    document.cookie
      ?.split('; ')
      ?.find((row) => row.startsWith('touraine-cuirs-token'))
      ?.split('=')[1] || '';

  if (partie1 && partie2) {
    userToken = partie1 + partie2;
  } else {
    removeToken();
  }

  return userToken;
};
