import React from 'react';
import {Routes, Navigate, Route} from 'react-router-dom';
import Home from '../views/Defect_manager/Home';
import Skin from '../views/Defect_manager/Skin';
import SkinResume from '../views/Defect_manager/SkinResume';
import SkinListView from '../views/Defect_manager/SkinList';
import Print from '../views/Defect_manager/Print';

export default function DefectManagerRouter(): JSX.Element {
  return (
    <Routes>
      <Route path=":lot">
        <Route path="list/:skin?" element={<SkinListView />} />
        <Route path="resume/print" element={<Print />} />
        <Route path="resume" element={<SkinResume />} />
        <Route path=":skin" element={<Skin />} />
        <Route index element={() => <Navigate to="/" replace />} />
      </Route>
      <Route path="/" element={<Home />} />
    </Routes>
  );
}
