import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {makeStyles} from '@mui/styles';
import {Button} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {parseISO, format} from 'date-fns';
import {Lot} from 'src/models/Lot';

type LotProps = {
  currentLot: Lot;
  countTray: number;
  countPallet: number;
};

export default function LotResume(props: LotProps) {
  const {currentLot, countTray, countPallet} = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const [countIsolateC3, setCountIsolateC3] = useState(0);

  useEffect(() => {
    let count = 0;
    if (currentLot.c3) {
      currentLot.c3.forEach((c3) => {
        if (c3.isolate === true) {
          count++;
        }
      });
    }
    setCountIsolateC3(count);
  }, [currentLot]);

  return (
    <Paper
      sx={{
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}>
      <Grid container spacing={0}>
        <Grid item={true} xs={12} md={8}>
          <Grid container spacing={0}>
            <Grid item={true} xs={6} md={8}>
              <Typography variant="body2">
                Lot : <Typography variant="h6">{currentLot.name}</Typography>
              </Typography>
              <Typography mt={2} variant="body2">
                Fournisseur : <Typography variant="h6">{currentLot.supplier.name}</Typography>
              </Typography>
            </Grid>
            <Grid item={true} xs={6} md={4}>
              <Typography mt={2} variant="body2">
                Date de reception :{' '}
                <Typography variant="h6">
                  {currentLot.reception_date ? format(parseISO(currentLot.reception_date), 'dd/MM/yy') : 'non défini'}
                </Typography>
              </Typography>
              <Typography mt={2} variant="body2">
                Date d&apos;abattage :{' '}
                <Typography variant="h6">
                  {format(parseISO(currentLot.slaughter_date), 'dd/MM/yy') ?? 'non défini'}
                </Typography>
              </Typography>
            </Grid>
            <Grid item={true}>
              <Typography mt={2} variant="body2">
                Catégories :
              </Typography>
              <Stack direction="row" mt={1} spacing={1}>
                {currentLot.categories
                  ? currentLot?.categories.map((category) => (
                      <Chip key={category.id} label={category.name} variant="outlined" />
                    ))
                  : 'non défini'}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item={true} xs={12} md={4} mt={{xs: 2, md: 0}}>
          <Grid container>
            <Grid item={true} xs={7}>
              <Typography variant="body2">
                Total plateau : <Typography variant="h6">{countTray}</Typography>
              </Typography>
              <Typography mt={2} variant="body2">
                c3 :{' '}
                <Typography variant="h6">
                  {countIsolateC3}/{currentLot.c3?.length} non isolés
                </Typography>
              </Typography>
              {currentLot.c3
                ? currentLot?.c3.map((c3) => (
                    <Chip
                      key={c3.id}
                      label={c3.number}
                      className={classes.chip}
                      color={c3.isolate == true ? 'error' : 'success'}
                    />
                  ))
                : 'non défini'}
            </Grid>
            <Grid item={true} xs={5}>
              <Typography variant="body2">
                Total palettes : <Typography variant="h6">{countPallet}</Typography>
              </Typography>
              <Button
                variant="contained"
                className={classes.btn}
                onClick={() => navigate(`/gestion-des-lots/${currentLot.id}/peaux`)}>
                FICHE DE DÉPOUILLE
              </Button>
            </Grid>
            <Grid item={true} xs={11}>
              <Typography mt={2} variant="body2">
                Commentaire :
              </Typography>
              <Typography variant="subtitle2">{currentLot.comment ?? ''}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: 25,
    marginLeft: 25,
  },
  chip: {
    margin: 2,
  },
}));
