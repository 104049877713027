import * as React from 'react';
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import {useNavigate} from 'react-router-dom';
import SkinImage from 'src/assets/images/SkinImage.png';
import LotImage from 'src/assets/images/LotsImage.png';
import CometaImage from 'src/assets/images/CometaImage.png';
import {Container} from '@mui/material';
import {makeStyles} from '@mui/styles';

const images = [
  {
    url: `${LotImage}`,
    title: 'GESTION DES LOTS',
    width: '33%',
    route: '/gestion-des-lots',
  },
  {
    url: `${SkinImage}`,
    title: 'SALAGE',
    width: '33%',
    route: '/salage',
  },
  {
    url: `${CometaImage}`,
    title: 'DESSALAGE',
    width: '33%',
    route: '/dessalage',
  },
];

const ImageButton = styled(ButtonBase)(({theme}) => ({
  position: 'relative',
  top: '25%',
  height: '50%',
  [theme.breakpoints.down('sm')]: {
    width: '100% !important',
    height: '100%',
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid white',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center 10%',
  height: 200,
  marginBottom: '85%',
});

const Image = styled('span')(({theme}) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: '45%',
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const ImageBackdrop = styled('span')(({theme}) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: '0%',
  bottom: 0,
  backgroundColor: '#1976d2',
  opacity: 0.25,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({theme}) => ({
  height: 3,
  width: 50,
  backgroundColor: '#1976d2',
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 25px)',
}));

export default function Index() {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Container className={classes.root}>
      <Box sx={{display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%'}}>
        {images.map((image) => (
          <ImageButton
            focusRipple
            key={image.title}
            onClick={() => navigate(`${image.route}`)}
            style={{
              width: image.width,
            }}>
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <Image>
              <ImageSrc style={{backgroundImage: `url(${image.url})`}} />
              <Typography
                component="span"
                variant="subtitle1"
                color="white"
                sx={{
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}>
                {image.title}
                <ImageMarked className="MuiImageMarked-root" />
              </Typography>
            </Image>
          </ImageButton>
        ))}
      </Box>
    </Container>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
  },
});
