import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import {ContactType} from 'src/models/ContactType';
import {axiosInstance} from 'src/security/useAxios';
export interface ContactTypeState {
  contactTypes: ContactType[];
}

const initialState: ContactTypeState = {
  contactTypes: [],
};

export const fetchContactTypes = createAsyncThunk<ContactType[]>('contactType/fetch', async () => {
  const contactTypes = await axiosInstance.get('/contact-type', {});

  return contactTypes.data;
});

export const createContactType = createAsyncThunk<ContactType, Omit<ContactType, 'id'>>(
  'contactType/create',
  async (contactTypeData) => {
    const newContactTypes = await axiosInstance.post('/contact-type/create', {
      name: contactTypeData.name,
    });

    return newContactTypes.data;
  },
);

export const updateContactType = createAsyncThunk<ContactType[], ContactType>(
  'contactType/update',
  async ({id, name}) => {
    const contactType = await axiosInstance.post(`/contact-type/${id}/update`, {
      name: name,
    });

    return contactType.data;
  },
);

export const deleteContactType = createAsyncThunk<ContactType[], string>(
  'contactType/delete',
  async (contactTypeID: string) => {
    const contactType = await axiosInstance.post(`/contact-type/${contactTypeID}/delete`, {});

    return contactType.data;
  },
);

export const contactTypeSlice = createSlice({
  name: 'ContactType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchContactTypes.fulfilled, (state, action) => {
      state.contactTypes = action.payload;
    });
    builder.addCase(createContactType.fulfilled, (state, action) => {
      state.contactTypes = [...state.contactTypes, action.payload];
    });
    builder.addCase(updateContactType.fulfilled, (state, action) => {
      state.contactTypes = action.payload;
    });
    builder.addCase(deleteContactType.fulfilled, (state, action) => {
      state.contactTypes = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = contactTypeSlice.actions;

export default contactTypeSlice.reducer;
