import React, {useState} from 'react';
import {Skin} from 'src/models/Skin';

import {DataGrid, GridColDef, GridValueGetterParams, GridCellParams, GridSortModel} from '@mui/x-data-grid';
import {parseISO, format} from 'date-fns';
import SkinsList from 'src/views/Desalting_manager/SkinsList';

const columns: GridColDef[] = [
  {
    field: 'number',
    cellClassName: 'pointer',
    headerName: 'N° PEAU',
    flex: 1,
    renderCell: (params) => {
      return <a>{params.row.number}</a>;
    },
  },
  {
    field: 'trayName',
    headerName: 'N° PLATEAU',
    flex: 1,
    renderCell: (params) => {
      return params.row.trayName ?? '';
    },
  },
  {
    field: 'lot',
    headerName: 'N° LOT',
    flex: 1,
    renderCell: (params) => {
      return params.row.lot ?? '';
    },
  },
  {
    field: 'category',
    headerName: 'CATÉGORIE',
    flex: 1,
    renderCell: (params) => {
      return params.row.category ?? '';
    },
  },
  {
    field: 'palletName',
    headerName: 'N° DE PALETTE',
    flex: 1,
    renderCell: (params) => {
      return params.row.palletName ?? '';
    },
  },
  {
    field: 'reception_date',
    headerName: 'DATE D’ENTREE',
    type: 'date',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.reception_date ? new Date(params.row.slaughter_date) : '';
    },
  },
  {
    field: 'slaughter_date',
    headerName: 'DATE ABATTAGE',
    type: 'date',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.slaughter_date ? new Date(params.row.slaughter_date) : '';
    },
  },
];

type PalletsTabProps = {
  skins: Skin[];
  onClick?: (skin: Skin) => void;
};

export default function SkinTab(props: PalletsTabProps): JSX.Element {
  const {skins, onClick} = props;

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'slaughter_date',
      sort: 'desc',
    },
  ]);

  const onCellClick = (params: GridCellParams) => {
    const skin = skins.find((l) => l.id === params.id);
    if (skin !== undefined && onClick) {
      onClick(skin);
    }
  };

  return (
    <DataGrid
      rows={skins}
      columns={columns}
      getRowId={(row) => row.id}
      pageSize={20}
      rowsPerPageOptions={[20]}
      onCellClick={onCellClick}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
    />
  );
}
