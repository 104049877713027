import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {RootState, useAppThunkDispatch} from 'src/store';
import {onUserCreate} from 'src/store/userSlice';

type ModalCreateLotProps = {
  handleClose: () => void;
};

export default function CreateUserModal(props: ModalCreateLotProps): JSX.Element {
  const {handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const onSubmit = () => {
    let hasError = false;

    if (name.length == 0) {
      setNameError('Le nom est obligatoire !');
      hasError = true;
    }

    if (email.length == 0) {
      setEmailError("L'email est obligatoire !");
      hasError = true;
    }

    if (password.length == 0 || passwordConfirm.length == 0) {
      setPasswordError('Le mot de passe est obligatoire !');
      hasError = true;
    }
    if (password != passwordConfirm) {
      setPasswordError('Ces mots de passe ne correspondent pas. Veuillez réessayer !');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    dispatch(onUserCreate({name, email, password}))
      .unwrap()
      .then(() => handleClose());
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Ajouter un nouveau utilisateur</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item={true} xs={12} p={2}>
            <TextField
              margin="dense"
              label="Nom :"
              type="text"
              fullWidth
              variant="standard"
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              error={nameError.length != 0}
              helperText={nameError}
            />
            <TextField
              margin="dense"
              label="Email :"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              InputLabelProps={{
                shrink: true,
              }}
              error={emailError.length != 0}
              helperText={emailError}
            />
          </Grid>
          <Grid item={true} xs={6} p={2}>
            <TextField
              margin="dense"
              fullWidth
              value={password}
              label="Mot de passe"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              error={passwordError.length != 0}
              helperText={passwordError}
            />
          </Grid>
          <Grid item={true} xs={6} p={2}>
            <TextField
              margin="dense"
              fullWidth
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              label="Confirmer le mot de passe"
              type="password"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
