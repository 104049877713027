import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import ModalCreateLot from 'src/components/Defect_manager/modalcreatelot/ModalCreateLot';
import LotTab from 'src/components/Defect_manager/lottab/LotTab';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Navbar from 'src/components/Desalting_manager/navbar/Navbar';
import Paper from '@mui/material/Paper';
import ModalLotDetail from 'src/components/Defect_manager/modallotdetail/ModalLotDetail';
import ModalSaisieInfo from 'src/components/Defect_manager/modalsaisieinfo/ModalSaisieInfo';
import ResponsiveAppBar from 'src/components/Defect_manager/responsiveappbar/ResponsiveAppBar';
import {Container} from '@mui/material';
import {createSalageLot, fetchSalageLots, fetchSalageSkin, reset} from 'src/store/lotSlice';
import {Box, Tab, Button} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {RootState, useAppThunkDispatch} from 'src/store';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {frFR} from '@mui/x-data-grid';
import {frFR as corefrFR} from '@mui/material/locale';
import {makeStyles} from '@mui/styles';
import {CircularProgress} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Lot} from 'src/models/Lot';

export default function Home(): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<'1' | '2'>('1');
  const [modalAddLotOpen, setModalAddLotOpen] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [modalLotDetail, setModalLotDetail] = useState<Lot | undefined>(undefined);
  const [modalSaisieInfo, setModalSaisieInfo] = useState<Lot | undefined>(undefined);
  const navigate = useNavigate();
  const classes = useStyles();

  const lots = useSelector((state: RootState) => state.lot.lots);
  const lotsEnCours = lots.filter((lot) => {
    return lot.count_sort < lot.last_skin - lot.first_skin + 1;
  });

  const lotsTermines: Lot[] = lots.filter((lot) => {
    return lot.count_sort >= lot.last_skin - lot.first_skin + 1;
  });
  const dispatch = useAppThunkDispatch();

  const fetchData = async () => {
    await dispatch(fetchSalageLots()).then(() => setFormSubmit(true));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const mdTheme = createTheme(
    {
      palette: {
        primary: {main: '#1976d2'},
      },
    },
    frFR, // x-data-grid translations
    corefrFR, // core translations
  );
  const onLotCreate = (
    name: string,
    slaughter_date: string,
    first_skin: number,
    last_skin: number,
    c3: number[],
    c2: number[],
    supplierName?: string,
    reception_date?: string,
  ) => {
    dispatch(
      createSalageLot({
        name,
        slaughter_date,
        first_skin,
        last_skin,
        c3,
        c2,
        supplierName,
        reception_date,
      }),
    )
      .unwrap()
      .then(() => setModalAddLotOpen(false));
  };

  const cancel = () => {
    setModalLotDetail(undefined);
    dispatch(reset());
  };

  const nextLotStep = () => {
    if (modalLotDetail) {
      if (modalLotDetail.count_sort === modalLotDetail.last_skin - modalLotDetail.first_skin + 1) {
        // fini
      } else {
        setModalSaisieInfo(modalLotDetail);
        setModalLotDetail(undefined);
      }
    }
  };

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{display: 'flex'}}>
          <CssBaseline />
          <Navbar />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              width: '100%',
              overflow: 'auto',
            }}>
            <Toolbar />
            <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
              <Container className={classes.root}>
                <ResponsiveAppBar />
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                    }}>
                    <Container className={classes.root}>
                      <div className={classes.page}>
                        <TabContext value={selectedTab}>
                          <Box sx={{borderBottom: 1, borderColor: 'divider', display: 'flex'}}>
                            <TabList
                              sx={{flexGrow: 1}}
                              onChange={(_, value) => setSelectedTab(value)}
                              aria-label="lab API tabs example">
                              <Tab label="LOT EN COURS" value="1" />
                              <Tab label="LOT TERMINÉS" value="2" />
                            </TabList>
                            <Button
                              variant="contained"
                              className={classes.btn}
                              onClick={() => setModalAddLotOpen(true)}>
                              ajouter un lot
                            </Button>
                            {modalAddLotOpen && (
                              <ModalCreateLot
                                handleSubmit={onLotCreate}
                                handleClose={() => setModalAddLotOpen(false)}
                              />
                            )}
                          </Box>
                          {!formSubmit ? (
                            <div className={classes.tabCircular}>
                              <CircularProgress />
                            </div>
                          ) : (
                            <div className={classes.tabContainer}>
                              <TabPanel value="1" className={classes.tabPanel}>
                                <LotTab lots={lotsEnCours} onClick={(lot) => setModalLotDetail(lot)} />
                              </TabPanel>
                              <TabPanel value="2" className={classes.tabPanel}>
                                <LotTab
                                  lots={lotsTermines}
                                  onClick={async (lot) => {
                                    await dispatch(fetchSalageSkin(lot.id));
                                    navigate(`/salage/${lot.id}/resume`);
                                  }}
                                />
                              </TabPanel>
                            </div>
                          )}
                        </TabContext>
                        {modalLotDetail && (
                          <ModalLotDetail lot={modalLotDetail} handleClose={cancel} handleNext={nextLotStep} />
                        )}
                        {modalSaisieInfo && (
                          <ModalSaisieInfo
                            lot={modalSaisieInfo}
                            handleClose={() => {
                              setModalLotDetail(modalSaisieInfo);
                              setModalSaisieInfo(undefined);
                            }}
                            handleNext={(first) => navigate(`/salage/${modalSaisieInfo.id}/${first}`)}
                          />
                        )}
                      </div>
                    </Container>
                  </Paper>
                </Grid>
              </Container>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  page: {
    height: `calc(100% - 64px)`,
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tabCircular: {
    height: '50vh',
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabPanel: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    height: '100%',
  },
  tabContainer: {
    height: '100vh',
  },
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
  },
  btn: {
    margin: 5,
  },
}));
