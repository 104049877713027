import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {useNavigate} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {useSelector} from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import {RootState, useAppThunkDispatch} from 'src/store';
import {fetchDessalageConfigPalletPlans} from 'src/store/palletSlice';
import {createPallet} from 'src/store/palletSlice';

type ModalLotDetailProps = {
  handleClose: () => void;
};

export default function ModalLotDetail(props: ModalLotDetailProps): JSX.Element {
  const {handleClose} = props;
  const plans = useSelector((state: RootState) => state?.pallet?.plans);
  const [plan, setPlan] = useState<string>('');
  const dispatch = useAppThunkDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchDessalageConfigPalletPlans());
  }, []);

  const openPlan = () => {
    if (plan !== '') {
      dispatch(createPallet(plan))
        .unwrap()
        .then(() => handleClose());
    }
  };
  return (
    <Dialog open>
      <Stack direction="row" alignItems="center" spacing={1}>
        <DialogTitle>Selectionez la configuration</DialogTitle>
      </Stack>
      <DialogContent>
        <TextField
          id="outlined-select-currency"
          className={classes.input}
          fullWidth
          select
          value={plan}
          label="Plans :"
          onChange={(e) => setPlan(e.target.value)}>
          {plans &&
            plans.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name} - {option.supplier}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={openPlan}>
          Suivant
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 10,
  },
  inputAutocoplite: {
    marginTop: 10,
    marginBottom: 10,
  },
  inputTextFild: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
