import React from 'react';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Navbar from 'src/components/Lot_manager/navbar/Navbar';
import {Box} from '@mui/material';
import {frFR} from '@mui/x-data-grid';
import {frFR as corefrFR} from '@mui/material/locale';

type Props = {
  children: string | JSX.Element;
};

export default function Dashboard({children}: Props) {
  const mdTheme = createTheme(
    {
      palette: {
        primary: {main: '#1976d2'},
      },
    },
    frFR, // x-data-grid translations
    corefrFR, // core translations
  );
  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{display: 'flex'}}>
        <CssBaseline />
        <Navbar />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            width: '100%',
            overflow: 'auto',
          }}>
          <Toolbar />
          <Container maxWidth="xl" sx={{mt: 4, mb: 4}}>
            {children}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
