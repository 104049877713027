import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {useSelector} from 'react-redux';
import {setTray, fetchDessalageTrays} from 'src/store/traySlice';
import {fetchDessalageSkins, fetchDesalagePallets, reset} from 'src/store/palletSlice';
import {Box, Tab} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import Button from '@mui/material/Button';
import {RootState, useAppThunkDispatch} from 'src/store';
import TrayTab from 'src/components/Desalting_manager/tabs/TrayTab';
import ModalDeleteTray from 'src/components/Desalting_manager/modals/ModalDeleteTray';
import ModalReturnedTray from 'src/components/Desalting_manager/modals/ModalReturnedTray';
import Paper from '@mui/material/Paper';
import {CircularProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {useNavigate} from 'react-router-dom';
import Dashboard from 'src/components/Desalting_manager/dashboard/Dashboard';
import {Tray} from 'src/models/Tray';

export default function Home() {
  const [selectedTab, setSelectedTab] = useState<'1' | '2'>('1');
  const [formSubmit, setFormSubmit] = useState(false);
  const [selectTray, setSelectTray] = useState<Tray | undefined>();
  const [deleteMotalOpen, setDeleteMotalOpen] = useState(false);
  const [returnedMotalOpen, setReturnedMotalOpen] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();
  const trayTreaty = useSelector((state: RootState) => state?.tray?.trays);
  const pallets = useSelector((state: RootState) => state?.pallet?.pallets);
  const dispatch = useAppThunkDispatch();

  const fetchData = async () => {
    await dispatch(fetchDessalageTrays()).then(() => setFormSubmit(true));
    dispatch(reset());
    dispatch(fetchDesalagePallets());
  };

  useEffect(() => {
    fetchData();
  }, []);

  const suppliers = Array.from(new Set(trayTreaty.map((tray) => tray.supplierName)));
  const [traysAll, setTraysToShow] = useState(trayTreaty.filter((tray) => tray.show));

  const openSkinsList = (tray: Tray) => {
    dispatch(setTray(tray));
    if (tray.id) {
      dispatch(fetchDessalageSkins(tray.id)).then(() =>
        navigate(`/dessalage/plateau/${tray.id}/peaux/${tray.last_skin}`),
      );
    }
  };
  const openDelete = (tray: Tray) => {
    if (tray) {
      setSelectTray(tray);
      setDeleteMotalOpen(true);
    }
  };
  const trayReturned = (tray: Tray) => {
    if (tray) {
      setSelectTray(tray);
      setReturnedMotalOpen(true);
    }
  };

  const cancelDeleteModale = () => {
    setDeleteMotalOpen(false);
  };
  const cancelReturnedModale = () => {
    setReturnedMotalOpen(false);
  };

  const handleClick = (supplierName: string) => {
    let updatedTrays: Tray[] = [];
    if (supplierName === 'all') {
      updatedTrays = traysAll.map((tray) => ({
        ...tray,
        show: true,
      }));
    } else {
      updatedTrays = traysAll.map((tray) => ({
        ...tray,
        show: tray.supplierName === supplierName,
      }));
    }

    setTraysToShow(updatedTrays);
  };

  return (
    <Dashboard>
      <Grid item xs={12} md={12} lg={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}>
          <Container className={classes.root}>
            <div className={classes.page}>
              <TabContext value={selectedTab}>
                <Box sx={{borderBottom: 1, borderColor: 'divider', display: 'flex'}}>
                  <TabList
                    sx={{flexGrow: 1}}
                    onChange={(_, value) => setSelectedTab(value)}
                    aria-label="lab API tabs example">
                    <Tab label="PLATEAUX EN COURS" value="1" />
                  </TabList>
                  {trayTreaty && (
                    <>
                      <Button variant="contained" className={classes.btn} onClick={() => handleClick('all')}>
                        Tous
                      </Button>
                      {suppliers.map((name) => (
                        <Button
                          key={name}
                          variant="contained"
                          className={classes.btn}
                          onClick={() => handleClick(name ?? '')}>
                          {name}
                        </Button>
                      ))}
                    </>
                  )}
                </Box>
                {!formSubmit ? (
                  <div className={classes.tabCircular}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div className={classes.tabContainer}>
                    <TabPanel value="1" className={classes.tabPanel}>
                      <TrayTab
                        key={traysAll.length}
                        trays={traysAll}
                        onClick={async (tray) => {
                          openSkinsList(tray);
                        }}
                        deleteModal={async (tray) => {
                          openDelete(tray);
                        }}
                        trayReturned={async (tray) => {
                          trayReturned(tray);
                        }}
                      />
                    </TabPanel>
                  </div>
                )}
              </TabContext>
            </div>
            {deleteMotalOpen && selectTray && <ModalDeleteTray tray={selectTray} handleClose={cancelDeleteModale} />}
            {returnedMotalOpen && selectTray && (
              <ModalReturnedTray tray={selectTray} handleClose={cancelReturnedModale} />
            )}
          </Container>
        </Paper>
      </Grid>
    </Dashboard>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  page: {
    height: `calc(100% - 64px)`,
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tabCircular: {
    height: '50vh',
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabPanel: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    height: '100%',
  },
  tabContainer: {
    height: '100vh',
  },
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
  },
  btn: {
    margin: 5,
  },
}));
