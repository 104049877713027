import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {Skeleton, Typography} from '@mui/material';
import {Lot} from 'src/models/Lot';
import {useSelector} from 'react-redux';
import {RootState} from 'src/store';
import {Defect} from 'src/models/Defect';

type ModalFinBacProps = {
  lot: Lot;
  handleClose: () => void;
  handleFinLot: () => void;
  handleNextBac: () => void;
};

export default function ModalFinBac(props: ModalFinBacProps): JSX.Element {
  const {lot, handleClose, handleFinLot, handleNextBac} = props;
  const currentLot = useSelector((state: RootState) => state.lot.currentLot);
  const skins = useSelector((state: RootState) => state.lot.skinLot);

  // Déchirures, Échancrures, Coutelures, Trous

  const processTrous = (p: Defect): boolean =>
    p.neck[3] > 0 ||
    p.back[3] > 0 ||
    p.leg_right[3] > 0 ||
    p.leg_left[3] > 0 ||
    p.shoulder_right[3] > 0 ||
    p.shoulder_left[3] > 0;
  const processCoutelures = (p: Defect) =>
    p.neck[2] > 0 ||
    p.back[2] > 0 ||
    p.leg_right[2] > 0 ||
    p.leg_left[2] > 0 ||
    p.shoulder_right[2] > 0 ||
    p.shoulder_left[2] > 0;
  const processEchancrures = (p: Defect) =>
    p.neck[1] > 0 ||
    p.back[1] > 0 ||
    p.leg_right[1] > 0 ||
    p.leg_left[1] > 0 ||
    p.shoulder_right[1] > 0 ||
    p.shoulder_left[1] > 0;
  const processDechirures = (p: Defect) =>
    p.neck[0] > 0 ||
    p.back[0] > 0 ||
    p.leg_right[0] > 0 ||
    p.leg_left[0] > 0 ||
    p.shoulder_right[0] > 0 ||
    p.shoulder_left[0] > 0;

  const troues = skins?.filter((p) => processTrous(p.defect)).length;
  const coutelures = skins?.filter((p) => processCoutelures(p.defect)).length;
  const echancrures = skins?.filter((p) => processEchancrures(p.defect)).length;
  const dechirures = skins?.filter((p) => processDechirures(p.defect)).length;

  return (
    <Dialog open>
      <DialogTitle>LOT {lot.name}</DialogTitle>
      <DialogContent>
        {currentLot?.id !== lot.id ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        ) : (
          <>
            <Typography>Nombre total de peau : {skins?.length}</Typography>
            <Typography>Nombre de peaux troués : {troues}</Typography>
            <Typography>Nombre de peaux coutelées : {coutelures}</Typography>
            <Typography>Nombre de peaux déchirées : {dechirures}</Typography>
            <Typography>Nombre de peaux échancrées : {echancrures}</Typography>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" color="error" onClick={handleFinLot}>
          Fin du lot
        </Button>
        <Button variant="contained" onClick={handleNextBac}>
          Nouveau bac
        </Button>
      </DialogActions>
    </Dialog>
  );
}
