import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {useSelector} from 'react-redux';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import {switchPallet} from 'src/store/palletSlice';
import {RootState, useAppThunkDispatch} from 'src/store';
import {makeStyles} from '@mui/styles';
import {Skin} from 'src/models/Skin';

type SwitchSkinModalProps = {
  currentSkin: Skin;
  handleClose: () => void;
};

export default function SwitchSkinModal(props: SwitchSkinModalProps): JSX.Element {
  const {currentSkin, handleClose} = props;

  const classes = useStyles();
  const dispatch = useAppThunkDispatch();
  const [pallet, setPallet] = useState<string>('');
  const pallets = useSelector((state: RootState) => state?.pallet?.palletsInProgress);

  const switchSkin = () => {
    if (pallet) {
      dispatch(switchPallet({skin: currentSkin.id, pallet: pallet}))
        .unwrap()
        .then(() => handleClose());
    }
  };

  return (
    <Dialog open fullWidth maxWidth={'lg'}>
      <DialogTitle>Changer de palette</DialogTitle>
      <DialogContent className={classes.modal}></DialogContent>
      <Grid item={true} xs={12} p={2}>
        <TextField
          id="outlined-select-currency"
          className={classes.input}
          fullWidth
          select
          value={pallet}
          label="Selectionner la palette :"
          onChange={(e) => setPallet(e.target.value)}>
          {pallets &&
            pallets.map((option) =>
              option.id != currentSkin.pallet_id ? (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ) : null,
            )}
        </TextField>
      </Grid>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Fermer
        </Button>
        <Button variant="contained" onClick={switchSkin} disabled={pallet === ''}>
          Deplacer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  page: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: -24,
    marginRight: -24,
    paddingTop: 24,
    position: 'relative',
  },
  header: {
    minHeight: 156,
    display: 'flex',
    flexDirection: 'row',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    '& > *': {
      flex: 1,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  },

  skin: {
    height: 'calc(100% - 316px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRight: 'solid #707070 1px',
    borderTop: 'solid #707070 1px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: '50%',
  },
  break: {
    height: 0,
    flexBasis: '100%',
  },
  modal: {
    padding: 0,
  },
  footer: {
    padding: 10,
    minHeight: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: 10,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
});
