import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import {useNavigate, useParams} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import {fetchPalletSkins} from 'src/store/palletSlice';
import SwitchSkinModal from 'src/components/Lot_manager/modals/SwitchSkinModal';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import SkinTab from 'src/components/Desalting_manager/tabs/SkinTab';
import {RootState, useAppThunkDispatch} from 'src/store';
import {useSelector} from 'react-redux';
import {Skin} from 'src/models/Skin';

export default function PalletSwitchSkin() {
  const classes = useStyles();
  const skins = useSelector((state: RootState) => state.pallet?.skinsList);
  const [detailSkin, setDetailSkin] = useState<Skin>();
  const [detailSkinModalOpen, setDetailSkinModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();

  const fetchData = async () => {
    await dispatch(fetchPalletSkins());
  };

  const openSkinsDetail = (skin: Skin) => {
    if (skin) {
      setDetailSkin(skin);
      setDetailSkinModalOpen(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid item={true} container spacing={0}>
        <Grid item={true} xs={6}>
          <Button variant="text" className={classes.btn} onClick={() => navigate('/gestion-des-lots/palettes')}>
            &lt; Retour
          </Button>
        </Grid>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Container className={classes.root}>
          <div className={classes.page}>
            {detailSkin && detailSkinModalOpen && (
              <SwitchSkinModal currentSkin={detailSkin} handleClose={() => setDetailSkinModalOpen(false)} />
            )}
            <Grid item={true} xs={12} container justifyContent="center">
              {skins && (
                <SkinTab
                  skins={skins}
                  onClick={async (skin) => {
                    openSkinsDetail(skin);
                  }}
                />
              )}
            </Grid>
          </div>
        </Container>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100vh',
  },
  page: {
    height: `100%`,
    paddingTop: theme.spacing(1),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    margin: 10,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
