import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import {RootState, useAppThunkDispatch} from 'src/store';
import {onUserUpdate} from 'src/store/userSlice';
import {User} from 'src/models/User';

type UpdateUserModalProps = {
  user: User;
  handleClose: () => void;
};

export default function UpdateUserModal(props: UpdateUserModalProps): JSX.Element {
  const {user, handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');

  const onSubmit = () => {
    let hasError = false;

    if (name.length == 0) {
      setNameError('Le nom est obligatoire !');
      hasError = true;
    }

    if (email == undefined || email.length == 0) {
      setEmailError("L'email est obligatoire !");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (user.id != undefined && name != undefined && email != undefined) {
      const userID = user.id;
      dispatch(onUserUpdate({userID, name, email}))
        .unwrap()
        .then(() => handleClose());
    }
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Modifier l&apos;utilisateur</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item={true} xs={12} p={2}>
            <TextField
              margin="dense"
              label="Nom :"
              type="text"
              fullWidth
              variant="standard"
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              error={nameError.length != 0}
              helperText={nameError}
            />
            <TextField
              margin="dense"
              label="Email :"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              InputLabelProps={{
                shrink: true,
              }}
              error={emailError.length != 0}
              helperText={emailError}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Modifier
        </Button>
      </DialogActions>
    </Dialog>
  );
}
