import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from '../views/Lot_manager/Home';
import LotResume from '../views/Lot_manager/LotResume';
import SkinList from '../views/Lot_manager/SkinList';
import Supplier from '../views/Lot_manager/Supplier';
import SupplierResume from '../views/Lot_manager/SupplierResume';
import PlansList from '../views/Lot_manager/PlansList';
import PalletPlanCreator from '../views/Lot_manager/PalletPlanCreator';
import PalletPlanUpdater from '../views/Lot_manager/PalletPlanUpdater';
import Parameters from 'src/views/Lot_manager/Parameters';
import Pallet from 'src/views/Lot_manager/Pallet';
import PalletResume from 'src/views/Lot_manager/PalletResume';
import PalletSwitchSkin from 'src/views/Lot_manager/PalletSwitchSkin';
import Dashboard from 'src/components/Lot_manager/dashboard/Dashboard';

export default function LotManagerRouter(): JSX.Element {
  return (
    <Dashboard>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path=":lot">
          <Route path="detail" element={<LotResume />} />
          <Route path="peaux" element={<SkinList />} />
        </Route>
        <Route path="fournisseurs" element={<Supplier />} />
        <Route path="palettes" element={<Pallet />} />
        <Route path="palettes/:palette">
          <Route path="detail" element={<PalletResume />} />
          <Route path="changer" element={<PalletSwitchSkin />} />
        </Route>
        <Route path="fournisseurs/:supplier">
          <Route path="detail" element={<SupplierResume />} />
          <Route path="plans" element={<PlansList />} />
          <Route path="plans/ajouter" element={<PalletPlanCreator />} />
          <Route path="plans/:plan">
            <Route path="modifier" element={<PalletPlanUpdater />} />
          </Route>
        </Route>
        <Route path="parametres" element={<Parameters />} />
      </Routes>
    </Dashboard>
  );
}
