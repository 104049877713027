import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {Link} from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import {disconnect} from 'src/store/userSlice';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'src/store';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function ResponsiveAppBar() {
  const [open] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = useState<Element | null>(null);
  const currentUser = useSelector((state: RootState) => state.user?.currentUser);
  const dispatch = useDispatch();

  const handleOpenUserMenu: React.MouseEventHandler = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="absolute" open={open}>
      <Toolbar
        sx={{
          pr: '24px', // keep right padding when drawer closed
        }}>
        <Typography component="h1" variant="h6" color="inherit" noWrap sx={{flexGrow: 1}}>
          Touraine Cuirs / SALAGE
        </Typography>
        <Link to="/" style={{textDecoration: 'none', marginRight: 20, color: 'white'}}>
          Quitter
        </Link>
        <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
          <Avatar alt={currentUser?.name} src={currentUser?.image_url ?? ''} />
        </IconButton>
      </Toolbar>
      <Menu
        sx={{mt: '45px'}}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}>
        <MenuItem onClick={() => dispatch(disconnect())}>
          <Typography textAlign="center">Deconnexion</Typography>
        </MenuItem>
      </Menu>
    </AppBar>
  );
}
