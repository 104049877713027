import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from '@mui/material';
import {Skin} from 'src/models/Skin';
import {RootState, useAppThunkDispatch} from 'src/store';
import {changeSalageSkinNumber} from 'src/store/lotSlice';

type ModalSaisieSkinNumberProps = {
  skin: Skin;
  handleClose: () => void;
};

export default function ModalSaisieSkinNumber(props: ModalSaisieSkinNumberProps): JSX.Element {
  const {skin, handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const [newSkinNumber, setNewSkinNumber] = useState<string>();
  const [newSkinNumberError, setNewSkinNumberError] = useState<string | undefined>();

  const onSubmit = () => {
    let hasError = false;

    if (newSkinNumber == undefined || newSkinNumber == '') {
      setNewSkinNumberError('Obligatoire');
      hasError = true;
    }
    if (hasError) {
      return;
    }
    if (newSkinNumber) {
      dispatch(changeSalageSkinNumber({skin: skin, number: newSkinNumber}))
        .unwrap()
        .then(() => handleClose());
    }
  };

  return (
    <Dialog open>
      <DialogTitle>Peau N° {skin.number}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Identifiant peau"
          type="text"
          fullWidth
          variant="standard"
          error={newSkinNumberError !== undefined}
          helperText={newSkinNumberError}
          value={newSkinNumber}
          onChange={(e) => setNewSkinNumber(e.target.value.toUpperCase())}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Changer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
