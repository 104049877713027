import React, {useEffect, useState} from 'react';
import {makeStyles} from '@mui/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import CreateConfigPlanModal from 'src/components/Lot_manager/modals/CreateConfigPlanModal';
import NumberTextField from 'src/components/Lot_manager/numbertextfield/NumberTextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import MenuItem from '@mui/material/MenuItem';
import {useNavigate} from 'react-router-dom';
import {RootState, useAppThunkDispatch} from 'src/store';
import {useSelector} from 'react-redux';
import {ConfigPalletPositions} from 'src/models/ConfigPalletPositions';
import {updateConfigPalletPlan, deleteConfigPalletPlan} from 'src/store/supplierSlice';
import {Supplier} from 'src/models/Supplier';

const CANVAS_WIDTH = window.innerWidth > 1200 ? window.innerWidth - 600 : window.innerWidth - 200;
const CANVAS_HEIGHT = window.innerHeight - 200;
const CIRCLE_RADIUS = window.innerWidth > 1200 ? 50 : 30;
const CIRCLE_NAME = window.innerWidth > 1200 ? 120 : 80;
const NAME_SIZE = window.innerWidth > 1200 ? '16px' : '12px';
const NAME_CATEGORY = window.innerWidth > 1200 ? '18px' : '12px';
const SKIN_SIZE = window.innerWidth > 1200 ? '32px' : '24px';
const SKIN_MARGIN_Y = window.innerWidth > 1200 ? 60 : 40;
const windowSize = window.innerWidth > 822 ? 1 : 2;

interface Pallet {
  category: string;
  name: string;
  capacity: number;
  position_x: number;
  position_y: number;
  setPalletsList: React.Dispatch<React.SetStateAction<ConfigPalletPositions[]>>;
}

const DraggableCircle: React.FC<Pallet> = ({name, category, capacity, position_x, position_y, setPalletsList}) => {
  const categoriesState = useSelector((state: RootState) => state.category.categories);
  const [isDown, setIsDown] = useState(false);
  const [posX, setPosX] = useState(position_x / windowSize);
  const [posY, setPosY] = useState(position_y / windowSize);
  const [screenX, setScreenX] = useState(0);
  const [screenY, setScreenY] = useState(0);
  const [categoryName, setCategoryName] = useState(category);
  const [pallet, setPallet] = useState(name);
  const [palletCapacity, setPalletCapacity] = useState<number>(capacity);
  const [length, setlength] = useState<number>(pallet.length * 8);
  const [capacityTextMargin, setCapacityTextMargin] = useState(CIRCLE_RADIUS - length);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editedPalletName, setEditedPalletName] = useState('');

  const addCapacity = (e: number | undefined) => {
    setPalletCapacity(e ?? 0);
    if (e) {
      setCapacityTextMargin(e < 10 ? 40 : 32);
    }
  };

  const onMouseDown = (e: React.MouseEvent<SVGRectElement> | React.TouchEvent<SVGRectElement>) => {
    e.preventDefault();
    if ('touches' in e) {
      setIsDown(true);
      setScreenX(e.touches[0].screenX);
      setScreenY(e.touches[0].screenY);
    } else {
      setIsDown(true);
      setScreenX(e.screenX);
      setScreenY(e.screenY);
    }
  };

  const onMouseMove = (e: React.MouseEvent<SVGRectElement> | React.TouchEvent<SVGRectElement>) => {
    e.preventDefault();
    if (!isDown) {
      return;
    }
    const shiftX = 'touches' in e ? e.touches[0].screenX - screenX : e.screenX - screenX;
    const shiftY = 'touches' in e ? e.touches[0].screenY - screenY : e.screenY - screenY;
    const updatedPosX = posX + shiftX;
    const updatedPosY = posY + shiftY;

    const maxX = CANVAS_WIDTH - CIRCLE_RADIUS;
    const maxY = CANVAS_HEIGHT - CIRCLE_RADIUS;
    const minX = CIRCLE_RADIUS;
    const minY = CIRCLE_RADIUS;

    const adjustedPosX = Math.max(Math.min(updatedPosX, maxX), minX);
    const adjustedPosY = Math.max(Math.min(updatedPosY, maxY), minY);

    setPosX(adjustedPosX);
    setPosY(adjustedPosY);
    setScreenX('touches' in e ? e.touches[0].screenX : e.screenX);
    setScreenY('touches' in e ? e.touches[0].screenY : e.screenY);
    setPalletsList((prevPalletsList) => {
      const updatedPalletsList = prevPalletsList.map((pallet) => {
        if (pallet.position_x === position_x && pallet.position_y === position_y) {
          return {...pallet, position_x: adjustedPosX, position_y: adjustedPosY};
        } else {
          return pallet;
        }
      });
      return updatedPalletsList;
    });
  };

  const onMouseUp = () => {
    setIsDown(false);
    setScreenX(0);
    setScreenY(0);
  };

  const openEditModal = (name: string) => {
    setEditedPalletName(name);
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setPalletsList((prevPalletsList) => {
      const updatedPalletsList = prevPalletsList.map((pallet) => {
        if (pallet.name === name) {
          return {...pallet, name: editedPalletName, category: categoryName, capacity: palletCapacity};
        } else {
          return pallet;
        }
      });
      return updatedPalletsList;
    });

    setPallet(editedPalletName);
    setEditedPalletName('');
    setEditModalOpen(false);
  };

  const handleDelete = () => {
    setPalletsList((prevPalletsList) => {
      const removeIndex = prevPalletsList.map((e) => e.name).indexOf(pallet);
      const updatedPalletsListFirstPart = prevPalletsList.slice(0, removeIndex);
      const updatedPalletsListSecondPart = prevPalletsList.slice(removeIndex + 1, prevPalletsList.length);
      return [...updatedPalletsListFirstPart, ...updatedPalletsListSecondPart];
    });

    setEditModalOpen(false);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedPalletName(e.target.value);
  };

  return (
    <g>
      <text
        x={posX - CIRCLE_RADIUS + capacityTextMargin}
        y={posY - CIRCLE_RADIUS + SKIN_MARGIN_Y}
        fill="#0058FF"
        style={{fontSize: SKIN_SIZE, cursor: 'pointer', fontWeight: '700'}}>
        {pallet}
      </text>
      <rect
        x={posX - CIRCLE_RADIUS}
        y={posY - CIRCLE_RADIUS}
        width={CIRCLE_RADIUS * 2}
        height={CIRCLE_RADIUS * 2}
        stroke="#0058FF"
        fill="transparent"
        strokeWidth="3"
        className="block"
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onTouchStart={onMouseDown}
        onTouchMove={onMouseMove}
        onTouchEnd={onMouseUp}
        style={{fontSize: '28px', cursor: 'move'}}
      />
      <text
        x={posX - CIRCLE_RADIUS}
        y={posY - CIRCLE_RADIUS - 10}
        fill="#0058FF"
        style={{fontSize: NAME_CATEGORY, cursor: 'pointer'}}
        onClick={() => openEditModal(pallet)}>
        {categoryName}
      </text>
      <text
        x={posX - CIRCLE_RADIUS}
        y={posY - CIRCLE_RADIUS + CIRCLE_NAME}
        fill="#0058FF"
        style={{fontSize: NAME_SIZE, cursor: 'pointer'}}
        onClick={() => openEditModal(pallet)}>
        Capacité : {palletCapacity}
      </text>
      <Dialog open={editModalOpen} maxWidth={'lg'}>
        <DialogTitle>Modifier la palette</DialogTitle>
        <DialogContent>
          <Grid container spacing={0}>
            <Grid item={true} xs={12} p={2}>
              <TextField
                margin="dense"
                label="Nom :"
                type="text"
                style={{marginTop: 10}}
                fullWidth
                variant="standard"
                value={editedPalletName}
                onChange={onChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <NumberTextField
                margin="dense"
                label="Capacité :"
                placeholder="Capacité maximal de peaux"
                type="number"
                fullWidth
                variant="standard"
                value={palletCapacity}
                onNumberChange={(e) => addCapacity(e)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item={true} xs={12} p={2}>
              <TextField
                id="outlined-select-currency"
                fullWidth
                select
                value={categoryName}
                label="Categories :"
                onChange={(e) => setCategoryName(e.target.value)}>
                {categoriesState.map((option) => (
                  <MenuItem key={option.id} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Supprimer
          </Button>
          <Button variant="contained" onClick={handleEditModalClose}>
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>
    </g>
  );
};

export default function PalletPlanUpdater() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [error, setError] = useState('');
  const supplier = useSelector((state: RootState) => state.supplier?.currentSupplier);
  const currentPlanName = useSelector((state: RootState) => state.supplier?.currentPlan?.name);
  const currentPlanID = useSelector((state: RootState) => state.supplier?.currentPlan?.id);
  const [name, setName] = useState(currentPlanName ?? '');
  const plans = useSelector((state: RootState) => state.supplier?.currentPlan?.plans);
  const [palletsList, setPalletsList] = useState<ConfigPalletPositions[]>(plans ?? []);
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();

  const handleClose = () => {
    setOpen(false);
  };

  const addPallet = () => {
    setCreateModalOpen(true);
  };

  const save = () => {
    if (name == '') {
      setError('Le nom est obligatoire !');
    }

    if (name != '' && supplier && palletsList != undefined && currentPlanID) {
      dispatch(
        updateConfigPalletPlan({
          currentPlanID,
          supplier,
          name,
          palletsList,
        }),
      )
        .unwrap()
        .then(() => navigate(`/gestion-des-lots/fournisseurs/${supplier.id}/plans`));
    }
  };
  const addNewPallet = (category: string, name: string, capacity: number, position_x: number, position_y: number) => {
    const newPallet: ConfigPalletPositions = {
      name: name,
      capacity: capacity,
      position_x: position_x,
      position_y: position_y,
      category: category,
    };

    setPalletsList((prevPalletsList) => {
      const updatedPalletsList = [...prevPalletsList, newPallet];
      return updatedPalletsList;
    });
  };

  const handleDelete = (supplier: Supplier, currentPlanID: string) => {
    if (currentPlanID && supplier) {
      dispatch(deleteConfigPalletPlan({supplier, currentPlanID}));
    }
    handleClose();
    navigate(`/gestion-des-lots/fournisseurs/${supplier.id}/plans`);
  };
  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid item={true} container spacing={0}>
        <Grid item={true} xs={8}>
          {supplier && (
            <Button
              variant="text"
              className={classes.btn}
              onClick={() => navigate(`/gestion-des-lots/fournisseurs/${supplier.id}/plans`)}>
              &lt; Retour à la liste
            </Button>
          )}
        </Grid>
        <Grid item={true} xs={4} container justifyContent="flex-end">
          <Button variant="contained" className={classes.btn} color="error" onClick={() => setOpen(true)}>
            Supprimer
          </Button>
          <Button variant="contained" className={classes.btn} onClick={save}>
            Enregistrer
          </Button>
        </Grid>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Container className={classes.root}>
          <Grid item={true} container spacing={0}>
            <Grid item={true} xs={8}>
              <TextField
                margin="dense"
                label="Nom :"
                type="text"
                style={{marginTop: 10}}
                fullWidth
                error={error.length != 0}
                helperText={error}
                variant="standard"
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item={true} xs={4} container justifyContent="flex-end">
              <Button variant="contained" className={classes.btn} onClick={addPallet}>
                Ajouter une palette
              </Button>
            </Grid>
          </Grid>
          {createModalOpen && (
            <CreateConfigPlanModal addNewPallet={addNewPallet} handleClose={() => setCreateModalOpen(false)} />
          )}
          <div className={classes.page}>
            <div>
              <svg
                style={{
                  width: CANVAS_WIDTH + 'px',
                  height: CANVAS_HEIGHT + 'px',
                  border: '1px solid silver',
                  overflow: 'hidden',
                }}>
                {palletsList.map((pallet, index) => (
                  <DraggableCircle
                    key={index}
                    name={pallet.name}
                    capacity={pallet.capacity}
                    category={pallet.category}
                    position_x={pallet.position_x}
                    position_y={pallet.position_y}
                    setPalletsList={setPalletsList}
                  />
                ))}
              </svg>
            </div>
          </div>
        </Container>
        {currentPlanID && supplier && (
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{'Confirmation de la suppression'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Êtes-vous sûr de vouloir supprimer cette configuration ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>NON</Button>
              <Button onClick={() => handleDelete(supplier, currentPlanID)} autoFocus>
                OUI
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100vh',
  },
  page: {
    height: `100%`,
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    margin: 10,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
