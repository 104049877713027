import React, {useEffect, useState} from 'react';
import {Modal, Box, Typography, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 200,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const ErrorModal = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [localStorageData, setLocalStorageData] = useState<string | null>(null);

  const handleClose = () => {
    localStorage.removeItem('error');
    setOpen(false);
  };

  useEffect(() => {
    updateLocalStorageData();
    const intervalId = setInterval(updateLocalStorageData, 5000);
    return () => clearInterval(intervalId);
  }, []);

  const updateLocalStorageData = () => {
    const errorMessage = localStorage.getItem('error');
    const data = errorMessage ? JSON.parse(errorMessage) : '';
    setLocalStorageData(data);

    if (data) {
      setOpen(true);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}>
          <CloseIcon />
        </IconButton>
        <Typography id="modal-modal-title" variant="h2" color="error">
          Erreur
        </Typography>
        <Typography id="modal-modal-description" variant="subtitle2" color="black" sx={{mt: 2}}>
          {localStorageData || 'Pas de connexion Internet'}
        </Typography>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
