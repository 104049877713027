import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import {Contact} from 'src/models/Contact';
import {Supplier} from 'src/models/Supplier';
import {ConfigPalletPlan} from 'src/models/ConfigPalletPlan';
import {ConfigPalletPositions} from 'src/models/ConfigPalletPositions';
import {axiosInstance} from 'src/security/useAxios';

export interface SupplierState {
  suppliers: Supplier[];
  currentSupplier?: Supplier;
  currentPlan?: ConfigPalletPlan;
}

const initialState: SupplierState = {
  suppliers: [],
};

export const fetchSuppliers = createAsyncThunk<Supplier[]>('suppliers/fetch', async () => {
  const suppliers = await axiosInstance.get('/suppliers', {});

  return suppliers.data;
});

export const fetchSalageSuppliers = createAsyncThunk<Supplier[]>('salage/suppliers/fetch', async () => {
  const suppliers = await axiosInstance.get('/salage/suppliers', {});

  return suppliers.data;
});

export const fetchConfigPalletPlans = createAsyncThunk<Supplier, string>(
  'suppliers/plans/fetch',
  async (supplierID: string) => {
    const plans = await axiosInstance.get(`/suppliers/${supplierID}/plans`, {});

    return plans.data;
  },
);

export const createSupplier = createAsyncThunk<Supplier[], {name: string; prefix: string; contact: Contact}>(
  'supplier/create',
  async (supplierData) => {
    const newSupplier = await axiosInstance.post('/suppliers/create', {
      name: supplierData.name,
      prefix: supplierData.prefix,
      contact: {
        type: supplierData.contact.contactType.name,
        name: supplierData.contact.name,
        phoneNumber: supplierData.contact.phoneNumber,
        email: supplierData.contact.email,
      },
    });

    return newSupplier.data;
  },
);

export const createConfigPalletPlan = createAsyncThunk<
  Supplier,
  {supplier: Supplier; name: string; palletsList: ConfigPalletPositions[]}
>('plan/create', async (configPalletPlanData) => {
  const newConfigPalletPlan = await axiosInstance.post(`/plans/suppliers/create/${configPalletPlanData.supplier.id}`, {
    name: configPalletPlanData.name,
    plans: configPalletPlanData.palletsList,
  });

  return newConfigPalletPlan.data;
});

export const updateConfigPalletPlan = createAsyncThunk<
  Supplier,
  {currentPlanID: string; supplier: Supplier; name: string; palletsList: ConfigPalletPositions[]}
>('plan/update', async (configPalletPlanData) => {
  const newConfigPalletPlan = await axiosInstance.post(
    `/plans/suppliers/update/${configPalletPlanData.supplier.id}/plan/${configPalletPlanData.currentPlanID}`,
    {
      name: configPalletPlanData.name,
      plans: configPalletPlanData.palletsList,
    },
  );

  return newConfigPalletPlan.data;
});

export const deleteConfigPalletPlan = createAsyncThunk<Supplier, {supplier: Supplier; currentPlanID: string}>(
  'plan/delete',
  async ({supplier, currentPlanID}) => {
    const newConfigPalletPlan = await axiosInstance.post(
      `/plans/suppliers/delete/${supplier.id}/plan/${currentPlanID}`,
      {},
    );

    return newConfigPalletPlan.data;
  },
);

export const updateSupplier = createAsyncThunk<Supplier, Supplier>('supplier/update', async ({id, name, prefix}) => {
  const supplier = await axiosInstance.post(`/suppliers/${id}/update`, {
    name: name,
    prefix: prefix,
  });

  return supplier.data;
});

export const deleteSupplier = createAsyncThunk<Supplier[], string>('supplier/delete', async (supplierID: string) => {
  const supplier = await axiosInstance.post(`/suppliers/${supplierID}/delete`, {});

  return supplier.data;
});

export const createContact = createAsyncThunk<Supplier, Omit<Contact, 'id'> & {supplieID: string}>(
  'contacts/create',
  async (contactData) => {
    const newContact = await axiosInstance.post('/contacts/create', {
      name: contactData.name,
      supplier_id: contactData.supplieID,
      email: contactData.email,
      phone_number: contactData.phoneNumber,
      type: contactData.contactType,
    });

    return newContact.data;
  },
);

export const updateContact = createAsyncThunk<Contact, Contact>('contacts/update', async (contact) => {
  const response = await axiosInstance.post(`/contacts/${contact.id}/update`, {
    name: contact.name,
    email: contact.email,
    type: contact.contactType.name,
    phone_number: contact.phoneNumber,
  });

  return response.data;
});

export const deleteContact = createAsyncThunk<Contact, string>('contacts/delete', async (contactID: string) => {
  const contact = await axiosInstance.post(`/contacts/${contactID}/delete`, {});

  return contact.data;
});

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    setSupplier: (state, action: PayloadAction<Supplier | undefined>) => {
      state.currentSupplier = action.payload;
    },
    setPlan: (state, action: PayloadAction<ConfigPalletPlan | undefined>) => {
      state.currentPlan = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSuppliers.fulfilled, (state, action) => {
      state.suppliers = action.payload;
    });
    builder.addCase(fetchSalageSuppliers.fulfilled, (state, action) => {
      state.suppliers = action.payload;
    });
    builder.addCase(fetchConfigPalletPlans.fulfilled, (state, action) => {
      state.currentSupplier = action.payload;
    });
    builder.addCase(createConfigPalletPlan.fulfilled, (state, action) => {
      state.currentSupplier = action.payload;
    });
    builder.addCase(updateConfigPalletPlan.fulfilled, (state, action) => {
      state.currentSupplier = action.payload;
    });
    builder.addCase(createSupplier.fulfilled, (state, action) => {
      state.suppliers = action.payload;
    });
    builder.addCase(updateSupplier.fulfilled, (state, action) => {
      state.currentSupplier = action.payload;
    });
    builder.addCase(deleteSupplier.fulfilled, (state, action) => {
      state.suppliers = action.payload;
    });
    builder.addCase(createContact.fulfilled, (state, action) => {
      state.currentSupplier = action.payload;
    });
    builder.addCase(updateContact.fulfilled, (state, action) => {
      state.currentSupplier = action.payload;
    });
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.currentSupplier = action.payload;
    });
    builder.addCase(deleteConfigPalletPlan.fulfilled, (state, action) => {
      state.currentSupplier = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {setSupplier, setPlan} = supplierSlice.actions;

export default supplierSlice.reducer;
