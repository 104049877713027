import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import NumberTextField from '../numbertextfield/NumberTextField';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {format, parseISO} from 'date-fns';
import {Chip, CircularProgress} from '@mui/material';
import {parse, isPast} from 'date-fns';
import {makeStyles} from '@mui/styles';
import {RootState, useAppThunkDispatch} from 'src/store';
import {fetchCategories} from 'src/store/categorySlice';
import {fetchSuppliers} from 'src/store/supplierSlice';
import {useSelector} from 'react-redux';
import {Category} from 'src/models/Category';
import {Supplier} from 'src/models/Supplier';
import {Lot} from 'src/models/Lot';

type ModalCreateLotProps = {
  lot: Lot;
  handleClose: () => void;
  handleSubmit: (
    id: string,
    name: string,
    slaughter_date: string,
    first_skin: number,
    last_skin: number,
    c3: number[],
    categories?: Category[],
    comment?: string,
    reception_date?: string,
    supplierName?: string,
  ) => void;
};

export default function UpdateLotModal(props: ModalCreateLotProps): JSX.Element {
  const {handleClose, handleSubmit, lot} = props;
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();
  const categoriesState = useSelector((state: RootState) => state.category.categories);
  const suppliersState = useSelector((state: RootState) => state.supplier.suppliers);
  const c3inputRef = useRef<HTMLInputElement>();
  const [name, setLot] = useState(lot.name);
  const [supplier, setSupplier] = useState<Supplier>(lot.supplier);
  const [supplierName, setSupplierName] = useState<string>(lot.supplier.name);
  const [categories, setCategory] = useState<Category[] | undefined>(lot.categories ?? []);
  const [dateAbattage, setDateAbattage] = useState<string>(format(parseISO(lot.slaughter_date), 'Y-MM-dd'));
  const [dateReceipt, setDateReceipt] = useState<string | undefined>(
    lot.reception_date ? format(parseISO(lot.reception_date), 'Y-MM-dd') : '',
  );
  const [comment, setComment] = useState<string | undefined>(lot.comment);
  const [firstSkin, setFirstSkin] = useState<number | undefined>(lot.first_skin);
  const [lastSkin, setLastSkin] = useState<number | undefined>(lot.last_skin);
  const [c3input, setC3input] = useState<number | undefined>();
  const [c3list, setC3list] = useState<number[]>([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [dateslaughter_dateValid, setDateslaughter_dateValid] = useState(true);
  const dateslaughter_date = parse(dateAbattage, 'yyyy-MM-dd', new Date());

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchSuppliers());
    if (lot.c3) {
      const array: number[] = [];
      for (let index = 0; index < lot.c3.length; index++) {
        array.push(lot.c3[index].number);
      }
      setC3list(array);
    }
  }, []);

  useEffect(() => {
    if (dateAbattage === '') {
      setDateslaughter_dateValid(true);
      return;
    }

    setDateslaughter_dateValid(isPast(dateslaughter_date));
  }, [dateAbattage]);

  const formValid =
    formSubmit !== true &&
    name !== '' &&
    supplier !== undefined &&
    dateAbattage !== '' &&
    isPast(dateslaughter_date) &&
    firstSkin != undefined &&
    lastSkin != undefined &&
    firstSkin <= lastSkin;

  const addC3 = () => {
    if (c3input !== undefined) {
      setC3list([...c3list, c3input]);
      setC3input(undefined);
      c3inputRef.current?.focus();
    }
  };

  const update = () => {
    if (formValid) {
      handleSubmit(
        lot.id,
        name,
        dateAbattage,
        firstSkin,
        lastSkin,
        c3list,
        categories,
        comment,
        dateReceipt,
        supplierName,
      );
      setFormSubmit(true);
    }
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Modifier un lot</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item={true} xs={12} p={2}>
            <TextField
              margin="dense"
              label="Lot :"
              type="text"
              fullWidth
              variant="standard"
              value={name}
              onChange={(e) => setLot(e.target.value.toUpperCase())}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item={true} xs={12} p={2}>
            <TextField
              id="outlined-select-currency-supplier"
              className={classes.input}
              select
              fullWidth
              value={supplierName}
              onChange={(e) => setSupplierName(e.target.value)}
              label="Fournisseur :"
              defaultValue={supplier.name}>
              {suppliersState.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={12} p={2}>
            <TextField
              margin="dense"
              label="Date de reception :"
              type="date"
              fullWidth
              variant="standard"
              value={dateReceipt}
              onChange={(e) => setDateReceipt(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="dense"
              label="Date d'abattage :"
              type="date"
              fullWidth
              variant="standard"
              value={dateAbattage}
              onChange={(e) => setDateAbattage(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              error={dateslaughter_dateValid === false}
              helperText={dateslaughter_dateValid === false ? 'La date est dans le future' : undefined}
            />
          </Grid>
          <Grid xs={12} item={true} p={2}>
            <Autocomplete
              className={classes.input}
              multiple
              fullWidth
              value={categories}
              id="multiple-limit-tags"
              options={categoriesState}
              getOptionLabel={(option) => option.name}
              onChange={(e, newVal) => setCategory(newVal || '')}
              renderInput={(params) => (
                <TextField {...params} label="Catégories des peaux :" placeholder="Categories" />
              )}
            />
            <TextField
              id="outlined-multiline-static"
              className={classes.input}
              label="Commentaire :"
              multiline
              fullWidth
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Ajouter un commentaire…"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={update} disabled={formValid === false}>
          {formSubmit ? <CircularProgress /> : 'Modifier'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 10,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
