import React, {useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import {makeStyles} from '@mui/styles';
import {useAppThunkDispatch} from 'src/store';
import {finishedPallet} from 'src/store/palletSlice';
import {Pallet} from 'src/models/Pallet';
import {Tray} from 'src/models/Tray';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

type FinishedPalletModalProps = {
  pallets: Pallet[];
  tray: Tray;
  handleClose: () => void;
};

export default function FinishedPalletModal(props: FinishedPalletModalProps): JSX.Element {
  const {pallets, tray, handleClose} = props;
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();
  const [pallet, setPallet] = useState<string>('');
  const formValid = pallet != '';
  const [ifChecked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!ifChecked);
  };

  const creer = () => {
    if (formValid) {
      dispatch(finishedPallet({pallet, ifChecked}))
        .unwrap()
        .then(() => handleClose());
    }
  };

  return (
    <Dialog open fullWidth maxWidth={'lg'}>
      <DialogTitle>FIN DE PALETTE</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item={true} xs={12} p={1}>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox defaultChecked onChange={handleChange} />}
                label="Générer une nouvelle palette"
              />
            </FormGroup>
            <TextField
              id="outlined-select-currency"
              className={classes.input}
              fullWidth
              select
              value={pallet}
              label="Selectionner la palette :"
              onChange={(e) => setPallet(e.target.value)}>
              {pallets.map((option) =>
                option.supplier_id === tray.supplier_id ? (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ) : null,
              )}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={creer} disabled={formValid === false}>
          Finir
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 10,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
  dialog: {minWidth: '500px'},
}));
