import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import {Tray} from 'src/models/Tray';
import {axiosInstance} from 'src/security/useAxios';

export interface TrayState {
  trays: Tray[];
  currentTray?: Tray;
}
const initialState: TrayState = {
  trays: [],
};

export const fetchTrays = createAsyncThunk<Tray[]>('trays/fetch', async () => {
  const trays = await axiosInstance.get('/trays/treaty', {});

  return trays.data;
});
export const fetchDessalageTrays = createAsyncThunk<Tray[]>('dessalage/trays/fetch', async () => {
  const trays = await axiosInstance.get('/dessalage/trays/treaty', {});

  return trays.data;
});

export const trayDelete = createAsyncThunk<Tray[], string>('/tray/delete', async (tray: string) => {
  const trays = await axiosInstance.post(`/tray/${tray}/delete`, {});

  return trays.data;
});

export const trayReturned = createAsyncThunk<Tray[], string>('/tray/returned', async (tray: string) => {
  const trays = await axiosInstance.post(`/tray/${tray}/returned`, {});

  return trays.data;
});

export const traySlice = createSlice({
  name: 'Tray',
  initialState,
  reducers: {
    setTray: (state, action: PayloadAction<Tray | undefined>) => {
      state.currentTray = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTrays.fulfilled, (state, action) => {
      state.trays = action.payload.map((tray) => ({...tray, show: true}));
    });
    builder.addCase(fetchDessalageTrays.fulfilled, (state, action) => {
      state.trays = action.payload.map((tray) => ({...tray, show: true}));
    });
    builder.addCase(trayDelete.fulfilled, (state, action) => {
      state.trays = action.payload;
    });
    builder.addCase(trayReturned.fulfilled, (state, action) => {
      state.trays = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {setTray} = traySlice.actions;

export default traySlice.reducer;
