import React, {useEffect} from 'react';
import {Container, Typography, ImageList, ImageListItem, ImageListItemBar, Avatar} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {fetchSalageUsers, connect} from 'src/store/userSlice';
import {RootState} from 'src/store';
import {User} from 'src/models/User';
import {makeStyles} from '@mui/styles';
import {useNavigate} from 'react-router-dom';
import {Button} from '@mui/material';

export default function Signin(): JSX.Element {
  const classes = useStyles();
  const users = useSelector((state: RootState) => state.user.users);
  const currentUser = useSelector((state: RootState) => state.user.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchSalageUsers());
  }, []);

  useEffect(() => {
    if (currentUser !== undefined) {
      navigate('/salage/');
    }
  }, [currentUser]);

  return (
    <Container className={classes.container}>
      <Typography>Bonjour, merci de bien vouloir vous identifier</Typography>
      <ImageList cols={2} gap={8}>
        {users.map((user: User, index: number) => (
          <ImageListItem key={index}>
            <Avatar src={user.image_url ?? ``} sx={{width: 122, height: 122}} onClick={() => dispatch(connect(user))} />
            <ImageListItemBar title={user.name} position="below" className={classes.username} />
          </ImageListItem>
        ))}
      </ImageList>
      <Button variant="contained" onClick={() => navigate('/')}>
        Sortir
      </Button>
    </Container>
  );
}

const useStyles = makeStyles({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F4F7FC',
  },
  username: {
    textAlign: 'center',
  },
});
