import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Lot} from 'src/models/Lot';
import {Skin} from 'src/models/Skin';
import {useSelector} from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import {RootState, useAppThunkDispatch} from 'src/store';
import {fetchLotTrays, changeSalageTray} from 'src/store/lotSlice';

type ModalSaisieTrayProps = {
  lot: Lot;
  skin: Skin;
  handleClose: () => void;
};

export default function ModalSaisieTray(props: ModalSaisieTrayProps): JSX.Element {
  const {lot, skin, handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const classes = useStyles();
  const trays = useSelector((state: RootState) => state?.lot?.trays);
  const [tray, setTray] = useState<string>('');
  const [trayError, setTrayError] = useState<string | undefined>();

  const onSubmit = () => {
    let hasError = false;
    if (tray == undefined || tray == '') {
      setTrayError('Obligatoire');
      hasError = true;
    }
    if (hasError) {
      return;
    }
    if (tray) {
      dispatch(changeSalageTray({skin: skin.id, tray: tray}))
        .unwrap()
        .then(handleClose);
    }
  };

  useEffect(() => {
    dispatch(fetchLotTrays(lot.id));
  }, [lot]);

  return (
    <Dialog open>
      <DialogTitle>Peau N° {skin.number}</DialogTitle>
      <DialogContent>
        <TextField
          id="outlined-select-currency"
          className={classes.input}
          fullWidth
          select
          value={tray}
          label="Plateaux :"
          onChange={(e) => setTray(e.target.value)}>
          {trays &&
            trays.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                Plateau: {option.name} - Nombre de peaux: {option.skinsAmount ?? 0} - Bac: {option.bac ?? 'pas défini'}
              </MenuItem>
            ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Changer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 10,
  },
  inputAutocoplite: {
    marginTop: 10,
    marginBottom: 10,
  },
  inputTextFild: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
