import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import UpdateUserModal from 'src/components/Lot_manager/modals/UpdateUserModal';
import UpdateUserPasswordModal from 'src/components/Lot_manager/modals/UpdateUserPasswordModal';
import EditIcon from '@mui/icons-material/Edit';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CreateUserModal from 'src/components/Lot_manager/modals/CreateUserModal';
import {RootState, useAppThunkDispatch} from 'src/store';
import {fetchUsers, deleteUser} from 'src/store/userSlice';
import {useSelector} from 'react-redux';
import {User} from 'src/models/User';

export default function UserTab(): JSX.Element {
  const users = useSelector((state: RootState) => state.user.users);
  const [modalAddUserOpen, setModalAddUserOpen] = useState(false);
  const [modalUpdateOpen, setModalUpdateOpen] = useState(false);
  const [modalUpdatePasswordOpen, setModalUpdatePasswordOpen] = useState(false);
  const [user, setUser] = React.useState<User>();
  const dispatch = useAppThunkDispatch();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const handleClickOpenUpdateModal = (user: User) => {
    setUser(user);
    setModalUpdateOpen(true);
  };

  const handleClickOpenUpdatePasswordModal = (user: User) => {
    setUser(user);
    setModalUpdatePasswordOpen(true);
  };

  const handleClickOpen = (user: User) => {
    setUser(user);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteUser = () => {
    if (user && user.id) {
      dispatch(deleteUser(user.id));
    }
    setOpen(false);
  };

  return (
    <TableContainer style={{padding: 0}}>
      <Grid container spacing={0} mt={2} mb={2}>
        <Grid container justifyContent="flex-end">
          <Button variant="contained" onClick={() => setModalAddUserOpen(true)}>
            ajouter un utilisateur
          </Button>
          {modalAddUserOpen && <CreateUserModal handleClose={() => setModalAddUserOpen(false)} />}
          {modalUpdateOpen && user && <UpdateUserModal user={user} handleClose={() => setModalUpdateOpen(false)} />}
          {modalUpdatePasswordOpen && user && (
            <UpdateUserPasswordModal user={user} handleClose={() => setModalUpdatePasswordOpen(false)} />
          )}
        </Grid>
      </Grid>
      <Table sx={{minWidth: 650}} aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>Nom</TableCell>
            <TableCell>Email</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users &&
            users.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell align="right">
                  <IconButton
                    color="success"
                    aria-label="update"
                    onClick={() => handleClickOpenUpdatePasswordModal(row)}>
                    <ManageAccountsIcon />
                  </IconButton>
                  <IconButton color="primary" aria-label="update" onClick={() => handleClickOpenUpdateModal(row)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton color="error" aria-label="delete" onClick={() => handleClickOpen(row)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Confirmation de la suppression'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes-vous sûr de vouloir supprimer l&rsquo;utilisateur {user ? user.name : ''} ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>NON</Button>
          <Button onClick={handleDeleteUser} autoFocus>
            OUI
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
}
