import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import ConfigPalletPlansTab from 'src/components/Lot_manager/tabs/ConfigPalletPlansTab';
import Paper from '@mui/material/Paper';
import {useSelector} from 'react-redux';
import {setPlan, fetchConfigPalletPlans} from 'src/store/supplierSlice';
import {RootState, useAppThunkDispatch} from 'src/store';
import {Button} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {CircularProgress} from '@mui/material';
import ModalDetail from 'src/components/Desalting_manager/modals/ModalDetail';
import {useNavigate} from 'react-router-dom';

export default function PlansList() {
  const [modalAddClientOpen, setModalAddClientOpen] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const plans = useSelector((state: RootState) => state.supplier?.currentSupplier?.plans);
  const supplier = useSelector((state: RootState) => state.supplier?.currentSupplier);
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();

  const fetchData = async () => {
    if (supplier && supplier.id != undefined) {
      await dispatch(fetchConfigPalletPlans(supplier.id)).then(() => setFormSubmit(true));
    }
  };
  const cancel = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Grid item xs={12} md={12} lg={12}>
      {modalOpen && <ModalDetail handleClose={cancel} />}
      <Grid container spacing={0} mt={2} mb={2}>
        <Grid item={true} container p={3}>
          <Grid item={true} xs={10}>
            {supplier && (
              <Button
                variant="text"
                className={classes.btn}
                onClick={() => navigate(`/gestion-des-lots/fournisseurs/${supplier.id}/detail`)}>
                &lt; Retour à la liste
              </Button>
            )}
          </Grid>
          <Button variant="contained" className={classes.btn} onClick={() => setModalOpen(true)}>
            Configuration client
          </Button>
        </Grid>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Grid item={true} container p={3}>
          <Grid item={true} xs={12} container justifyContent="flex-end">
            {plans != undefined && supplier ? (
              <Button
                variant="contained"
                className={classes.btn}
                onClick={() => navigate(`/gestion-des-lots/fournisseurs/${supplier.id}/plans/ajouter`)}>
                Ajouter un plan
              </Button>
            ) : (
              ''
            )}
          </Grid>
        </Grid>
        {!formSubmit ? (
          <div className={classes.tabCircular}>
            <CircularProgress />
          </div>
        ) : plans != undefined && supplier ? (
          <ConfigPalletPlansTab
            plans={plans}
            onClick={async (plan) => {
              dispatch(setPlan(plan));
              navigate(`/gestion-des-lots/fournisseurs/${supplier.id}/plans/${plan.id}/modifier`);
            }}
          />
        ) : (
          ''
        )}
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 5,
  },
  icon: {
    marginLeft: 5,
    marginRight: 5,
  },
  tabCircular: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
}));
