import React, {useEffect, useState} from 'react';
import Grid from '@mui/material/Grid';
import ModalUpdateLot from 'src/components/Lot_manager/modals/UpdateLotModal';
import LotDesk from 'src/components/Lot_manager/modeldesks/LotDesk';
import TrayDesk from 'src/components/Lot_manager/modeldesks/TrayDesk';
import PalletDesk from 'src/components/Lot_manager/modeldesks/PalletDesk';
import {updateLot, fetchlotC3, fetchTrays, fetchPallets} from 'src/store/lotSlice';
import AlertDialog from 'src/components/Lot_manager/modals/DeleteLotModal';
import {RootState, useAppThunkDispatch} from 'src/store';
import {makeStyles} from '@mui/styles';
import {Button} from '@mui/material';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Category} from 'src/models/Category';

export default function LotResume() {
  const currentLot = useSelector((state: RootState) => state.lot?.currentLot);
  const trays = useSelector((state: RootState) => state.lot?.trays);
  const pallets = useSelector((state: RootState) => state.lot?.pallets);
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    dispatch(fetchlotC3(currentLot?.id ?? ''));
    dispatch(fetchTrays(currentLot?.id ?? ''));
    dispatch(fetchPallets(currentLot?.id ?? ''));
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseUpdateModal = () => {
    dispatch(fetchlotC3(currentLot?.id ?? ''));
    dispatch(fetchTrays(currentLot?.id ?? ''));
    dispatch(fetchPallets(currentLot?.id ?? ''));
    setModalAddLotOpen(false);
  };

  const [modalAddLotOpen, setModalAddLotOpen] = useState(false);

  const onLotUpdate = (
    id: string,
    name: string,
    slaughter_date: string,
    first_skin: number,
    last_skin: number,
    c3: number[],
    categories?: Category[],
    comment?: string,
    reception_date?: string,
    supplierName?: string,
  ) => {
    dispatch(
      updateLot({
        id,
        name,
        slaughter_date,
        first_skin,
        last_skin,
        c3,
        categories,
        comment,
        reception_date,
        supplierName,
      }),
    )
      .unwrap()
      .then(() => handleCloseUpdateModal());
  };

  return (
    <Grid container spacing={3}>
      <Grid item={true} container spacing={0}>
        <Grid item={true} xs={8}>
          <Button variant="text" className={classes.btn} onClick={() => navigate('/gestion-des-lots/')}>
            &lt; Retour à la liste
          </Button>
        </Grid>
        <Grid item={true} xs={4} container justifyContent="flex-end">
          <AlertDialog lot={currentLot} close={handleClose} />
          <Button variant="contained" className={classes.btn} onClick={() => setModalAddLotOpen(true)}>
            Modifier
          </Button>
          {modalAddLotOpen && currentLot && (
            <ModalUpdateLot handleSubmit={onLotUpdate} lot={currentLot} handleClose={() => setModalAddLotOpen(false)} />
          )}
        </Grid>
      </Grid>
      <Grid item={true} xs={12}>
        {currentLot && trays && pallets && (
          <LotDesk currentLot={currentLot} countTray={trays.length} countPallet={pallets.length} />
        )}
      </Grid>
      <Grid item={true} xs={12}>
        {trays && <TrayDesk trays={trays} />}
      </Grid>
      <Grid item={true} xs={12}>
        {pallets && <PalletDesk pallets={pallets} />}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: 25,
    marginLeft: 25,
  },
  chip: {
    margin: 2,
  },
}));
