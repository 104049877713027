import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import {Category} from 'src/models/Category';
import {axiosInstance} from 'src/security/useAxios';

export interface CategoryState {
  categories: Category[];
}
const initialState: CategoryState = {
  categories: [],
};

export const fetchCategories = createAsyncThunk<Category[]>('categories/fetch', async () => {
  const categories = await axiosInstance.get('/categories', {});

  return categories.data;
});

export const fetchDessalageCategories = createAsyncThunk<Category[]>('dessalage/categories/fetch', async () => {
  const categories = await axiosInstance.get('/dessalage/categories', {});

  return categories.data;
});

export const createCategory = createAsyncThunk<Category, Omit<Category, 'id'>>(
  'category/create',
  async (categoryData) => {
    const newCategory = await axiosInstance.post('/categories/create', {
      name: categoryData.name,
    });

    return newCategory.data;
  },
);

export const updateCategory = createAsyncThunk<Category[], Category>('category/update', async ({id, name}) => {
  const category = await axiosInstance.post(`/categories/${id}/update`, {
    name: name,
  });

  return category.data;
});

export const deleteCategory = createAsyncThunk<Category[], string>('categories/delete', async (categoryId: string) => {
  const categories = await axiosInstance.post(`/categories/${categoryId}/delete`, {});

  return categories.data;
});

export const categorySlice = createSlice({
  name: 'Category',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
    builder.addCase(fetchDessalageCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
    builder.addCase(createCategory.fulfilled, (state, action) => {
      state.categories = [...state.categories, action.payload];
    });
    builder.addCase(updateCategory.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
    builder.addCase(deleteCategory.fulfilled, (state, action) => {
      state.categories = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = categorySlice.actions;

export default categorySlice.reducer;
