import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import DefautColonne from 'src/components/Defect_manager/defautcolonne/DefautColonne';
import ModalPlateau from 'src/components/Defect_manager/modalplateau/ModalPlateau';
import ModalFinBac from 'src/components/Defect_manager/modalfinbac/ModalFinBac';
import {Button} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {RootState, useAppThunkDispatch} from 'src/store';
import {format, parseISO} from 'date-fns';
import skinImage from 'src/assets/images/Skin.png';
import {Defect} from 'src/models/Defect';
import Chip from '@mui/material/Chip';
import {
  checkC3,
  checkC2,
  rollbackDefect,
  toogleSalageGrease,
  toogleSalageGap,
  treaty,
  unCheckC3,
  unCheckC2,
  updateDefect,
} from 'src/store/lotSlice';
import update from 'immutability-helper';
import {Skin} from 'src/models/Skin';
import ModalSaisieInfo from 'src/components/Defect_manager/modalsaisieinfo/ModalSaisieInfo';
import ModalSaisieTray from 'src/components/Defect_manager/modaltraychange/ModalSaisieTray';
import ModalC3 from 'src/components/Defect_manager/modalc3/ModalC3';
import ModalC2 from 'src/components/Defect_manager/modalc2/ModalC2';
import CustomSwitch from 'src/components/Defect_manager/customswitch/CustomSwitch';
import GapSwitchButton from 'src/components/Defect_manager/gapwitchbutton/GapSwitchButton';
import {Container} from '@mui/material';

export default function SkinView(): JSX.Element {
  const {lot, skin} = useParams<{lot: string; skin: string}>();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppThunkDispatch();

  const skins = useSelector<RootState, Skin[] | undefined>((state) => state.lot?.skinLot);
  const currentLot = useSelector((state: RootState) => state.lot.currentLot);
  const tray = useSelector((state: RootState) => state.lot.tray);
  const plateau = useSelector((state: RootState) => state.lot.plateau);
  const bac = useSelector((state: RootState) => state.lot.bac);

  const [showPlateauModal, setShowPlateauModal] = useState(false);
  const [showFinBacModal, setShowFinBacModal] = useState(false);
  const [showModalSaisieInfo, setShowModalSaisieInfo] = useState(false);
  const [showModalSaisieTray, setShowModalSaisieTray] = useState(false);
  const [showModalC3, setShowModalC3] = useState(false);
  const [showModalC2, setShowModalC2] = useState(false);
  const [working, setWorking] = useState(false);

  if (
    lot == undefined ||
    skin == undefined ||
    skins == undefined ||
    currentLot == undefined ||
    tray == undefined ||
    bac == undefined ||
    plateau == undefined
  ) {
    navigate('/salage/');
    return <div></div>;
  }

  const currentSkinIndex = skins.findIndex((s) => s.number === parseInt(skin, 10));
  const currentSkin = currentSkinIndex !== -1 ? skins[currentSkinIndex] : undefined;

  if (currentSkin == undefined) {
    navigate('/salage/');
    return <div></div>;
  }
  const [defectHistory, setDefautHistory] = useState<Defect[]>([currentSkin.defect]);
  const [defectBackHistory, setDefautBackHistory] = useState<Defect[]>([]);

  const nextSkin = currentSkinIndex !== -1 && currentSkinIndex > 0 ? skins[currentSkinIndex - 1] : undefined;
  const prevSkin =
    currentSkinIndex !== -1 && currentSkinIndex < skins.length - 1 ? skins[currentSkinIndex + 1] : undefined;

  const c3list = skins.filter((p) => p.c3);
  const c2list = skins.filter((p) => p.c2);

  const onPrev = () => {
    if (prevSkin) {
      setDefautBackHistory([]);
      setDefautHistory([prevSkin.defect]);
      navigate(`/salage/${lot}/${prevSkin.number}`);
    }
  };
  const onNext = async () => {
    if (nextSkin) {
      setWorking(true);
      setDefautBackHistory([]);
      setDefautHistory([nextSkin.defect]);
      setWorking(false);
      navigate(`/salage/${lot}/${nextSkin.number}`);
    }
  };

  const updateDefaut = (key: keyof Defect, defect: [number, number, number, number]) => {
    const newDefaut = update(currentSkin.defect, {[key]: {$set: defect}});
    setDefautHistory([...defectHistory, newDefaut]);
    setDefautBackHistory([]);
    dispatch(updateDefect({potId: currentSkin.id, key, singleDefect: defect}));
  };

  const updateGap = (key: keyof Defect, defect: [number, number, number, number]) => {
    const newDefaut = update(currentSkin.defect, {[key]: {$set: defect}});
    setDefautHistory([...defectHistory, newDefaut]);
    setDefautBackHistory([]);
    dispatch(updateDefect({potId: currentSkin.id, key, singleDefect: defect}));
    if (currentSkin.defect.back[currentSkin.defect.back.length - 1] == 2) {
      dispatch(toogleSalageGap(currentSkin.id));
    }
  };

  const onCancel = () => {
    if (defectHistory.length > 1) {
      const last = defectHistory.pop();
      if (last !== undefined) {
        setDefautBackHistory([...defectBackHistory, Object.assign({}, last)]);
        setDefautHistory(defectHistory);
        dispatch(
          rollbackDefect({potId: currentSkin.id, defect: Object.assign({}, defectHistory[defectHistory.length - 1])}),
        );
      }
    }
  };
  const onRepete = () => {
    const prev = defectBackHistory.pop();
    if (prev !== undefined) {
      setDefautHistory([...defectHistory, Object.assign({}, prev)]);
      setDefautBackHistory(defectBackHistory);
      dispatch(rollbackDefect({potId: currentSkin.id, defect: Object.assign({}, prev)}));
    }
  };

  const onC3Check = async (c3: Skin, check: boolean) => {
    setWorking(true);
    if (check) {
      await dispatch(checkC3({skinId: c3.id, tray: plateau, bac: bac}));
    } else {
      await dispatch(unCheckC3(c3.id));
    }
    setWorking(false);
  };

  const onC2Check = async (c2: Skin, check: boolean) => {
    setWorking(true);
    if (check) {
      await dispatch(checkC2({skinId: c2.id, tray: plateau, bac: bac}));
    } else {
      await dispatch(unCheckC2(c2.id));
    }
    setWorking(false);
  };

  const saveSkin = async () => {
    setWorking(true);
    await dispatch(treaty({skin: currentSkin, tray, plateau}));
    setWorking(false);
  };

  const finDePlateau = async () => {
    setWorking(true);
    setShowPlateauModal(true);
    setWorking(false);
  };

  const changeTray = async () => {
    setWorking(true);
    setShowModalSaisieTray(true);
    setWorking(false);
  };

  const finDeBac = async () => {
    setWorking(true);
    setShowFinBacModal(true);
    setWorking(false);
  };

  const handleFinLot = () => {
    if (c3list.filter((c) => c.isolate !== true).length > 0) {
      setShowModalC3(true);
    } else if (c2list.filter((c) => c.returned !== true).length > 0) {
      setShowModalC2(true);
    } else {
      navigate(`/salage/${lot}/resume`);
    }
  };

  return (
    <Container className={classes.root}>
      <div className={classes.page}>
        <div className={classes.header}>
          <Button variant="text" onClick={() => navigate('/salage/')}>
            &lt; RETOUR
          </Button>
          <div className={classes.details}>
            <div>
              <div className={classes.blockInfo}>
                <div>Date d’abattage :</div>
                <div>{format(parseISO(currentLot.slaughter_date), 'dd-MM-yy')}</div>
                <div>
                  Plateau en cours:
                  <Chip key={plateau} label={plateau ?? null} color={'success'} />
                </div>
              </div>
            </div>
            <div>
              <div>Température bac {tray.temperature}°C</div>
              <div>
                <Button
                  variant="contained"
                  className={classes.margin}
                  onClick={() => navigate(`/salage/${lot}/list/${currentSkin.number}`)}>
                  DÉTAIL DES PEAUX
                </Button>
              </div>
              <div>
                Bac en cours: <Chip key={bac} label={bac ?? null} color={'primary'} />
              </div>
            </div>
            <div>
              <div className={classes.blockInfo}>
                <div>
                  {currentSkin.trayID ? (
                    <div>
                      N° Plateau de la peau
                      <Chip
                        key={currentSkin.number}
                        label={currentSkin?.tray?.name ?? null}
                        onClick={changeTray}
                        color={'secondary'}
                      />
                    </div>
                  ) : null}
                </div>
                <div>
                  {currentSkin.trayID ? (
                    <div>
                      N° Bac de la peau
                      <Chip key={currentSkin.id} label={currentSkin?.tray?.bac ?? null} color={'primary'} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div>
              <div className={classes.blockInfo}>
                <div>
                  <div>C2 :</div>
                  <div>
                    {c2list.map((c2, index) => (
                      <React.Fragment key={c2.id}>
                        <FormControlLabel
                          control={<Checkbox checked={c2.returned} onChange={(_, b) => onC2Check(c2, b)} />}
                          label={c2.number}
                        />
                        {index !== 0 && index % 2 === 0 && <div className={classes.break} />}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>C3 :</div>
            <div>
              {c3list.map((c3, index) => (
                <React.Fragment key={c3.id}>
                  <FormControlLabel
                    control={<Checkbox checked={c3.isolate} onChange={(_, b) => onC3Check(c3, b)} />}
                    label={c3.number}
                  />
                  {index !== 0 && index % 2 === 0 && <div className={classes.break} />}
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
        {currentSkin.c3 && currentSkin.isolate !== true && (
          <div className={classes.c3Overlay}>
            <p>⚠ ATTENTION PEAU {currentSkin.number} À ISOLER ⚠</p>
          </div>
        )}
        {currentSkin.c2 && (
          <div className={classes.c3Overlay}>
            <p>⚠ ATTENTION PEAU {currentSkin.number} À RENVOYER ⚠</p>
          </div>
        )}
        <div className={`${classes.navigation} ${currentSkin.c3 && currentSkin.isolate !== true ? 'isC3' : ''}`}>
          <Button variant="text" onClick={onPrev} disabled={working}>
            {prevSkin && `< ${prevSkin.number}`}
          </Button>
          <div>
            {' '}
            <Button
              variant="contained"
              color={currentSkin.trayID != null ? 'secondary' : 'success'}
              onClick={() => saveSkin()}
              disabled={working || currentSkin.c2 == true}>
              Enregistrer la Peau N° {currentSkin?.number}
            </Button>
          </div>
          <Button variant="text" onClick={onNext} disabled={working}>
            {nextSkin && `${nextSkin?.number} >`}
          </Button>
        </div>
        <div className={classes.skin} style={{backgroundImage: `url(${skinImage})`}}>
          <DefautColonne
            title="ÉPAULE GAUCHE"
            skinDefect={currentSkin.defect.shoulder_left}
            onUpdate={(defect) => updateDefaut('shoulder_left', defect)}
          />
          <DefautColonne
            title="COLLET"
            skinDefect={currentSkin.defect.neck}
            onUpdate={(defect) => updateDefaut('neck', defect)}
          />
          <DefautColonne
            title="ÉPAULE DROITE"
            skinDefect={currentSkin.defect.shoulder_right}
            onUpdate={(defect) => updateDefaut('shoulder_right', defect)}
          />
          <div className={classes.break} />
          <DefautColonne
            title="CUISSE GAUCHE"
            skinDefect={currentSkin.defect.leg_left}
            onUpdate={(defect) => updateDefaut('leg_left', defect)}
          />
          <DefautColonne
            title="CROUPON"
            skinDefect={currentSkin.defect.back}
            onUpdate={(defect) => updateGap('back', defect)}
            noEchancrure
          />
          <DefautColonne
            title="CUISSE DROITE"
            skinDefect={currentSkin.defect.leg_right}
            onUpdate={(defect) => updateDefaut('leg_right', defect)}
          />
        </div>
        <div className={classes.footer}>
          <div>
            <Button variant="contained" onClick={onCancel} disabled={defectHistory.length === 1}>
              ANNULER
            </Button>
            <Button
              variant="contained"
              onClick={onRepete}
              className={classes.button}
              disabled={defectBackHistory.length === 0}>
              RETABLIR
            </Button>
          </div>
          <div>
            <CustomSwitch
              checked={currentSkin.degrease === false}
              disabled={working}
              onChange={async () => {
                setWorking(true);
                await dispatch(toogleSalageGrease(currentSkin.id));
                setWorking(false);
              }}
            />
          </div>
          <div>
            <GapSwitchButton
              checked={currentSkin.gap === true}
              disabled={working}
              onChange={async () => {
                setWorking(true);
                await dispatch(toogleSalageGap(currentSkin.id));
                setWorking(false);
              }}
            />
          </div>
          <div>
            <Button variant="contained" onClick={finDePlateau} disabled={working || currentSkin.c3 == true}>
              FIN PLATEAU
            </Button>
            {showPlateauModal && (
              <ModalPlateau lot={lot} temperature={tray.temperature} handleClose={() => setShowPlateauModal(false)} />
            )}
            <Button
              variant="contained"
              className={classes.button}
              onClick={finDeBac}
              disabled={working || currentSkin.c3 == true}>
              FIN DU BAC
            </Button>
            {showFinBacModal && (
              <ModalFinBac
                lot={currentLot}
                handleClose={() => setShowFinBacModal(false)}
                handleFinLot={handleFinLot}
                handleNextBac={() => {
                  setShowModalSaisieInfo(true);
                  setShowFinBacModal(false);
                }}
              />
            )}
            {showModalSaisieInfo && (
              <ModalSaisieInfo
                lot={currentLot}
                handleClose={() => {
                  setShowFinBacModal(true);
                  setShowModalSaisieInfo(false);
                }}
                handleNext={(first) => {
                  setShowModalSaisieInfo(false);
                  navigate(`/salage/${lot}/${first}`);
                }}
              />
            )}
            {showModalSaisieTray && (
              <ModalSaisieTray
                lot={currentLot}
                skin={currentSkin}
                handleClose={() => {
                  setShowModalSaisieTray(false);
                }}
              />
            )}
            {showModalC3 && (
              <ModalC3
                c3={c3list}
                handleClose={() => {
                  setShowModalC3(false);
                  setShowFinBacModal(true);
                }}
                handleConfirm={() => {
                  setShowModalC3(false);
                  navigate(`/salage/${lot}/resume`);
                }}
              />
            )}
            {showModalC2 && (
              <ModalC2
                c2={c2list}
                handleClose={() => {
                  setShowModalC2(false);
                  setShowFinBacModal(true);
                }}
                handleConfirm={() => {
                  setShowModalC2(false);
                  navigate(`/salage/${lot}/resume`);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </Container>
  );
}

const useStyles = makeStyles({
  root: {
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
  },
  page: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: -24,
    marginRight: -24,
    paddingTop: 24,
    position: 'relative',
  },
  header: {
    minHeight: 156,
    display: 'flex',
    flexDirection: 'row',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    '& > *': {
      flex: 1,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  blockInfo: {
    width: 150,
  },
  c3Overlay: {
    backgroundColor: 'rgba(240, 20, 46, 0.81)',
    position: 'absolute',
    top: 180,
    height: 'calc(100% - 180px)',
    width: '100%',
    zIndex: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  },
  navigation: {
    padding: '0 20px',
    minHeight: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'solid 1px #707070',
    marginBottom: 30,
    zIndex: 101,
    '&.isC3': {
      color: 'white',
    },
  },
  skin: {
    height: 'calc(100% - 316px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRight: 'solid #707070 1px',
    borderTop: 'solid #707070 1px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: '50%',
  },
  break: {
    height: 0,
    flexBasis: '100%',
  },
  footer: {
    padding: 10,
    minHeight: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  margin: {
    margin: 5,
  },
  button: {
    marginLeft: 10,
  },
});
