import React, {useEffect, useState, ReactNode} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import {getToken} from 'src/client/Auth';

type AuthenticateMiddlewareProps = {
  children?: ReactNode;
};

export default function AuthenticateMiddleware({children}: AuthenticateMiddlewareProps) {
  const navigate = useNavigate();
  const [token, setToken] = useState<string>(getToken());

  useEffect(() => {
    if (token == undefined || token == '' || token == null || token.length <= 0) {
      navigate({pathname: '/login'}, {replace: true});
    }
  }, [token]);

  return token ? <Outlet /> : <Navigate to="/login" />;
}
