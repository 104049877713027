import React, {useState} from 'react';
import {makeStyles} from '@mui/styles';
import {DataGrid, GridColDef, GridRenderCellParams, GridSortModel} from '@mui/x-data-grid';
import {Skin} from 'src/models/Skin';

const defectRenderer = (params: GridRenderCellParams<[number, number, number, number]>) => (
  <>
    <span style={{color: params.row.c3 ? 'white' : '#FF9300'}}>{params.value[0]}</span>
    <span style={{color: params.row.c3 ? 'white' : '#FFE200'}}>{params.value[1]}</span>
    <span style={{color: params.row.c3 ? 'white' : '#EB00FF'}}>{params.value[2]}</span>
    <span style={{color: params.row.c3 ? 'white' : '#0058FF'}}>{params.value[3]}</span>
  </>
);

const columns: GridColDef[] = [
  {
    field: 'number',
    headerName: 'peau',
    flex: 1,
    renderCell: (params) => {
      return (
        <a>
          {params.row.number} {params.row.ref_number_error ? ' (' + params.row.ref_number_error + ')' : null}
        </a>
      );
    },
  },
  {
    field: 'defect.shoulder_left',
    headerName: 'epaule g',
    flex: 1,
    valueGetter: (params) => params.row.defect.shoulder_left,
    renderCell: defectRenderer,
  },
  {
    field: 'defect.neck',
    headerName: 'collet',
    flex: 1,
    valueGetter: (params) => params.row.defect.neck,
    renderCell: defectRenderer,
  },
  {
    field: 'defect.shoulder_right',
    headerName: 'epaule d',
    flex: 1,
    valueGetter: (params) => params.row.defect.shoulder_right,
    renderCell: defectRenderer,
  },
  {
    field: 'defect.leg_left',
    headerName: 'cuisse g',
    flex: 1,
    valueGetter: (params) => params.row.defect.leg_left,
    renderCell: defectRenderer,
  },
  {
    field: 'defect.back',
    headerName: 'croupon',
    flex: 1,
    valueGetter: (params) => params.row.defect.back,
    renderCell: defectRenderer,
  },
  {
    field: 'defect.leg_right',
    headerName: 'cuisse d',
    flex: 1,
    valueGetter: (params) => params.row.defect.leg_right,
    renderCell: defectRenderer,
  },
  {
    field: 'degrease',
    headerName: 'degraisse',
    flex: 1,
    renderCell: (params) =>
      params.value ? 'Oui' : <span style={{color: params.row.isolate ? 'white' : '#F0142F'}}>NON</span>,
  },
  {
    field: 'gap',
    headerName: 'écart',
    flex: 1,
    renderCell: (params) =>
      params.value ? 'Oui' : <span style={{color: params.row.isolate ? 'white' : '#F0142F'}}>NON</span>,
  },
  {
    field: 'plateau',
    headerName: 'plateau',
    flex: 1,
    valueGetter: (params) => (params.row.isolate ? params.row.plateau : params.row.plateau),
  },
];

type SkinListProps = {
  skins: Skin[];
  handleClose: (item: any) => void;
};
export default function SkinList(props: SkinListProps): JSX.Element {
  const {skins, handleClose} = props;
  const classes = useStyles();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'number',
      sort: 'asc',
    },
  ]);

  return (
    <DataGrid
      rows={skins}
      columns={columns}
      getRowId={(row) => row.id}
      pageSize={100}
      onCellClick={(item) => handleClose(item)}
      rowsPerPageOptions={[20]}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      getRowClassName={(params) => (params.row.isolate ? classes.c3 : '' || params.row.c2 ? classes.c2 : '')}
    />
  );
}

const useStyles = makeStyles({
  c3: {
    backgroundColor: '#F0142F',
    color: 'white',
  },
  c2: {
    backgroundColor: '#EB00FF',
    color: 'white',
  },
});
