import {createSlice, PayloadAction, createAsyncThunk} from '@reduxjs/toolkit';
import {Pallet} from 'src/models/Pallet';
import {ConfigPalletPlan} from 'src/models/ConfigPalletPlan';
import {Skin} from 'src/models/Skin';
import {axiosInstance} from 'src/security/useAxios';

export interface CategoryState {
  pallets: Pallet[];
  palletsInProgress?: Pallet[];
  plans?: ConfigPalletPlan[];
  skinPallet?: Skin[];
  skinsList?: Skin[];
  currentSkin?: Skin;
  currentPallet?: Pallet;
  palletName?: string;
}

const initialState: CategoryState = {
  pallets: [],
};

export const fetchPallets = createAsyncThunk<Pallet[]>('pallets/fetch', async () => {
  const pallets = await axiosInstance.get('/pallets', {});

  return pallets.data;
});

export const fetchDesalagePallets = createAsyncThunk<Pallet[]>('dessalage/pallets/fetch', async () => {
  const pallets = await axiosInstance.get('/dessalage/pallets', {});

  return pallets.data;
});

export const fetchDessalageSkins = createAsyncThunk<Skin[], string>('dessalage/pallets/skins/fetch', async (trayId) => {
  const skins = await axiosInstance.get(`/dessalage/pallets/skins/${trayId}`, {});

  return skins.data;
});

export const fetchDessalageCurrentPalletsSkins = createAsyncThunk<Skin[], string>(
  'dessalage/pallets/skinsList/fetch',
  async (supplier) => {
    const skins = await axiosInstance.get(`/dessalage/pallets/supplier/${supplier}/skins-list`, {});

    return skins.data;
  },
);

export const fetchCurrentPalletSkins = createAsyncThunk<Skin[], string>('pallet/skinsList/fetch', async (palletID) => {
  const skins = await axiosInstance.get(`/pallets/${palletID}/skins-list`, {});

  return skins.data;
});

export const fetchPalletSkins = createAsyncThunk<Skin[]>('pallet/skinsInProgressList/fetch', async () => {
  const skins = await axiosInstance.get(`/pallets/skins-list`, {});

  return skins.data;
});

export const storePallet = createAsyncThunk<Pallet[], Pallet>('pallet/store', async (data) => {
  const newPallets = await axiosInstance.post(`/pallets/store`, {
    data,
  });

  return newPallets.data;
});

export const finishedPallet = createAsyncThunk<Pallet[], {pallet: string; ifChecked: boolean}>(
  'pallet/save',
  async ({pallet, ifChecked}) => {
    const pallets = await axiosInstance.post(`/dessalage/pallets/finished/${pallet}`, {
      ifChecked,
    });

    return pallets.data;
  },
);

export const fetchFinishedPallets = createAsyncThunk<Pallet[]>('pallet/fetchFinishedPallets', async () => {
  const pallets = await axiosInstance.get(`/pallets/finished`, {});

  return pallets.data;
});

export const fetchInProgressPallets = createAsyncThunk<Pallet[]>('pallet/fetchInProgressPallets', async () => {
  const pallets = await axiosInstance.get(`/pallets/inProgress`, {});

  return pallets.data;
});

export const deletePallet = createAsyncThunk<Pallet[], string>('pallet/delete', async (palletID) => {
  const pallets = await axiosInstance.post(`/pallets/delete`, {
    palletID,
  });

  return pallets.data;
});

export const switchPallet = createAsyncThunk<Skin[], {skin: string; pallet: string}>(
  'pallet/switchPallet',
  async ({skin, pallet}) => {
    const skins = await axiosInstance.post(`/pallets/switchPallet/${skin}`, {
      skin: skin,
      pallet: pallet,
    });

    return skins.data;
  },
);

export const addSkinToPallet = createAsyncThunk<Pallet[], {palletID: string; trayID: string; currentSkinID: string}>(
  'pallet/addSkin',
  async ({palletID, trayID, currentSkinID}) => {
    const pallet = await axiosInstance.post(`/pallets/add/${trayID}/skin/${currentSkinID}`, {
      palletID,
    });

    return pallet.data;
  },
);

export const saveAllPalletPositions = createAsyncThunk<Pallet[], Pallet[]>(
  'pallet/saveAllPalletPositions',
  async (pallets) => {
    const pallet = await axiosInstance.post(`/pallets/update-positions`, {
      pallets,
    });

    return pallet.data;
  },
);

export const deleteSkinInPallet = createAsyncThunk<Pallet[], {trayID: string; currentSkinID: string}>(
  'pallet/remouveSkin',
  async ({trayID, currentSkinID}) => {
    const pallet = await axiosInstance.post(`/pallets/delete/${trayID}/skin/${currentSkinID}`, {});

    return pallet.data;
  },
);

export const generatePalletName = createAsyncThunk<string, {supplierID: string}>(
  'pallet/generatePalletName',
  async ({supplierID}) => {
    const name = await axiosInstance.get(`/dessalage/pallets/generate-name/${supplierID}`, {});

    return name.data;
  },
);

export const createPallet = createAsyncThunk<Pallet[], string>('pallet/create', async (planID) => {
  const newPallets = await axiosInstance.post(`/pallets/create/${planID}`, null, {});

  return newPallets.data;
});

export const updatePallet = createAsyncThunk<Pallet[], Pallet>('pallet/update', async (data) => {
  const newPallets = await axiosInstance.post(`/pallets/update/${data.id}`, {
    data,
  });

  return newPallets.data;
});

export const fetchConfigPalletPlans = createAsyncThunk<ConfigPalletPlan[]>('/plans/fetch', async () => {
  const plans = await axiosInstance.get(`/plans`, {});

  return plans.data;
});
export const fetchDessalageConfigPalletPlans = createAsyncThunk<ConfigPalletPlan[]>(
  '/dessalage/plans/fetch',
  async () => {
    const plans = await axiosInstance.get(`/dessalage/plans`, {});

    return plans.data;
  },
);

export const palletSlice = createSlice({
  name: 'Pallet',
  initialState,
  reducers: {
    reset: (state) => {
      state.plans = undefined;
      state.skinPallet = undefined;
      state.currentSkin = undefined;
      state.skinsList = undefined;
      state.currentPallet = undefined;
    },
    setSkinToPallet: (state, action: PayloadAction<{index: number; currentSkin: Skin; currentSkinIndex: number}>) => {
      const {index, currentSkin, currentSkinIndex} = action.payload;
      if (state.pallets && state.skinPallet) {
        state.pallets[index].skins?.push(currentSkin);
        state.pallets[index].skinsAmount = state.pallets[index].skinsAmount + 1;
        state.skinPallet[currentSkinIndex].finished = true;
        state.skinPallet[currentSkinIndex].pallet_id = state.pallets[index].id;
      }
    },
    storePalletPosition: (state, action: PayloadAction<{index: number; position_x: number; position_y: number}>) => {
      const {index, position_x, position_y} = action.payload;
      state.pallets[index].position_x = position_x;
      state.pallets[index].position_y = position_y;
    },
    setCurrentSkin: (state, action: PayloadAction<Skin | undefined>) => {
      state.currentSkin = action.payload;
    },
    refreshPallet: (state) => {
      state.pallets = state.pallets;
    },
    setPallet: (state, action: PayloadAction<Pallet | undefined>) => {
      state.currentPallet = action.payload;
    },
    removeSkinToPallet: (
      state,
      action: PayloadAction<{index: number; currentSkin: Skin; currentSkinIndex: number}>,
    ) => {
      const {index, currentSkin, currentSkinIndex} = action.payload;
      if (state.pallets && state.skinPallet) {
        const skin = state.pallets[index].skins?.findIndex(function (skin) {
          return skin.number == currentSkin?.number;
        });

        if (skin != undefined) {
          state.pallets[index].skins?.splice(skin, 1);
          state.pallets[index].skinsAmount = state.pallets[index].skinsAmount - 1;
          state.skinPallet[currentSkinIndex].finished = false;
          state.skinPallet[currentSkinIndex].pallet_id = undefined;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPallets.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(fetchDesalagePallets.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(fetchConfigPalletPlans.fulfilled, (state, action) => {
      state.plans = action.payload;
    });
    builder.addCase(fetchDessalageConfigPalletPlans.fulfilled, (state, action) => {
      state.plans = action.payload;
    });
    builder.addCase(fetchDessalageSkins.fulfilled, (state, action) => {
      state.skinPallet = action.payload;
    });
    builder.addCase(createPallet.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(storePallet.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(updatePallet.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(addSkinToPallet.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(finishedPallet.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(fetchFinishedPallets.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(fetchInProgressPallets.fulfilled, (state, action) => {
      state.palletsInProgress = action.payload;
    });
    builder.addCase(deleteSkinInPallet.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(deletePallet.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(saveAllPalletPositions.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(fetchDessalageCurrentPalletsSkins.fulfilled, (state, action) => {
      state.skinsList = action.payload;
    });
    builder.addCase(fetchCurrentPalletSkins.fulfilled, (state, action) => {
      state.skinsList = action.payload;
    });
    builder.addCase(fetchPalletSkins.fulfilled, (state, action) => {
      state.skinsList = action.payload;
    });
    builder.addCase(switchPallet.fulfilled, (state, action) => {
      state.skinsList = action.payload;
    });
    builder.addCase(generatePalletName.fulfilled, (state, action) => {
      state.palletName = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  reset,
  setSkinToPallet,
  setPallet,
  removeSkinToPallet,
  setCurrentSkin,
  refreshPallet,
  storePalletPosition,
} = palletSlice.actions;

export default palletSlice.reducer;
