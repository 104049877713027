import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from '@mui/material';
import {createSalageTray} from 'src/store/lotSlice';
import {useSelector} from 'react-redux';
import {useAppThunkDispatch, RootState} from 'src/store';
import NumberTextField from '../numbertextfield/NumberTextField';
import {setPlateau, setBac} from 'src/store/lotSlice';

type ModalPlateauInfoProps = {
  lot: string;
  temperature: number | undefined;
  handleClose: () => void;
};

export default function ModalPlateauInfo(props: ModalPlateauInfoProps): JSX.Element {
  const {lot, temperature, handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const currentBac = useSelector((state: RootState) => state.lot.bac);
  const plateau = useSelector((state: RootState) => state.lot.plateau ?? '');
  const [newTray, setNewTray] = useState<string | undefined>();
  const [trayError, setTrayError] = useState<string | undefined>();
  const skinsLot = useSelector((state: RootState) => state.lot.skinLot);
  const countSkin = useSelector((state: RootState) => state.lot.amountSkinPateau);
  const [nombrePeau, setNombrePeau] = useState<number | undefined>(countSkin);
  const [bacInput, setBacInput] = useState<string>(currentBac ?? '');
  const [bacError, setBacError] = useState<string | undefined>();

  useEffect(() => {
    let count = 0;
    if (skinsLot) {
      skinsLot.forEach((skin) => {
        if (skin.plateau == plateau) {
          count++;
        }
      });
    }
    setNombrePeau(count);
  }, [countSkin]);

  const onSubmit = () => {
    let hasError = false;

    if (newTray == undefined) {
      setTrayError('Obligatoire');
      hasError = true;
    }

    if (bacInput == undefined || bacInput == '') {
      setBacError('Obligatoire');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    dispatch(
      createSalageTray({lot: lot, temperature: temperature, first_skin: undefined, name: newTray, bac: bacInput}),
    );
    dispatch(setPlateau(newTray));
    dispatch(setBac(bacInput));
    handleClose();
  };

  return (
    <Dialog open>
      <DialogTitle>FIN DU PLATEAU</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Identifiant du plateau en cours"
          type="text"
          fullWidth
          variant="standard"
          value={plateau}
          disabled
        />
        <NumberTextField
          margin="dense"
          label="Nombre de peau dans le plateau"
          type="number"
          fullWidth
          variant="standard"
          value={nombrePeau == 0 ? 0 : nombrePeau}
          disabled
        />
        <TextField
          margin="dense"
          label="Identifiant du nouveau plateau"
          type="text"
          fullWidth
          error={trayError !== undefined}
          helperText={trayError}
          variant="standard"
          value={newTray}
          onChange={(e) => setNewTray(e.target.value.toUpperCase())}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
