import axios from 'axios';
import {logout, getToken} from 'src/client/Auth';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

axiosInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!window.navigator.onLine && !error.response && error.code === 'ERR_NETWORK') {
      localStorage.setItem('error', JSON.stringify('Pas de connexion Internet'));
    }
    if (error.response) {
      if (error.response.data.data.code === 401) {
        logout();
        window.location.href = '/login';
      }
      if (error.response.data.data.code === 419) {
        logout();
        window.location.href = '/login';
      }
    } else if (error.request) {
      console.error('Aucune réponse reçue:', error.request);
      return Promise.reject('Aucune réponse reçue');
    } else {
      console.error('Erreur lors de la configuration de la requête:', error.message);
      return Promise.reject(error.message);
    }
  },
);
