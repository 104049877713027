import {createSlice, createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {Tray} from 'src/models/Tray';
import {Lot} from 'src/models/Lot';
import {Skin} from 'src/models/Skin';
import {Defect} from 'src/models/Defect';
import {Pallet} from 'src/models/Pallet';
import {axiosInstance} from 'src/security/useAxios';

export interface LotsState {
  lots: Lot[];
  currentLot?: Lot;
  skinLot?: Skin[];
  tray?: Tray;
  trays?: Tray[];
  pallets?: Pallet[];
  plateau?: string;
  bac?: string;
  amountSkinPateau?: number;
}
const initialState: LotsState = {
  lots: [],
};

export const fetchLots = createAsyncThunk<Lot[], void>('lots/fetch', async (): Promise<Lot[]> => {
  const lots = await axiosInstance.get('/lots', {});

  return lots.data;
});
export const fetchSalageLots = createAsyncThunk<Lot[], void>('salage/lots/fetch', async (): Promise<Lot[]> => {
  const lots = await axiosInstance.get('/salage/lots', {});

  return lots.data;
});

export const createLot = createAsyncThunk<
  Lot,
  Omit<Lot, 'id' | 'count_sort' | 'c3' | 'supplier'> & {
    c3: number[];
    supplierName?: string | number;
    reception_date?: string | number;
  }
>('lots/create', async (lotData) => {
  const newlot = await axiosInstance.post('/lots/create', {
    name: lotData.name,
    supplier: lotData.supplierName,
    slaughter_date: lotData.slaughter_date,
    first_skin: lotData.first_skin,
    last_skin: lotData.last_skin,
    c3: lotData.c3,
    reception_date: lotData.reception_date,
    categories: lotData.categories,
    comment: lotData.comment,
  });

  return newlot.data;
});

export const createSalageLot = createAsyncThunk<
  Lot,
  Omit<Lot, 'id' | 'count_sort' | 'c3' | 'c2' | 'supplier'> & {
    c3: number[];
    c2: number[];
    supplierName?: string | number;
    reception_date?: string | number;
  }
>('salage/lots/create', async (lotData) => {
  const newlot = await axiosInstance.post('/salage/lots/create', {
    name: lotData.name,
    supplier: lotData.supplierName,
    slaughter_date: lotData.slaughter_date,
    first_skin: lotData.first_skin,
    last_skin: lotData.last_skin,
    c3: lotData.c3,
    c2: lotData.c2,
    reception_date: lotData.reception_date,
    categories: lotData.categories,
    comment: lotData.comment,
  });

  return newlot.data;
});

export const updateLot = createAsyncThunk<
  Lot,
  Omit<Lot, 'count_sort' | 'c3' | 'supplier'> & {
    c3: number[];
    supplierName?: string | number;
    reception_date?: string | number;
  }
>('lots/update', async (lotData) => {
  const lot = await axiosInstance.post(`/lots/${lotData.id}/update`, {
    name: lotData.name,
    supplier: lotData.supplierName,
    slaughter_date: lotData.slaughter_date,
    first_skin: lotData.first_skin,
    last_skin: lotData.last_skin,
    c3: lotData.c3,
    reception_date: lotData.reception_date,
    categories: lotData.categories,
    comment: lotData.comment,
  });

  return lot.data;
});

export const skinUpdate = createAsyncThunk<Skin[], Skin>('lots/skin/update', async (skin: Skin) => {
  const skins = await axiosInstance.post(`/skin/${skin.id}/update`, {
    shoulder_left: skin.defect.shoulder_left.join(','),
    neck: skin.defect.neck.join(','),
    shoulder_right: skin.defect.shoulder_right.join(','),
    leg_left: skin.defect.leg_left.join(','),
    back: skin.defect.back.join(','),
    leg_right: skin.defect.leg_right.join(','),
  });

  return skins.data;
});

export const skinSalageUpdate = createAsyncThunk<Skin[], Skin>('salage/lots/skin/update', async (skin: Skin) => {
  const skins = await axiosInstance.post(`/salage/skin/${skin.id}/update`, {
    shoulder_left: skin.defect.shoulder_left.join(','),
    neck: skin.defect.neck.join(','),
    shoulder_right: skin.defect.shoulder_right.join(','),
    leg_left: skin.defect.leg_left.join(','),
    back: skin.defect.back.join(','),
    leg_right: skin.defect.leg_right.join(','),
  });

  return skins.data;
});

export const fetchlotC3 = createAsyncThunk<[string, Skin[]], string>('lots/skin/c3', async (lotId: string) => {
  const c3 = await axiosInstance.get(`/lots/${lotId}/c3`, {});

  return [lotId, c3.data];
});

export const lotDelete = createAsyncThunk<Lot[], string>('lots/skin/delete', async (skinId: string) => {
  const lots = await axiosInstance.post(`/lots/${skinId}/delete`, {});

  return lots.data;
});

export const lotSalageDelete = createAsyncThunk<Lot[], string>('salage/lots/skin/delete', async (skinId: string) => {
  const lots = await axiosInstance.post(`/salage/lots/${skinId}/delete`, {});

  return lots.data;
});

export const traySalageDelete = createAsyncThunk<Tray[], string>('/salage/tray/delete', async (tray: string) => {
  const trays = await axiosInstance.post(`/salage/tray/${tray}/delete`, {});

  return trays.data;
});

export const fetchSkin = createAsyncThunk<[string, Skin[]], string>('lots/skins/fetch', async (lotId: string) => {
  const skins = await axiosInstance.get(`/lots/${lotId}/skins`, {});

  return [lotId, skins.data];
});

export const fetchSalageSkin = createAsyncThunk<[string, Skin[]], string>(
  'salage/lots/skins/fetch',
  async (lotId: string) => {
    const skins = await axiosInstance.get(`/salage/lots/${lotId}/skins`, {});

    return [lotId, skins.data];
  },
);
export const fetchSalageSkinPrint = createAsyncThunk<[string, Skin[]], string>(
  'salage/lots/skins/print/fetch',
  async (lotId: string) => {
    const skins = await axiosInstance.get(`/salage/lots/${lotId}/skins/print`, {});

    return [lotId, skins.data];
  },
);

export const fetchTrays = createAsyncThunk<Tray[], string>('lots/trays/fetch', async (lot: string) => {
  const trays = await axiosInstance.get(`/lots/${lot}/trays`, {});

  return trays.data;
});

export const fetchLotTrays = createAsyncThunk<Tray[], string>('lots/trays/all', async (lot: string) => {
  const trays = await axiosInstance.get(`/salage/lots/${lot}/trays`, {});

  return trays.data;
});

export const createSalageTray = createAsyncThunk<
  Tray,
  {
    lot: string;
    temperature: number | undefined;
    first_skin: number | undefined;
    name: string | undefined;
    bac: string | undefined;
  }
>('lots/trays/create', async ({lot, temperature, first_skin, name, bac}) => {
  const newTray = await axiosInstance.post(`/salage/lots/${lot}/trays/create`, {
    temperature: temperature,
    first_skin: first_skin,
    name: name,
    bac: bac,
  });

  return newTray.data;
});

export const fetchPallets = createAsyncThunk<Pallet[], string>('lots/pallets/fetch', async (lot: string) => {
  const pallets = await axiosInstance.get(`/lots/${lot}/pallets`, {});

  return pallets.data;
});

export const toogleGrease = createAsyncThunk<Skin, string>('lots/skin/degreases', async (skinId: string) => {
  const skin = await axiosInstance.post(`/skin/${skinId}/degreases`, {});

  return skin.data;
});

export const toogleGap = createAsyncThunk<Skin, string>('lots/skin/gap', async (skinId: string) => {
  const skin = await axiosInstance.post(`/skin/${skinId}/gap`, {});

  return skin.data;
});

export const toogleSalageGrease = createAsyncThunk<Skin, string>(
  'salage/lots/skin/degreases',
  async (skinId: string) => {
    const skin = await axiosInstance.post(`/salage/skin/${skinId}/degreases`, {});

    return skin.data;
  },
);
export const changeSalageSkinNumber = createAsyncThunk<Skin[], {skin: Skin; number: string}>(
  'lots/skin/change/number',
  async ({skin, number}) => {
    const skins = await axiosInstance.post(`/salage/skin/${skin.id}/change-number`, {
      number: number,
    });

    return skins.data;
  },
);

export const switchC2 = createAsyncThunk<Skin, string>('lots/skin/switchC2', async (skinId: string) => {
  const skin = await axiosInstance.post(`/salage/skin/${skinId}/switchC2`, {});

  return skin.data;
});

export const toogleSalageGap = createAsyncThunk<Skin, string>('salage/lots/skin/gap', async (skinId: string) => {
  const skin = await axiosInstance.post(`/salage/skin/${skinId}/gap`, {});

  return skin.data;
});

export const checkC3 = createAsyncThunk<Skin, {skinId: string; tray: string; bac: string}>(
  'lots/skin/check',
  async ({skinId, tray, bac}) => {
    const skin = await axiosInstance.post(`/salage/skin/${skinId}/check`, {
      plateau: tray,
      bac: bac,
    });

    return skin.data;
  },
);
export const checkC2 = createAsyncThunk<Skin, {skinId: string; tray: string; bac: string}>(
  'lots/skin/checkC2',
  async ({skinId, tray, bac}) => {
    const skin = await axiosInstance.post(`/salage/skin/${skinId}/check-c2`, {
      plateau: tray,
      bac: bac,
    });

    return skin.data;
  },
);

export const unCheckC3 = createAsyncThunk<Skin, string>('lots/skin/uncheck', async (skinId: string) => {
  const skin = await axiosInstance.post(`/salage/skin/${skinId}/uncheck`, {});

  return skin.data;
});

export const unCheckC2 = createAsyncThunk<Skin, string>('lots/skin/uncheck-c2', async (skinId: string) => {
  const skin = await axiosInstance.post(`/salage/skin/${skinId}/uncheck-c2`, {});

  return skin.data;
});

export const treaty = createAsyncThunk<Skin[], {skin: Skin; tray: Tray; plateau: string}>(
  'lots/skin/treat',
  async ({skin, tray, plateau}) => {
    const newSkin = await axiosInstance.post(`/salage/skin/${skin.id}/treat`, {
      tray_id: tray.id,
      skin_tray_id: skin.trayID ?? null,
      tray_name: plateau,
      first_skin: tray.first_skin,
      temperatureTray: tray.temperature,
      shoulder_left: skin.defect.shoulder_left.join(','),
      neck: skin.defect.neck.join(','),
      shoulder_right: skin.defect.shoulder_right.join(','),
      leg_left: skin.defect.leg_left.join(','),
      back: skin.defect.back.join(','),
      leg_right: skin.defect.leg_right.join(','),
    });

    return newSkin.data;
  },
);
export const changeSalageTray = createAsyncThunk<Skin[], {skin: string; tray: string}>(
  'lots/tray/change',
  async ({skin, tray}) => {
    const newSkin = await axiosInstance.post(`/salage/skin/${skin}/tray/${tray}/change`, {});

    return newSkin.data;
  },
);

export const lotsSlice = createSlice({
  name: 'lot',
  initialState,
  reducers: {
    reset: (state) => {
      state.currentLot = undefined;
      state.skinLot = undefined;
      state.tray = undefined;
      state.plateau = undefined;
      state.bac = undefined;
      state.amountSkinPateau = undefined;
    },
    setLot: (state, action: PayloadAction<Lot | undefined>) => {
      state.currentLot = action.payload;
    },
    setPlateau: (state, action: PayloadAction<string | undefined>) => {
      state.plateau = action.payload;
      state.amountSkinPateau = 0;
    },
    setBac: (state, action: PayloadAction<string | undefined>) => {
      state.bac = action.payload;
    },
    updateDefect: (
      state,
      action: PayloadAction<{potId: string; key: keyof Defect; singleDefect: [number, number, number, number]}>,
    ) => {
      const {potId, key, singleDefect} = action.payload;
      if (state.skinLot) {
        const skinIndex = state.skinLot.findIndex((p) => p.id === potId);
        if (skinIndex !== -1) {
          state.skinLot[skinIndex].defect[key] = singleDefect;
        }
      }
    },
    rollbackDefect: (state, action: PayloadAction<{potId: string; defect: Defect}>) => {
      const {potId, defect} = action.payload;

      if (state.skinLot) {
        const skinIndex = state.skinLot.findIndex((p) => p.id === potId);
        if (skinIndex !== -1) {
          state.skinLot[skinIndex].defect = defect;
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLots.fulfilled, (state, action) => {
      state.lots = action.payload;
      state.currentLot = undefined;
      state.bac = undefined;
      state.skinLot = undefined;
      state.tray = undefined;
      state.plateau = undefined;
    });
    builder.addCase(fetchSalageLots.fulfilled, (state, action) => {
      state.lots = action.payload;
      state.currentLot = undefined;
      state.bac = undefined;
      state.skinLot = undefined;
      state.tray = undefined;
      state.plateau = undefined;
    });
    builder.addCase(lotDelete.fulfilled, (state, action) => {
      state.lots = action.payload;
      state.currentLot = undefined;
      state.skinLot = undefined;
      state.tray = undefined;
      state.plateau = undefined;
      state.bac = undefined;
    });
    builder.addCase(lotSalageDelete.fulfilled, (state, action) => {
      state.lots = action.payload;
      state.currentLot = undefined;
      state.skinLot = undefined;
      state.tray = undefined;
      state.plateau = undefined;
      state.bac = undefined;
    });
    builder.addCase(createLot.fulfilled, (state, action) => {
      state.lots = [...state.lots, action.payload];
    });
    builder.addCase(createSalageLot.fulfilled, (state, action) => {
      state.lots = [...state.lots, action.payload];
    });
    builder.addCase(fetchSkin.fulfilled, (state, action) => {
      state.currentLot = state.lots.find((l) => l.id == action.payload[0]);
      state.skinLot = action.payload[1];
    });
    builder.addCase(fetchSalageSkin.fulfilled, (state, action) => {
      state.currentLot = state.lots.find((l) => l.id == action.payload[0]);
      state.skinLot = action.payload[1];
    });
    builder.addCase(fetchSalageSkinPrint.fulfilled, (state, action) => {
      state.currentLot = state.lots.find((l) => l.id == action.payload[0]);
      state.skinLot = action.payload[1];
    });
    builder.addCase(skinUpdate.fulfilled, (state, action) => {
      state.skinLot = action.payload;
    });
    builder.addCase(treaty.fulfilled, (state, action) => {
      state.skinLot = action.payload;
    });
    builder.addCase(changeSalageTray.fulfilled, (state, action) => {
      state.skinLot = action.payload;
    });
    builder.addCase(skinSalageUpdate.fulfilled, (state, action) => {
      state.skinLot = action.payload;
    });
    builder.addCase(createSalageTray.fulfilled, (state, action) => {
      state.tray = action.payload;
    });
    builder.addCase(updateLot.fulfilled, (state, action) => {
      state.currentLot = action.payload;
    });
    builder.addCase(fetchlotC3.fulfilled, (state, action) => {
      state.currentLot ? (state.currentLot.c3 = action.payload[1]) : null;
    });
    builder.addCase(fetchTrays.fulfilled, (state, action) => {
      state.trays = action.payload;
    });
    builder.addCase(fetchLotTrays.fulfilled, (state, action) => {
      state.trays = action.payload;
    });
    builder.addCase(traySalageDelete.fulfilled, (state, action) => {
      state.trays = action.payload;
    });
    builder.addCase(fetchPallets.fulfilled, (state, action) => {
      state.pallets = action.payload;
    });
    builder.addCase(checkC3.fulfilled, (state, action) => {
      if (state.skinLot === undefined) {
        return;
      }
      const skinIndex = state.skinLot.findIndex((p) => p.id === action.payload.id);
      if (skinIndex !== -1) {
        state.skinLot[skinIndex].isolate = action.payload.isolate;
      }
    });
    builder.addCase(checkC2.fulfilled, (state, action) => {
      if (state.skinLot === undefined) {
        return;
      }
      const skinIndex = state.skinLot.findIndex((p) => p.id === action.payload.id);
      if (skinIndex !== -1) {
        state.skinLot[skinIndex].returned = action.payload.returned;
      }
    });
    builder.addCase(unCheckC3.fulfilled, (state, action) => {
      if (state.skinLot === undefined) {
        return;
      }
      const skinIndex = state.skinLot.findIndex((p) => p.id === action.payload.id);
      if (skinIndex !== -1) {
        state.skinLot[skinIndex].isolate = action.payload.isolate;
      }
    });
    builder.addCase(unCheckC2.fulfilled, (state, action) => {
      if (state.skinLot === undefined) {
        return;
      }
      const skinIndex = state.skinLot.findIndex((p) => p.id === action.payload.id);
      if (skinIndex !== -1) {
        state.skinLot[skinIndex].returned = action.payload.returned;
      }
    });
    builder.addCase(toogleGrease.fulfilled, (state, action) => {
      if (state.skinLot === undefined) {
        return;
      }
      const skinIndex = state.skinLot.findIndex((p) => p.id === action.payload.id);
      if (skinIndex !== -1) {
        state.skinLot[skinIndex].degrease = action.payload.degrease;
      }
    });
    builder.addCase(toogleGap.fulfilled, (state, action) => {
      if (state.skinLot === undefined) {
        return;
      }
      const skinIndex = state.skinLot.findIndex((p) => p.id === action.payload.id);
      if (skinIndex !== -1) {
        state.skinLot[skinIndex].gap = action.payload.gap;
      }
    });
    builder.addCase(toogleSalageGap.fulfilled, (state, action) => {
      if (state.skinLot === undefined) {
        return;
      }
      const skinIndex = state.skinLot.findIndex((p) => p.id === action.payload.id);
      if (skinIndex !== -1) {
        state.skinLot[skinIndex].gap = action.payload.gap;
      }
    });
    builder.addCase(toogleSalageGrease.fulfilled, (state, action) => {
      if (state.skinLot === undefined) {
        return;
      }
      const skinIndex = state.skinLot.findIndex((p) => p.id === action.payload.id);
      if (skinIndex !== -1) {
        state.skinLot[skinIndex].degrease = action.payload.degrease;
      }
    });
    builder.addCase(switchC2.fulfilled, (state, action) => {
      if (state.skinLot === undefined) {
        return;
      }
      const skinIndex = state.skinLot.findIndex((p) => p.id === action.payload.id);
      if (skinIndex !== -1) {
        state.skinLot[skinIndex].c2 = action.payload.c2;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {setLot, setPlateau, setBac, reset, updateDefect, rollbackDefect} = lotsSlice.actions;

export default lotsSlice.reducer;
