import React from 'react';
import {Routes, Route} from 'react-router-dom';
import Home from '../views/Desalting_manager/Home';
import SkinsList from '../views/Desalting_manager/SkinsList';
import EditPlan from '../views/Desalting_manager/EditPlan';
import Detail from '../views/Desalting_manager/Detail';

export default function DesaltingManagerRouter(): JSX.Element {
  return (
    <Routes>
      <Route path="" element={<Home />} />
      <Route path="plateau/:tray">
        <Route path="peaux/:skin">
          <Route path="" element={<SkinsList />} />
        </Route>
      </Route>
      <Route path="editer" element={<EditPlan />} />
      <Route path="detail" element={<Detail />} />
    </Routes>
  );
}
