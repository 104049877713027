import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, {AppBarProps as MuiAppBarProps} from '@mui/material/AppBar';
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import PeopleIcon from '@mui/icons-material/People';
import LayersIcon from '@mui/icons-material/Layers';
import {disconnect} from 'src/store/userSlice';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'src/store';
import {logout} from 'src/client/Auth';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({theme, open}) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Navbar() {
  const [open, setOpen] = React.useState(false);
  const [anchorElUser, setAnchorElUser] = useState<Element | null>(null);
  const currentUser = useSelector((state: RootState) => state.user?.currentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOpenUserMenu: React.MouseEventHandler = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  useEffect(() => {
    if (window.location.pathname.includes('/gestion-des-lots/')) {
      setSelectedIndex(1);
    }
    if (window.location.pathname.includes('/gestion-des-lots/palettes')) {
      setSelectedIndex(2);
    }
    if (window.location.pathname.includes('/gestion-des-lots/fournisseurs')) {
      setSelectedIndex(3);
    }
    if (window.location.pathname.includes('/gestion-des-lots/parametres')) {
      setSelectedIndex(4);
    }
  }, []);

  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const logoutUser = () => {
    logout();
    dispatch(disconnect());
    navigate(`/`);
  };

  const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(({theme, open}) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  return (
    <div>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px', // keep right padding when drawer closed
          }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && {display: 'none'}),
            }}>
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{flexGrow: 1}}>
            Touraine Cuirs
          </Typography>
          <Link to="/" style={{textDecoration: 'none', marginRight: 20, color: 'white'}}>
            Quitter
          </Link>
          <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
            <Avatar alt={currentUser?.name} src={currentUser?.image_url ?? ''} />
          </IconButton>
        </Toolbar>
        <Menu
          sx={{mt: '45px'}}
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}>
          <MenuItem onClick={logoutUser}>
            <Typography textAlign="center">Deconnexion</Typography>
          </MenuItem>
        </Menu>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          <ListItemButton
            component={Link}
            to="/gestion-des-lots/"
            selected={selectedIndex === 1}
            onClick={() => handleListItemClick(1)}>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Lots" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/gestion-des-lots/palettes"
            selected={selectedIndex === 2}
            onClick={() => handleListItemClick(2)}>
            <ListItemIcon>
              <ViewAgendaIcon />
            </ListItemIcon>
            <ListItemText primary="Palettes" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/gestion-des-lots/fournisseurs"
            selected={selectedIndex === 3}
            onClick={() => handleListItemClick(3)}>
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Fournisseurs" />
          </ListItemButton>
          <ListItemButton
            component={Link}
            to="/gestion-des-lots/parametres"
            selected={selectedIndex === 4}
            onClick={() => handleListItemClick(4)}>
            <ListItemIcon>
              <LayersIcon />
            </ListItemIcon>
            <ListItemText primary="Paramètres" />
          </ListItemButton>
        </List>
      </Drawer>
    </div>
  );
}
