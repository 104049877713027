import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {RootState, useAppThunkDispatch} from 'src/store';
import {updateSupplier} from 'src/store/supplierSlice';
import {Supplier} from 'src/models/Supplier';

type ModalUpdateSupplierProps = {
  supplierStat: Supplier;
  handleClose: () => void;
};

export default function UpdateSupplierModal(props: ModalUpdateSupplierProps): JSX.Element {
  const {supplierStat, handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const [supplierName, setName] = useState(supplierStat.name);
  const [prefix, setPrefix] = useState(supplierStat.prefix);
  const [nameError, setNameError] = useState('');
  const [prefixError, setPrefixError] = useState('');

  const onSupplierUpdate = (id: string, name: string, prefix: string) => {
    dispatch(
      updateSupplier({
        id,
        name,
        prefix,
      }),
    )
      .unwrap()
      .then(() => handleClose);
  };

  const onSubmit = () => {
    let hasError = false;

    if (supplierName.length == 0) {
      setNameError('Le nom est obligatoire !');
      hasError = true;
    }

    if (prefix && prefix.length == 0) {
      setPrefixError('Le prefix est obligatoire !');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (supplierStat.id && prefix) {
      onSupplierUpdate(supplierStat.id, supplierName, prefix);
    }

    handleClose();
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Modifier le fournisseur</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nom :"
          type="text"
          fullWidth
          variant="standard"
          value={supplierName}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={nameError.length != 0}
          helperText={nameError}
        />
        <TextField
          margin="dense"
          label="Prefix :"
          type="text"
          fullWidth
          variant="standard"
          value={prefix}
          onChange={(e) => setPrefix(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={prefixError.length != 0}
          helperText={prefixError}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Modifier
        </Button>
      </DialogActions>
    </Dialog>
  );
}
