import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DefautColonne from 'src/components/Defect_manager/defautcolonne/DefautColonne';
import skinImage from 'src/assets/images/Skin.png';
import {makeStyles} from '@mui/styles';
import {Skin} from 'src/models/Skin';

type DetailSkinModalProps = {
  currentSkin: Skin;
  handleClose: () => void;
};

export default function DetailSkinModal(props: DetailSkinModalProps): JSX.Element {
  const {currentSkin, handleClose} = props;
  const classes = useStyles();

  return (
    <Dialog open fullWidth maxWidth={'lg'}>
      <DialogContent className={classes.modal}></DialogContent>
      <div className={classes.skin} style={{backgroundImage: `url(${skinImage})`}}>
        <DefautColonne title="ÉPAULE GAUCHE" skinDefect={currentSkin.defect.shoulder_left} />
        <DefautColonne title="COLLET" skinDefect={currentSkin.defect.neck} />
        <DefautColonne title="ÉPAULE DROITE" skinDefect={currentSkin.defect.shoulder_right} />
        <div className={classes.break} />
        <DefautColonne title="CUISSE GAUCHE" skinDefect={currentSkin.defect.leg_left} />
        <DefautColonne title="CROUPON" skinDefect={currentSkin.defect.back} noEchancrure />
        <DefautColonne title="CUISSE DROITE" skinDefect={currentSkin.defect.leg_right} />
      </div>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles({
  page: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: -24,
    marginRight: -24,
    paddingTop: 24,
    position: 'relative',
  },
  header: {
    minHeight: 156,
    display: 'flex',
    flexDirection: 'row',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    '& > *': {
      flex: 1,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  },

  skin: {
    height: 'calc(100% - 316px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRight: 'solid #707070 1px',
    borderTop: 'solid #707070 1px',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: '50%',
    backgroundPositionY: '50%',
  },
  break: {
    height: 0,
    flexBasis: '100%',
  },
  modal: {
    padding: 0,
  },
  footer: {
    padding: 10,
    minHeight: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: 10,
  },
});
