import React, {useEffect} from 'react';
import {makeStyles} from '@mui/styles';
import {Button} from '@mui/material';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import {RootState, useAppThunkDispatch} from 'src/store';
import {useNavigate, useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {fetchSkin} from 'src/store/lotSlice';
import SkinList from 'src/components/Defect_manager/skinlist/SkinList';
import {Container} from '@mui/material';
import ModalSkinUpdate from 'src/components/Lot_manager/modals/UpdateSkinModal';
import {Skin} from 'src/models/Skin';

export default function SkinListView(): JSX.Element {
  const {lot} = useParams<{lot: string}>();
  const classes = useStyles();
  const navigate = useNavigate();

  const dispatch = useAppThunkDispatch();

  const currentLot = useSelector((state: RootState) => state.lot.currentLot);
  const skins = useSelector((state: RootState) => state.lot.skinLot);
  const [skin, setSkin] = React.useState<Skin>();
  const [showModalSkinUpdate, setShowModalSkinUpdate] = React.useState(false);

  function addSkin(item: Skin) {
    setSkin(item);
    setShowModalSkinUpdate(true);
  }

  useEffect(() => {
    if (lot !== undefined) {
      dispatch(fetchSkin(lot));
    }
  }, [lot]);

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Grid item={true} container spacing={0}>
        <Grid item={true} xs={8}>
          {currentLot && (
            <Button
              variant="text"
              className={classes.btn}
              onClick={() => navigate(`/gestion-des-lots/${currentLot.id}/detail`)}>
              &lt; Retour à la liste
            </Button>
          )}
        </Grid>
      </Grid>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Container className={classes.root}>
          <div className={classes.page}>
            {skins && <SkinList skins={skins} handleClose={(item) => addSkin(item)} />}
          </div>
        </Container>
        {showModalSkinUpdate && skin && (
          <ModalSkinUpdate handleClose={() => setShowModalSkinUpdate(false)} skin={skin} />
        )}
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles({
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100vh',
  },
  page: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    marginLeft: -24,
    marginRight: -24,
    paddingTop: 24,
    position: 'relative',
  },
  header: {
    minHeight: 68,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: 'solid 1px',
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    '& > *': {
      flex: 1,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  blockInfo: {
    width: 150,
  },
  navigation: {
    padding: '0 20px',
    minHeight: 60,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 101,
    '&.isC3': {
      color: 'white',
    },
  },
  skin: {
    height: 'calc(100% - 316px)',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    borderRight: 'solid #707070 1px',
    backgroundSize: '70%',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 120,
    backgroundPositionY: 0,
  },
  break: {
    height: 0,
    flexBasis: '100%',
  },
  footer: {
    padding: 10,
    minHeight: 70,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    marginLeft: 10,
  },
  btn: {
    marginTop: 25,
    marginBottom: 25,
    marginLeft: 25,
  },
});
