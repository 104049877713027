import React, {useState} from 'react';
import {Lot} from 'src/models/Lot';

import {
  DataGrid,
  GridColDef,
  GridValueGetterParams,
  GridCellParams,
  GridSortModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import {parseISO, format} from 'date-fns';

const columns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'LOTS',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.name;
    },
  },
  {
    field: 'supplier',
    headerName: 'FOURNISSEURS',
    flex: 1,
    renderCell: (params) => {
      return params.row.supplier.name;
    },
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.supplier.name;
    },
  },
  {
    field: 'peauxRemaining',
    headerName: 'PEAUX RESTANTES',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) =>
      `${params.row.last_skin - params.row.first_skin + 1 - params.row.count_sort}/${
        params.row.last_skin - params.row.first_skin + 1
      }`,
  },
  {
    field: 'slaughter_date',
    headerName: 'DATE ABATTAGE',
    type: 'date',
    flex: 1,
    valueGetter: (params: GridValueGetterParams) => {
      return new Date(params.row.slaughter_date);
    },
  },
];

type LotTabProps = {
  lots: Lot[];
  onClick?: (lot: Lot) => void;
};

export default function LotTab(props: LotTabProps): JSX.Element {
  const {lots, onClick} = props;

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'slaughter_date',
      sort: 'desc',
    },
  ]);

  const onCellClick = (params: GridCellParams) => {
    const lot = lots.find((l) => l.id === params.id);
    if (lot !== undefined && onClick) {
      onClick(lot);
    }
  };

  return (
    <DataGrid
      rows={lots}
      columns={columns}
      getRowId={(row) => row.id}
      pageSize={13}
      disableSelectionOnClick
      rowsPerPageOptions={[20]}
      onCellClick={onCellClick}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
    />
  );
}
