import React, {useEffect} from 'react';
import {Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Outlet, useNavigate} from 'react-router-dom';
import {RootState} from '../store';

export default function LoginRequiredRoute() {
  const navigate = useNavigate();
  const currentUser = useSelector((state: RootState) => state.user.currentUser);

  if (currentUser !== undefined) {
    return <Outlet />;
  } else {
    return <Navigate to="signin" replace />;
  }
}
