import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {fetchContactTypes} from 'src/store/contactTypeSlice';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import {CircularProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {RootState, useAppThunkDispatch} from 'src/store';
import {useSelector} from 'react-redux';
import {ContactType} from 'src/models/ContactType';
import {Supplier} from 'src/models/Supplier';

type ModalCreateSupplierProps = {
  handleClose: () => void;
  supplier: Supplier;
  handleSubmit: (supplieID: string, name: string, contactType: ContactType, email: string, phoneNumber: string) => void;
};

export default function CreateContactModal(props: ModalCreateSupplierProps): JSX.Element {
  const {handleClose, handleSubmit, supplier} = props;
  const dispatch = useAppThunkDispatch();
  const types = useSelector((state: RootState) => state.contactType?.contactTypes);
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [contactType, setContactType] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [formSubmit, setFormSubmit] = useState(false);

  useEffect(() => {
    dispatch(fetchContactTypes());
  }, []);

  const formValid = formSubmit !== true && contactType !== '' && name !== '' && phoneNumber !== '' && email !== '';
  const creer = () => {
    if (formValid) {
      setFormSubmit(true);

      const contactTypeModel: ContactType = {
        name: contactType,
      };

      if (supplier.id) {
        handleSubmit(supplier.id, name, contactTypeModel, email, phoneNumber);
      }
    }
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Ajouter un nouveau contact</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item={true} xs={12} p={2}>
            <TextField
              margin="dense"
              label="Contact :"
              placeholder="Nom du contact"
              variant="standard"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="dense"
              label="Coordonnées mail :"
              placeholder="Mail du contact"
              variant="standard"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="dense"
              label="Coordonnées tel :"
              placeholder="Tel du contact"
              variant="standard"
              fullWidth
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="outlined-select-currency"
              className={classes.input}
              fullWidth
              select
              value={contactType}
              label="Type de contact :"
              onChange={(e) => setContactType(e.target.value)}>
              {types.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={creer} disabled={formValid === false}>
          {formSubmit ? <CircularProgress /> : 'Créer'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 10,
  },
  inputAutocoplite: {
    marginTop: 10,
    marginBottom: 10,
  },
  inputTextFild: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
