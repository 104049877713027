import * as React from 'react';
import {Supplier} from 'src/models/Supplier';
import {Contact} from 'src/models/Contact';

import {DataGrid, GridColDef, GridCellParams} from '@mui/x-data-grid';

const columns: GridColDef[] = [
  {
    field: 'name',
    cellClassName: 'pointer',
    headerName: 'FOURNISSEURS',
    flex: 1,
    renderCell: (params) => {
      return <a>{params.row.name}</a>;
    },
  },
  {
    field: 'contact',
    headerName: 'CONTACT',
    flex: 1,
    renderCell: (params) => {
      return params.row.contactPrincipal?.name ?? 'Pas de contact';
    },
  },
  {
    field: 'phone',
    headerName: 'N° Tel',
    flex: 1,
    renderCell: (params) => {
      return params.row.contactPrincipal?.phoneNumber ?? '...';
    },
  },
];

type SuppliersTabProps = {
  suppliers: Supplier[];
  onClick?: (supplier: Supplier) => void;
};

export default function SupplierTab(props: SuppliersTabProps) {
  const {suppliers, onClick} = props;

  const onCellClick = (params: GridCellParams) => {
    const supplier = suppliers.find((l) => l.id === params.id);
    if (supplier !== undefined && onClick) {
      onClick(supplier);
    }
  };

  return (
    <div style={{height: 400, width: '100%'}}>
      <DataGrid
        rows={suppliers}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onCellClick={onCellClick}
        getRowId={(row) => row.id}
        disableSelectionOnClick
      />
    </div>
  );
}
