import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import {RootState, useAppThunkDispatch} from 'src/store';
import {updateIpAddress} from 'src/store/ipAddressSlice';
import {IpAddress} from 'src/models/IpAddress';

type ModalUpdateContactTypeProps = {
  ipAddressStat: IpAddress;
  handleClose: () => void;
};

export default function UpdateIpAddressModal(props: ModalUpdateContactTypeProps): JSX.Element {
  const {ipAddressStat, handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const [ipAddressName, setIpAddressName] = useState(ipAddressStat.name);
  const [parties, setParties] = useState(ipAddressStat.address.split('.'));
  const [address1, setAddress1] = useState(parties[0]);
  const [address2, setAddress2] = useState(parties[1]);
  const [address3, setAddress3] = useState(parties[2]);
  const [address4, setAddress4] = useState(parties[3]);
  const [nameError, setNameError] = useState('');
  const [addressError, setAddressError] = useState('');

  const onContactTypeUpdate = (id: string, name: string, address: string) => {
    dispatch(
      updateIpAddress({
        id,
        name,
        address,
      }),
    )
      .unwrap()
      .then(() => handleClose);
  };

  const onSubmit = () => {
    let hasError = false;
    const address = address1 + '.' + address2 + '.' + address3 + '.' + address4;

    if (ipAddressName.length == 0) {
      setNameError('Le nom est obligatoire !');
      hasError = true;
    }

    if (address == '...' || address == '0.0.0.0') {
      setAddressError('IP est obligatoire !');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (ipAddressStat.id) {
      onContactTypeUpdate(ipAddressStat.id, ipAddressName, address);
    }

    handleClose();
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Modifier l&apos;Adresse IP</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nom :"
          type="text"
          fullWidth
          variant="standard"
          value={ipAddressName}
          onChange={(e) => setIpAddressName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={nameError.length != 0}
          helperText={nameError}
        />
        <Grid container spacing={0}>
          <Grid item={true} xs={12} mt={2}>
            <Typography variant="h6">Adresse IP</Typography>
            <TextField
              sx={{width: 80}}
              type="tel"
              className="text-field-amount"
              value={address1}
              onChange={(e) => {
                const parsedValue = parseInt(e.target.value);
                if (!isNaN(parsedValue)) {
                  const newValue = Math.min(255, Math.max(0, parsedValue));
                  setAddress1(newValue.toString());
                }
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
            <TextField disabled value={'.'} sx={{width: 40}} />
            <TextField
              sx={{width: 80}}
              type="tel"
              className="text-field-amount"
              value={address2}
              onChange={(e) => {
                const parsedValue = parseInt(e.target.value);
                if (!isNaN(parsedValue)) {
                  const newValue = Math.min(255, Math.max(0, parsedValue));
                  setAddress2(newValue.toString());
                }
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
            <TextField disabled value={'.'} sx={{width: 40}} />
            <TextField
              sx={{width: 80}}
              type="tel"
              className="text-field-amount"
              value={address3}
              onChange={(e) => {
                const parsedValue = parseInt(e.target.value);
                if (!isNaN(parsedValue)) {
                  const newValue = Math.min(255, Math.max(0, parsedValue));
                  setAddress3(newValue.toString());
                }
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
            <TextField disabled value={'.'} sx={{width: 40}} />
            <TextField
              sx={{width: 80}}
              type="tel"
              className="text-field-amount"
              value={address4}
              onChange={(e) => {
                const parsedValue = parseInt(e.target.value);
                if (!isNaN(parsedValue)) {
                  const newValue = Math.min(255, Math.max(0, parsedValue));
                  setAddress4(newValue.toString());
                }
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
            {addressError && (
              <Typography mt={1} variant="subtitle2" color="error">
                {addressError}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Modifier
        </Button>
      </DialogActions>
    </Dialog>
  );
}
