import React from 'react';
import {makeStyles} from '@mui/styles';
import update from 'immutability-helper';

type BlockProps = {
  count: number;
  title: string;
  color: '#FF9300' | '#FFE200' | '#EB00FF' | '#0058FF' | '#CCC';
  onClick?: () => void;
};

function Block(props: BlockProps): JSX.Element {
  const {count, title, color, onClick} = props;
  const classes = useStylesBlock();
  return (
    <div className={classes.block} style={{color}} onClick={onClick}>
      <span className={classes.count}>{count}</span>
      <span className={classes.title}>{title}</span>
    </div>
  );
}

const useStylesBlock = makeStyles({
  block: {
    height: 78,
    borderLeft: 'solid #707070 1px',
    borderBottom: 'solid #707070 1px',
    alignItems: 'center',
    display: 'flex',
  },
  count: {
    fontSize: 48,
    marginLeft: 30,
  },
  title: {
    fontSize: 24,
    marginLeft: 20,
  },
});

type DefautColonneProps = {
  title: string;
  skinDefect: [number, number, number, number]; // Déchirures, Échancrures, Coutelures, Trous
  onUpdate?: (defect: [number, number, number, number]) => void;
  noEchancrure?: boolean;
};

export default function DefautColonne(props: DefautColonneProps): JSX.Element {
  const {title, skinDefect, noEchancrure, onUpdate} = props;
  const classes = useStyles();

  return (
    <div className={classes.colonne}>
      <div className={classes.title}>{title}</div>
      <Block
        count={skinDefect[0]}
        title="Déchirures"
        color="#FF9300"
        onClick={() => (onUpdate ? onUpdate(update(skinDefect, {[0]: {$set: skinDefect[0] + 1}})) : undefined)}
      />
      <Block
        count={skinDefect[1]}
        title="Échancrures"
        color={noEchancrure === true ? '#CCC' : '#FFE200'}
        onClick={() =>
          onUpdate && noEchancrure !== true ? onUpdate(update(skinDefect, {[1]: {$set: skinDefect[1] + 1}})) : undefined
        }
      />
      <Block
        count={skinDefect[2]}
        title="Coutelures"
        color="#0058FF"
        onClick={() => (onUpdate ? onUpdate(update(skinDefect, {[2]: {$set: skinDefect[2] + 1}})) : undefined)}
      />
      <Block
        count={skinDefect[3]}
        title="Trous"
        color="#EB00FF"
        onClick={() => (onUpdate ? onUpdate(update(skinDefect, {[3]: {$set: skinDefect[3] + 1}})) : undefined)}
      />
    </div>
  );
}

const useStyles = makeStyles({
  colonne: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    backgroundColor: '#F5F6FA60',
    border: 'solid #707070 1px',
    textAlign: 'center',
    borderRight: 'none',
    borderTop: 'none',
  },
});
