import React, {useEffect, useState} from 'react';
import {setPallet, fetchInProgressPallets, fetchFinishedPallets} from 'src/store/palletSlice';
import {Pallet} from 'src/models/Pallet';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState, useAppThunkDispatch} from 'src/store';
import {DataGrid, GridColDef, GridValueGetterParams, GridCellParams, GridSortModel} from '@mui/x-data-grid';
import {parseISO, format} from 'date-fns';

const columns: GridColDef[] = [
  {
    field: 'name',
    cellClassName: 'pointer',
    headerName: 'N°PALETTE',
    flex: 1,
    renderCell: (params) => {
      return <a>{params.row.name}</a>;
    },
  },
  {
    field: 'category',
    headerName: 'CATEGORIE',
    flex: 1,
    renderCell: (params) => {
      return params.row.category ?? '';
    },
  },
  {
    field: 'supplier',
    headerName: 'FOURNISSEURS',
    flex: 1,
    renderCell: (params) => {
      return params.row.supplierName;
    },
    valueGetter: (params: GridValueGetterParams) => {
      return params.row.supplierName;
    },
  },
  {
    field: 'skinsAmount',
    headerName: 'NOMBRE DE PEAUX',
    flex: 1,
    renderCell: (params) => {
      return params.row.skinsAmount ?? '';
    },
  },
  {
    field: 'capacity',
    headerName: 'CAPACITÉ',
    flex: 1,
    renderCell: (params) => {
      return params.row.capacity ?? '';
    },
  },
];

type PalletTabProps = {
  setFormSubmit: (boolean: boolean) => void;
};

export default function TrayTab(props: PalletTabProps): JSX.Element {
  const {setFormSubmit} = props;

  const pallets = useSelector((state: RootState) => state?.pallet?.palletsInProgress);
  const dispatch = useAppThunkDispatch();
  const navigate = useNavigate();

  const fetchData = async () => {
    await dispatch(fetchInProgressPallets()).then(() => setFormSubmit(true));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'finished_at',
      sort: 'desc',
    },
  ]);

  const onCellClick = (params: GridCellParams) => {
    const pallet = pallets ? pallets.find((p) => p.id === params.id) : undefined;

    if (pallet !== undefined) {
      dispatch(setPallet(pallet));
      navigate(`/gestion-des-lots/palettes/${pallet.id}/changer`);
    }
  };

  return (
    <DataGrid
      rows={pallets ?? []}
      columns={columns}
      getRowId={(row) => row.id}
      pageSize={20}
      rowsPerPageOptions={[20]}
      onCellClick={onCellClick}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
    />
  );
}
