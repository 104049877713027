import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import {fetchContactTypes} from 'src/store/contactTypeSlice';
import MenuItem from '@mui/material/MenuItem';
import {CircularProgress} from '@mui/material';
import Grid from '@mui/material/Grid';
import {deleteContact} from 'src/store/supplierSlice';
import {makeStyles} from '@mui/styles';
import {RootState, useAppThunkDispatch} from 'src/store';
import {Contact} from 'src/models/Contact';
import {useSelector} from 'react-redux';
import {ContactType} from 'src/models/ContactType';

type ModalUpdateContactProps = {
  contactStat: Contact;
  handleClose: () => void;
  handleSubmit: (contact: Contact) => void;
};

export default function UpdateContactModal(props: ModalUpdateContactProps): JSX.Element {
  const {contactStat, handleClose, handleSubmit} = props;
  const [openDelete, setOpenDelete] = React.useState(false);
  const dispatch = useAppThunkDispatch();
  const types = useSelector((state: RootState) => state.contactType?.contactTypes);
  const [contactType, setContactType] = useState(contactStat.contactType.name);
  const [nameError, setNameError] = useState('');

  const classes = useStyles();
  const [name, setName] = useState(contactStat.name);
  const [email, setEmail] = useState(contactStat.email);
  const [phoneNumber, setPhoneNumber] = useState(contactStat.phoneNumber);
  const [formSubmit, setFormSubmit] = useState(false);

  useEffect(() => {
    dispatch(fetchContactTypes());
  }, []);

  const formValid = formSubmit !== true && contactType !== '' && name !== '' && phoneNumber !== '' && email !== '';
  const creer = () => {
    if (formValid) {
      setFormSubmit(true);

      const contactTypeModel: ContactType = {
        name: contactType,
      };

      const contactModel: Contact = {
        id: contactStat.id,
        name: name,
        email: email,
        phoneNumber: phoneNumber,
        contactType: contactTypeModel,
      };

      handleSubmit(contactModel);
    }
  };

  const handleDelete = (contact: Contact) => {
    if (contact.id) {
      dispatch(deleteContact(contact.id));
    }
    handleClose();
  };
  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>
        <Grid container spacing={0}>
          <Grid item={true} xs={11} p={0}>
            Modifier le contact
          </Grid>
          <Grid item={true} xs={1} p={0}>
            <IconButton color="error" aria-label="delete" onClick={() => setOpenDelete(true)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item={true} xs={12} p={2}>
            <TextField
              margin="dense"
              label="Contact :"
              placeholder="Nom du contact"
              variant="standard"
              fullWidth
              value={name}
              onChange={(e) => setName(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="dense"
              label="Coordonnées mail :"
              placeholder="Mail du contact"
              variant="standard"
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              margin="dense"
              label="Coordonnées tel :"
              placeholder="Tel du contact"
              variant="standard"
              fullWidth
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              id="outlined-select-currency"
              className={classes.input}
              fullWidth
              select
              value={contactType}
              defaultValue={contactStat.contactType}
              label="Type de contact :"
              onChange={(e) => setContactType(e.target.value)}>
              {types.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={creer} disabled={formValid === false}>
          {formSubmit ? <CircularProgress /> : 'Modifier'}
        </Button>
      </DialogActions>
      <div>
        <Dialog
          open={openDelete}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{'Confirmation de la suppression'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Êtes-vous sûr de vouloir supprimer le contact &quot;{contactStat.name}&quot; ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>NON</Button>
            <Button onClick={() => handleDelete(contactStat)} autoFocus>
              OUI
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 10,
  },
  inputAutocoplite: {
    marginTop: 10,
    marginBottom: 10,
  },
  inputTextFild: {
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
