import React, {useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import NumberTextField from '../numbertextfield/NumberTextField';
import MenuItem from '@mui/material/MenuItem';
import {Chip, CircularProgress} from '@mui/material';
import {parse, isPast} from 'date-fns';
import {makeStyles} from '@mui/styles';
import {RootState, useAppThunkDispatch} from 'src/store';
import {fetchCategories} from 'src/store/categorySlice';
import {fetchSuppliers} from 'src/store/supplierSlice';
import {useSelector} from 'react-redux';
import {Category} from 'src/models/Category';

type ModalCreateLotProps = {
  handleClose: () => void;
  handleSubmit: (
    name: string,
    slaughter_date: string,
    first_skin: number,
    last_skin: number,
    c3: number[],
    categories?: Category[],
    comment?: string,
    reception_date?: string,
    supplierName?: string,
  ) => void;
};

export default function CreateLotModal(props: ModalCreateLotProps): JSX.Element {
  const {handleClose, handleSubmit} = props;
  const classes = useStyles();
  const dispatch = useAppThunkDispatch();
  const categoriesState = useSelector((state: RootState) => state.category.categories);
  const suppliersState = useSelector((state: RootState) => state.supplier.suppliers);

  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchSuppliers());
  }, []);

  const c3inputRef = useRef<HTMLInputElement>();
  const [lot, setLot] = useState('');
  const [supplierName, setSupplierName] = useState<string>();
  const [categories, setCategory] = useState<Category[]>([]);
  const [dateAbattage, setDateAbattage] = useState('');
  const [dateReceipt, setDateReceipt] = useState('');
  const [comment, setComment] = useState('');
  const [firstSkin, setFirstSkin] = useState<number | undefined>();
  const [lastSkin, setLastSkin] = useState<number | undefined>();
  const [c3input, setC3input] = useState<number | undefined>();
  const [c3list, setC3list] = useState<number[]>([]);
  const [formSubmit, setFormSubmit] = useState(false);
  const [dateslaughter_dateValid, setDateslaughter_dateValid] = useState(true);
  const [dateReceipt_dateValid, setDateReceipt_dateValid] = useState(true);
  const dateslaughter_date = parse(dateAbattage, 'yyyy-MM-dd', new Date());
  const datesReceipt_date = parse(dateReceipt, 'yyyy-MM-dd', new Date());

  useEffect(() => {
    if (dateAbattage === '') {
      setDateslaughter_dateValid(true);
      return;
    }
    if (dateReceipt === '') {
      setDateReceipt_dateValid(true);
      return;
    }
    setDateslaughter_dateValid(isPast(dateslaughter_date));
    setDateReceipt_dateValid(isPast(datesReceipt_date));
  }, [dateAbattage, dateReceipt]);

  const formValid =
    formSubmit !== true &&
    lot !== '' &&
    supplierName !== undefined &&
    dateAbattage !== '' &&
    dateReceipt !== '' &&
    isPast(dateslaughter_date) &&
    firstSkin != undefined &&
    lastSkin != undefined &&
    firstSkin <= lastSkin;

  const addC3 = () => {
    if (c3input !== undefined) {
      setC3list([...c3list, c3input]);
      setC3input(undefined);
      c3inputRef.current?.focus();
    }
  };

  const creer = () => {
    if (formValid) {
      setFormSubmit(true);
      handleSubmit(lot, dateAbattage, firstSkin, lastSkin, c3list, categories, comment, dateReceipt, supplierName);
    }
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Ajouter un nouveau lot</DialogTitle>
      <DialogContent>
        <Grid container spacing={0}>
          <Grid item={true} xs={12} p={2}>
            <TextField
              margin="dense"
              label="Lot :"
              type="text"
              fullWidth
              variant="standard"
              value={lot}
              onChange={(e) => setLot(e.target.value.toUpperCase())}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item={true} xs={12} p={2}>
            <TextField
              id="outlined-select-currency-supplier"
              className={classes.input}
              select
              fullWidth
              value={supplierName}
              onChange={(e) => setSupplierName(e.target.value)}
              label="Fournisseur :"
              defaultValue="1">
              {suppliersState.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={true} xs={6} p={2}>
            <TextField
              margin="dense"
              label="Date de reception :"
              type="date"
              fullWidth
              variant="standard"
              value={dateReceipt}
              onChange={(e) => setDateReceipt(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              error={dateReceipt_dateValid === false}
              helperText={dateReceipt_dateValid === false ? 'La date est dans le future' : undefined}
            />
            <TextField
              margin="dense"
              label="Date d'abattage :"
              type="date"
              fullWidth
              variant="standard"
              value={dateAbattage}
              onChange={(e) => setDateAbattage(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              error={dateslaughter_dateValid === false}
              helperText={dateslaughter_dateValid === false ? 'La date est dans le future' : undefined}
            />
          </Grid>
          <Grid item={true} xs={6} p={2}>
            <NumberTextField
              margin="dense"
              label="Première peau :"
              placeholder="Numéro de la première peau"
              type="number"
              fullWidth
              variant="standard"
              value={firstSkin}
              onNumberChange={(e) => setFirstSkin(e)}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <NumberTextField
              margin="dense"
              label="Dernière peau :"
              placeholder="Numéro de la dernière peau"
              type="number"
              fullWidth
              variant="standard"
              value={lastSkin}
              onNumberChange={(e) => setLastSkin(e)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid xs={12} item={true} p={2}>
            <NumberTextField
              inputRef={c3inputRef}
              margin="dense"
              label="C3 :"
              placeholder="Numéro de la peau"
              type="number"
              fullWidth
              variant="standard"
              value={c3input}
              onNumberChange={(e) => setC3input(e)}
              InputProps={{
                endAdornment: (
                  <Button variant="contained" onClick={addC3} className={classes.btn}>
                    AJOUTER
                  </Button>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {c3list.map((c3) => (
              <Chip key={c3} label={c3} onDelete={() => setC3list(c3list.filter((c) => c !== c3))} />
            ))}
            <Autocomplete
              className={classes.input}
              multiple
              fullWidth
              id="multiple-limit-tags"
              options={categoriesState}
              getOptionLabel={(option) => option.name}
              onChange={(e, newVal) => setCategory(newVal || '')}
              renderInput={(params) => (
                <TextField {...params} label="Catégories des peaux :" placeholder="Categories" />
              )}
            />
            <TextField
              id="outlined-multiline-static"
              className={classes.input}
              label="Commentaire :"
              multiline
              fullWidth
              rows={4}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Ajouter un commentaire…"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={creer} disabled={formValid === false}>
          {formSubmit ? <CircularProgress /> : 'Créer'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const useStyles = makeStyles((theme) => ({
  btn: {
    margin: 10,
  },
  input: {
    marginTop: 10,
    marginBottom: 10,
  },
}));
