import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {RootState, useAppThunkDispatch} from 'src/store';
import {updateCategory} from 'src/store/categorySlice';
import {Category} from 'src/models/Category';

type ModalUpdateCategoryProps = {
  categoryStat: Category;
  handleClose: () => void;
};

export default function UpdateCategoryModal(props: ModalUpdateCategoryProps): JSX.Element {
  const {categoryStat, handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const [categoryID, setId] = useState(categoryStat.id);
  const [categoryName, setName] = useState(categoryStat.name);
  const [nameError, setNameError] = useState('');

  const onCategoryUpdate = (id: string, name: string) => {
    dispatch(
      updateCategory({
        id,
        name,
      }),
    )
      .unwrap()
      .then(() => handleClose);
  };

  const onSubmit = () => {
    let hasError = false;

    if (categoryName.length == 0) {
      setNameError('Le nom est obligatoire !');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    onCategoryUpdate(categoryID, categoryName);
    handleClose();
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Modifier une nouvelle categorie</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nom :"
          type="text"
          fullWidth
          variant="standard"
          value={categoryName}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={nameError.length != 0}
          helperText={nameError}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Modifier
        </Button>
      </DialogActions>
    </Dialog>
  );
}
