import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {RootState, useAppThunkDispatch} from 'src/store';
import {updateContactType} from 'src/store/contactTypeSlice';
import {ContactType} from 'src/models/ContactType';

type ModalUpdateContactTypeProps = {
  contactTypeStat: ContactType;
  handleClose: () => void;
};

export default function UpdateContactTypeModal(props: ModalUpdateContactTypeProps): JSX.Element {
  const {contactTypeStat, handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const [contactTypeName, setContactTypeName] = useState(contactTypeStat.name);
  const [nameError, setNameError] = useState('');

  const onContactTypeUpdate = (id: string, name: string) => {
    dispatch(
      updateContactType({
        id,
        name,
      }),
    )
      .unwrap()
      .then(() => handleClose);
  };

  const onSubmit = () => {
    let hasError = false;

    if (contactTypeName.length == 0) {
      setNameError('Le nom est obligatoire !');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    if (contactTypeStat.id) {
      onContactTypeUpdate(contactTypeStat.id, contactTypeName);
    }

    handleClose();
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Modifier le type de contact</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nom :"
          type="text"
          fullWidth
          variant="standard"
          value={contactTypeName}
          onChange={(e) => setContactTypeName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={nameError.length != 0}
          helperText={nameError}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Modifier
        </Button>
      </DialogActions>
    </Dialog>
  );
}
