import * as React from 'react';
import {ConfigPalletPlan} from 'src/models/ConfigPalletPlan';

import {DataGrid, GridColDef, GridCellParams} from '@mui/x-data-grid';

const columns: GridColDef[] = [
  {
    field: 'name',
    cellClassName: 'pointer',
    headerName: 'NOM',
    flex: 1,
    renderCell: (params) => {
      return <a>{params.row.name}</a>;
    },
  },
  {
    field: 'countConfigPalletPositions',
    headerName: 'NOMBRE DE PALETTES',
    flex: 1,
    renderCell: (params) => {
      return params.row.countConfigPalletPositions;
    },
  },
];

type SuppliersTabProps = {
  plans: ConfigPalletPlan[];
  onClick?: (plan: ConfigPalletPlan) => void;
};

export default function ConfigPalletPlansTab(props: SuppliersTabProps) {
  const {plans, onClick} = props;

  const onCellClick = (params: GridCellParams) => {
    const supplier = plans.find((l) => l.id === params.id);
    if (supplier !== undefined && onClick) {
      onClick(supplier);
    }
  };

  return (
    <div style={{height: 400, width: '100%'}}>
      <DataGrid
        rows={plans}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        onCellClick={onCellClick}
        getRowId={(row) => row.id}
        disableSelectionOnClick
      />
    </div>
  );
}
