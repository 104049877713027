import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {useAppThunkDispatch} from 'src/store';
import {createIpAddress} from 'src/store/ipAddressSlice';

type ModalCreateContactTypeProps = {
  handleClose: () => void;
};

export default function CreateIpAddressModal(props: ModalCreateContactTypeProps): JSX.Element {
  const {handleClose} = props;
  const dispatch = useAppThunkDispatch();
  const [name, setName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [address3, setAddress3] = useState('');
  const [address4, setAddress4] = useState('');
  const [nameError, setNameError] = useState('');
  const [addressError, setAddressError] = useState('');

  const onIpAddressCreate = (name: string, address: string) => {
    dispatch(
      createIpAddress({
        name,
        address,
      }),
    )
      .unwrap()
      .then(() => handleClose);
  };

  const onSubmit = () => {
    let hasError = false;
    const address = address1 + '.' + address2 + '.' + address3 + '.' + address4;

    if (name.length == 0) {
      setNameError('Le nom est obligatoire !');
      hasError = true;
    }

    if (address == '...' || address == '0.0.0.0') {
      setAddressError('IP est obligatoire !');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    onIpAddressCreate(name, address);
    handleClose();
  };

  return (
    <Dialog open maxWidth={'lg'}>
      <DialogTitle>Ajouter une nouvelle IP Adresse</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Nom :"
          type="text"
          fullWidth
          variant="standard"
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          error={nameError.length != 0}
          helperText={nameError}
        />
        <Grid container spacing={0}>
          <Grid item={true} xs={12} p={0} mt={2}>
            <Typography variant="h6">Adresse IP</Typography>
            <TextField
              sx={{width: 80}}
              type="tel"
              className="text-field-amount"
              value={address1}
              onChange={(e) => {
                const parsedValue = parseInt(e.target.value);
                if (!isNaN(parsedValue)) {
                  const newValue = Math.min(255, Math.max(0, parsedValue));
                  setAddress1(newValue.toString());
                }
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
            <TextField disabled value={'.'} sx={{width: 40}} />
            <TextField
              sx={{width: 80}}
              type="tel"
              className="text-field-amount"
              value={address2}
              onChange={(e) => {
                const parsedValue = parseInt(e.target.value);
                if (!isNaN(parsedValue)) {
                  const newValue = Math.min(255, Math.max(0, parsedValue));
                  setAddress2(newValue.toString());
                }
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
            <TextField disabled value={'.'} sx={{width: 40}} />
            <TextField
              sx={{width: 80}}
              type="tel"
              className="text-field-amount"
              value={address3}
              onChange={(e) => {
                const parsedValue = parseInt(e.target.value);
                if (!isNaN(parsedValue)) {
                  const newValue = Math.min(255, Math.max(0, parsedValue));
                  setAddress3(newValue.toString());
                }
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
            <TextField disabled value={'.'} sx={{width: 40}} />
            <TextField
              sx={{width: 80}}
              type="tel"
              className="text-field-amount"
              value={address4}
              onChange={(e) => {
                const parsedValue = parseInt(e.target.value);
                if (!isNaN(parsedValue)) {
                  const newValue = Math.min(255, Math.max(0, parsedValue));
                  setAddress4(newValue.toString());
                }
              }}
              inputProps={{
                maxLength: 3,
              }}
            />
            {addressError && (
              <Typography mt={1} variant="subtitle2" color="error">
                {addressError}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Créer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
