import React, {useEffect, useState} from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import {useSelector} from 'react-redux';
import {createLot, setLot, fetchLots} from 'src/store/lotSlice';
import {Box, Tab, Button} from '@mui/material';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {RootState, useAppThunkDispatch} from 'src/store';
import LotTab from 'src/components/Lot_manager/tabs/LotTab';
import Paper from '@mui/material/Paper';
import {CircularProgress} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Lot} from 'src/models/Lot';
import ModalCreateLot from 'src/components/Lot_manager/modals/CreateLotModal';
import {useNavigate} from 'react-router-dom';
import {Category} from 'src/models/Category';

export default function Home() {
  const [selectedTab, setSelectedTab] = useState<'1' | '2'>('1');
  const [modalAddLotOpen, setModalAddLotOpen] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const navigate = useNavigate();
  const classes = useStyles();

  const lots = useSelector((state: RootState) => state.lot.lots);
  const lotsEnCours = lots.filter((lot) => {
    return lot.count_sort < lot.last_skin - lot.first_skin + 1;
  });

  const lotsTermines: Lot[] = lots.filter((lot) => {
    return lot.count_sort >= lot.last_skin - lot.first_skin + 1;
  });

  const dispatch = useAppThunkDispatch();

  const fetchData = async () => {
    await dispatch(fetchLots()).then(() => setFormSubmit(true));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onLotCreate = (
    name: string,
    slaughter_date: string,
    first_skin: number,
    last_skin: number,
    c3: number[],
    categories?: Category[],
    comment?: string,
    reception_date?: string,
    supplierName?: string,
  ) => {
    dispatch(
      createLot({
        name,
        slaughter_date,
        first_skin,
        last_skin,
        c3,
        categories,
        comment,
        reception_date,
        supplierName,
      }),
    )
      .unwrap()
      .then(() => setModalAddLotOpen(false));
  };

  return (
    <Grid item xs={12} md={12} lg={12}>
      <Paper
        sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}>
        <Container className={classes.root}>
          <div className={classes.page}>
            <TabContext value={selectedTab}>
              <Box sx={{borderBottom: 1, borderColor: 'divider', display: 'flex'}}>
                <TabList
                  sx={{flexGrow: 1}}
                  onChange={(_, value) => setSelectedTab(value)}
                  aria-label="lab API tabs example">
                  <Tab label="LOT EN COURS" value="1" />
                  <Tab label="LOT TERMINÉS" value="2" />
                </TabList>
                <Button variant="contained" className={classes.btn} onClick={() => setModalAddLotOpen(true)}>
                  ajouter un lot
                </Button>
                {modalAddLotOpen && (
                  <ModalCreateLot handleSubmit={onLotCreate} handleClose={() => setModalAddLotOpen(false)} />
                )}
              </Box>
              {!formSubmit ? (
                <div className={classes.tabCircular}>
                  <CircularProgress />
                </div>
              ) : (
                <div className={classes.tabContainer}>
                  <TabPanel value="1" className={classes.tabPanel}>
                    <LotTab
                      lots={lotsEnCours}
                      onClick={async (lot) => {
                        dispatch(setLot(lot));
                        navigate(`/gestion-des-lots/${lot.id}/detail`);
                      }}
                    />
                  </TabPanel>
                  <TabPanel value="2" className={classes.tabPanel}>
                    <LotTab
                      lots={lotsTermines}
                      onClick={async (lot) => {
                        dispatch(setLot(lot));
                        navigate(`/gestion-des-lots/${lot.id}/detail`);
                      }}
                    />
                  </TabPanel>
                </div>
              )}
            </TabContext>
          </div>
        </Container>
      </Paper>
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  page: {
    height: `calc(100% - 64px)`,
    paddingTop: theme.spacing(3),
    maxWidth: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  tabCircular: {
    height: '50vh',
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: '10%',
    display: 'flex',
    justifyContent: 'center',
  },
  tabPanel: {
    flexGrow: 1,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    height: '100%',
  },
  tabContainer: {
    height: '100vh',
  },
  root: {
    maxWidth: '100%',
    display: 'grid',
    height: '100vh',
    ' height': '-webkit-fill-available',
  },
  btn: {
    margin: 5,
  },
}));
